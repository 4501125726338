import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CustomButton, Loading } from "components";
import { ROUTES } from "constants/routes";
import { useAPIProjectDELETE } from "api";
import ErrorModal from "components/ErrorModal";
import DeleteModal from "components/DeleteModal";
import { getDeleteTargetTitleText, PROJECT_SETTINGS_PART_TITLES } from "constants/messages";
import { IDS } from "constants/constants";

interface ProjectDeleteProps {
  projectId: string;
  projectName: string;
}

/**
 * プロジェクト削除画面
 */
const ProjectDelete = ({ projectId, projectName }: ProjectDeleteProps) => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { mutate: mutateDeleteProject, isLoading: isLoadingDeleteProject } = useAPIProjectDELETE();

  //「プロジェクト削除」ボタン押下時、モーダル表示
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  // エラーモーダル表示非表示処理
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const handleErrorModalOpen = () => setErrorModalOpen(true);
  const handleErrorModalClose = () => setErrorModalOpen(false);

  /**
   * プロジェクト削除処理
   */
  const handleDeleteProject = async () => {
    const response = await mutateDeleteProject({ id: projectId });
    //削除後モーダル閉じる。成功時TOPへ戻り、失敗時エラーモーダル表示
    if (response.success) {
      navigate(ROUTES.HOME);
    } else {
      setErrorMessage("プロジェクトの削除に失敗しました。");
      handleClose();
      handleErrorModalOpen();
    }
  };

  if (isLoadingDeleteProject) {
    return <Loading name="ProjectDelete" />;
  }

  return (
    <>
      <CustomButton
        id={IDS.DELETE_PROJECT_BUTTON}
        text={PROJECT_SETTINGS_PART_TITLES.PROJECT_DELETE}
        onClick={handleOpen}
        color="error"
      />
      <DeleteModal title={PROJECT_SETTINGS_PART_TITLES.PROJECT_DELETE} text={getDeleteTargetTitleText(projectName, 'プロジェクト名')} open={open} onClick={handleDeleteProject} onClose={handleClose} />
      <ErrorModal text={errorMessage} open={errorModalOpen} onClose={handleErrorModalClose} />
    </>
  );
};

export default ProjectDelete;
