import { GoogleCredentialsAuthResponseDto } from "types/api";

export const getCommonCalendarExternalData = (calendarId: string | undefined, auth: GoogleCredentialsAuthResponseDto | undefined) => {
    if (!calendarId) {
        throw new Error(`No calendarId`);
    }
    const client = auth?.server;
    if (!client) {
        throw new Error('No client code');
    }
    const commonExternalData = {
        calendarId,
        auth: {
            client,
        }
    };
    return {
        commonExternalData,
    };
};
