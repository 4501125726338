import { useCallback, useEffect, useState } from "react";
import { Box, Tabs, Tab, useTheme, useMediaQuery } from "@mui/material";
import { Loading, Title } from "components";
import { useProject } from "hooks";
import AlertBox from "./block/AlertBox";
import ListItems from "./block/ListItems";
import Tools from "./block/Tools";
import Notifications from "./block/Notifications";
import Members from "./block/Members";
import RoutinesCalendar from "./block/RoutinesCalendar";
import MeetingsCalendar from "./block/MeetingsCalendar";
import { useAPIToolsGET, useAPINotificationsGET, useAPIMembersGET, useAPIRoutinesCalendarsGET, useAPIMeetingsCalendarsGET } from "api";
import { createIncremetor } from "utilities";
import { PROJECT_SETTINGS_PART_TITLES, REGISTER_NOTIFICATION_REQUEST_TEXT, REGISTER_TOOL_REQUEST_TEXT } from "constants/messages";
import { useLocation } from "react-router-dom";
import ProjectSettings from "./block/ProjectSettings";
import APIGetHandler from "components/APIGetHandler";

const PROJECT_SETTING_KEYS = [
  'tool', 'routine', 'meeting', 'notification', 'member', 'settings', 'delete',
] as const;

interface ProjectSettingTab {
  key: (typeof PROJECT_SETTING_KEYS)[number];
  label: string;
  index: number;
};

const INCREMENTOR_INITIAL_VALUE = 0;
const incrementor = createIncremetor(INCREMENTOR_INITIAL_VALUE - 1); // - 1でincrementor.nextのみを使える。

/**
 * プロジェクト設定
 */
const PROJECT_SETTING_SETTINGS = {
  key: 'settings',
  label: PROJECT_SETTINGS_PART_TITLES.PROJECT_SETTINGS,
  index: incrementor.next(),
} as const;
/**
 * 管理ツール設定
 */
const PROJECT_SETTING_TOOL = {
  key: 'tool',
  label: PROJECT_SETTINGS_PART_TITLES.TOOL,
  index: incrementor.next(),
} as const;
/**
 * 通知設定
 */
const PROJECT_SETTING_NOTIFICATION = {
  key: 'notification',
  label: PROJECT_SETTINGS_PART_TITLES.NOTIFICATION,
  index: incrementor.next(),
} as const;
/**
 * タスク用カレンダー設定
 */
const PROJECT_SETTING_ROUTINE = {
  key: 'routine',
  label: PROJECT_SETTINGS_PART_TITLES.ROUTINE,
  index: incrementor.next(),
} as const;
/**
 * MTG用カレンダー設定
 */
const PROJECT_SETTING_MEETING = {
  key: 'meeting',
  label: PROJECT_SETTINGS_PART_TITLES.MEETING,
  index: incrementor.next(),
} as const;

const PROJECT_SETTING_MEMBER = {
  key: 'member',
  label: PROJECT_SETTINGS_PART_TITLES.MEMBER,
  index: incrementor.next(),
} as const;

// 以下でindexで自動でソートされる。
const PROJECT_SETTING_TABS: ProjectSettingTab[] = [
  PROJECT_SETTING_SETTINGS,
  PROJECT_SETTING_TOOL,
  PROJECT_SETTING_NOTIFICATION,
  PROJECT_SETTING_ROUTINE,
  PROJECT_SETTING_MEETING,
  PROJECT_SETTING_MEMBER,
].sort((a, b) => a.index > b.index ? 1 : -1);

/*
 * プロジェクト設定画面
 */
const ProjectSettingPage = () => {

  //レフトナビ
  const a11yProps = (index: number) => {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
  };
  const [value, setValue] = useState(INCREMENTOR_INITIAL_VALUE); // TAB indexと一致したら表示対象
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (value !== newValue) {
      setValue(newValue);
    }
  };

  // プロジェクト
  const { project, projectId, setProjectFromParams, isLoading: isLoadingProjects, errorMessage: projectsErrorMessage } = useProject();
  const updateProject = useCallback(() => { window.location.reload(); }, []); // TODO: Project APIの更新の方がよい。

  // タスク管理ツール取得
  const { data: tools, execute: executeTools, isLoading: isLoadingTools, errorMessage: toolsErrorMessage } = useAPIToolsGET();
  const updateTools = useCallback(() => executeTools({ projectId }), [projectId]);

  // 通知設定取得
  const { data: notifications, execute: executeNotifications, isLoading: isLoadingNotifications, errorMessage: notificationsErrorMessage } =  useAPINotificationsGET();
  const updateNotifications = useCallback(() => executeNotifications({ projectId }), [projectId]);

  // メンバー設定取得
  const { data: members, execute: executeMembers, isLoading: isLoadingMembers, errorMessage: membersErrorMessage } = useAPIMembersGET();
  const updateMembers = useCallback(() => executeMembers({ projectId }), [projectId]);

  // ルーチンタスクカレンダー取得
  const { data: routinesCalendars, execute: executeRoutines, isLoading: isLoadingRoutines, errorMessage: routinesErrorMessage } = useAPIRoutinesCalendarsGET();
  const updateRoutines = useCallback(() => executeRoutines({ projectId }), [projectId]);

  // ミーティング用カレンダー取得
  const { data: meetingsCalendars, execute: executeMeetings, isLoading: isLoadingMeetings, errorMessage: meetingsErrorMessage } = useAPIMeetingsCalendarsGET();
  const updateMeetings = useCallback(() => executeMeetings({ projectId }), [projectId]);

  const theme = useTheme();
  const isLargerScreen = useMediaQuery(theme.breakpoints.up('sm'));

  const isLoading = isLoadingProjects || isLoadingTools || isLoadingNotifications || isLoadingMembers || isLoadingRoutines || isLoadingMeetings;
  const errorMessage = projectsErrorMessage || toolsErrorMessage || notificationsErrorMessage || membersErrorMessage || routinesErrorMessage || meetingsErrorMessage;
  const execute = () => {
    console.debug('ProjectSettingPage:useEffect', { projectId });
    setProjectFromParams();

    updateTools();
    updateNotifications();
    updateMembers();
    updateRoutines();
    updateMeetings();
  };
  useEffect(() => {
    execute()
  }, [projectId]);

  // HASH CHANGE
  const { hash } = useLocation();
  useEffect(() => {
    const key = (hash || '').substring(1);
    const match = PROJECT_SETTING_TABS.filter(item => item.key === key)[0];
    const index = match ? match.index : PROJECT_SETTING_TABS[0]?.index || INCREMENTOR_INITIAL_VALUE;
    setValue(index);
  }, [hash]);

  if (isLoading || errorMessage || !projectId || !project || !tools || !notifications) {
    return <APIGetHandler settings={{ name: 'ProjectSettingPage', isLoading, errorMessage, retry: () => execute() }} />
  }

  const projectName = project.name;
  const datas = [
    {
      text: REGISTER_TOOL_REQUEST_TEXT,
      post: tools,
    },
    {
      text: REGISTER_NOTIFICATION_REQUEST_TEXT,
      post: notifications,
    },
  ];
  // const tabsOrientation = isLargerScreen ? 'vertical' : 'horizontal';
  const tabsOrientation = 'vertical';
  const conditionalSx = isLargerScreen ? {} : { display: 'block', clear: 'both' };
  const flexDirection = isLargerScreen ? 'row' : 'column';

  return (
    <>
      <Title title={projectName} icon="bag" />
      <AlertBox datas={datas} />

      <Box
        sx={{
          flexGrow: 1,
          bgcolor: "background.paper",
          display: "flex",
          flexDirection,
        }}
      >
        <Tabs
          orientation={tabsOrientation}
          variant="scrollable"
          scrollButtons="auto"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{
            borderRight: 1,
            borderColor: "divider",
            ...conditionalSx,
          }}
        >
          {PROJECT_SETTING_TABS.map((tab) => (
            <Tab
              key={`tab-${tab.index}`}
              sx={{
                whiteSpace: "pre",
              }}
              label={tab.label}
              {...a11yProps(tab.index)}
            />
          ))}
        </Tabs>
        <ListItems value={value} index={PROJECT_SETTING_SETTINGS.index}>
          <ProjectSettings
            project={project}
            requestUpdate={updateProject}
          />
        </ListItems>
        <ListItems value={value} index={PROJECT_SETTING_TOOL.index}>
          <Tools
            rows={tools}
            projectId={projectId}
            requestUpdate={updateTools}
          />
        </ListItems>
        <ListItems value={value} index={PROJECT_SETTING_NOTIFICATION.index}>
          <Notifications
            rows={notifications}
            projectId={projectId}
            tools={tools}
            requestUpdate={updateNotifications}
          />
        </ListItems>
        <ListItems value={value} index={PROJECT_SETTING_ROUTINE.index}>
          <RoutinesCalendar
            rows={routinesCalendars}
            projectId={projectId}
            tools={tools}
            requestUpdate={updateRoutines}
          />
        </ListItems>
        <ListItems value={value} index={PROJECT_SETTING_MEETING.index}>
          <MeetingsCalendar
            rows={meetingsCalendars}
            projectId={projectId}
            requestUpdate={updateMeetings}
          />
        </ListItems>
        <ListItems value={value} index={PROJECT_SETTING_MEMBER.index}>
          <Members
            rows={members}
            projectId={projectId}
            requestUpdate={updateMembers}
            projectName={projectName}
          />
        </ListItems>
      </Box>
    </>
  );
};

export default ProjectSettingPage;
