import RefreshIcon from "@mui/icons-material/Refresh";
import { Box, Button, IconButton, Typography } from "@mui/material";
import CustomSnackbar from "./CustomSnackbar";

const APIErrorHandler = ({ message, retry }: { message?: string; retry?: () => any }) => {
  const isError = !!message;
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      {
        isError && (
          <Typography sx={{ textAlign: 'center', padding: '10px' }}>APIのリクエストが失敗した。</Typography>
        )
      }
      <Box>
        {
          isError && retry && (
            <Button variant="outlined" color="primary" startIcon={<RefreshIcon />} onClick={() => retry()}>リトライ</Button>
          )
        }
      </Box>
      <Box>
        <CustomSnackbar severity="error" message={message} />
      </Box>
    </Box>
  );
};

export default APIErrorHandler;
