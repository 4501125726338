import { useAuth0 } from "@auth0/auth0-react";
import { ROLES_KEY, DEFAULT_ROLES, DEFAULT_USER_ROLES } from "constants/constants";
import { useCallback } from "react";
import { RoleKey } from "types";
import useAppState from "./useAppState";

const useRoles = () => {
  const { isAuthenticated, getIdTokenClaims, user } = useAuth0();
  const [state, setAppState] = useAppState();
  const roles = state.roles;

  const getRoles = useCallback(async () => { // TODO: refactor: apiと同じようなimplicit roles処理を使って、共通化する。
    const roles: RoleKey[] = [...DEFAULT_ROLES];
    if (!isAuthenticated) {
      return roles;
    }
    const userRoles = await getIdTokenClaims().then((c) => {
      // TODO: apiと同じように、"roles"で終わる項目処理も利用可能に。
      const claims = c || { [ROLES_KEY]: user && user[ROLES_KEY] ? user[ROLES_KEY] : [] };
      console.debug({ c, claims, isAuthenticated, user });
      const r: RoleKey[] = [...DEFAULT_USER_ROLES];
      const claimRoles: RoleKey[] = claims && claims[ROLES_KEY] ? claims[ROLES_KEY] : [];
      claimRoles.forEach((cr) => {
        // 重複をさける。
        if (!DEFAULT_ROLES.includes(cr) && !r.includes(cr)) {
          r.push(cr);
        }
      })
      return r;
    });
    roles.push(...userRoles);

    console.debug('useRoles', { roles });

    return roles;
  }, [isAuthenticated, getIdTokenClaims, user]);
  const updateRoles = useCallback(async () => {
    const newRoles = await getRoles();
    // setAppState({ ...state, roles: newRoles });
    return newRoles;
  }, [getRoles]);
  return { roles, getRoles, updateRoles, isAuthenticated };
};

export default useRoles;
