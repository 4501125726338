import { API_ROUTES, DYNAMIC_API_ROUTES, EXTERNAL_API_ROUTES } from "constants/api-routes";
import { useAppState, useBacklog, useDelete, useGet, usePost, usePut } from "hooks";
import { Params, RoleKey } from "types";
import { AlertsGetResponseDto, AuthGoogleResponseDto, AuthMemberPostRequestDto, AuthMemberPostResponseDto, BacklogIssueType, BacklogProject, BacklogStatus, BookmarkGetResponseDto, BookmarkPostRequestDto, BookmarkPostResponseDto, BookmarkPutRequestDto, BookmarkPutResponseDto, CalendarMeetingsGetResponseDto, CalendarMeetingsPostRequestDto, CalendarMeetingsPostResponseDto, CalendarRoutinesGetResponseDto, CalendarRoutinesPostRequestDto, CalendarRoutinesPostResponseDto, CourseGetResponseDto, DeskTicketCommentPostRequestDto, DeskTicketCommentPostResponseDto, DeskTicketGetResponseDto, DeskTicketPostRequestDto, DeskTicketPostResponseDto, DeskTicketsGetResponseDto, DeskTicketTemplatesGetResponseDto, DeskUploadFilePostResponeDto, DiagnosesResponseDto, MemberGetResponseDto, MemberPostRequestDto, MemberPostResponseDto, NotificationGetResponseDto, NotificationPostRequestDto, NotificationPostResponseDto, ProjectDiagnosesGetResponseDto, ProjectDiagnosesPostRequestDto, ProjectDiagnosesPostResponseDto, ProjectPostRequestDto, ProjectPostResponseDto, ProjectPutRequestDto, ProjectPutResponseDto, ProjectsGetResponseDto, ProjectsWithAlertsGetResponseDto, SurveyDiagnosisGetResponseDto, SurveyGetAllResponseDto, SurveyGetResponseDto, SurveyPostAnswersRequestDto, SurveyPostAnswersResponseDto, TaskGetResponseDto, ToolGetResponseDto, ToolPostRequestDto, ToolPostResponseDto, ToolSettingsPostRequestDto, ToolSettingsPostResponseDto } from "types/api";

/*
GUARDS API 仕様: https://andgate-co.backlog.com/view/AP-349
*/

const s = String;
type ProjectStateProps = {
    projectId: string;
}
type CommonProjectStateProps = ProjectStateProps & {
    //
};
type PProps = CommonProjectStateProps;
type SurveyProps = { surveyId: string };
type DiagnosisProps = SurveyProps & { diagnosisId: string };
const DESK_OPTIONS = { authRequired: true, requiredRoles: ['desk'] as RoleKey[] };
const DANDLE_OPTIONS = { authRequired: true, requiredRoles: ['dandle'] as RoleKey[] };
const FREE_OPTIONS = { authRequired: true, requiredRoles: ['free'] as RoleKey[] };
const GUEST_OPTIONS = { authRequired: false, requiredRoles: ['guest'] as RoleKey[] };
/**
 * ログインしても、しなくてもよくて、状態によって動作が違う場合に利用する。
 */
const ALWAYS_SEND_AUTH_TOKEN_OPTIONS = { alwaysSendAuthToken: true };

/**
 * T=リクエスト
 * U=レスポンス
 */
 const useCommonProjectDependentPOST = <T extends Params, U>(urlGenerator: (projectId: string) => string) => {
    const { mutate, isLoading } = usePost<T, U>({ ...DANDLE_OPTIONS });
    return {
        mutate: ({ projectId, params }: { projectId: string, params: T }) => {
            return mutate({
                url: urlGenerator(projectId),
                params,
            });
        },
        isLoading,
    };
};
const useCommonProjectDependentDELETE = (urlGenerator: (projectId: string, id: string) => string) => {
    const { mutate, isLoading } = useDelete();
    return {
        mutate: ({ projectId, id }: { projectId: string, id: string }) => {
            return mutate(urlGenerator(s(projectId), s(id)));
        },
        isLoading,
    };
};

// GET
// 必ずに同じ形式で返すこと。
// CACHED GET START
export const useAPICoursesGET = () => {
    const [state, setAppState] = useAppState();
    const response = useGet<CourseGetResponseDto[], {}>({ url: API_ROUTES.ACADEMY_COURSES, ...GUEST_OPTIONS }) || undefined;
    const { data: courses } = response
    /*
    useEffect(() => {
        setAppState({ ...state, courses });
    }, [courses, setAppState, state]);
    */
    return response
};
export const useAPIProjectsGET = () => {
    const [state, setAppState] = useAppState();
    const response = useGet<ProjectsGetResponseDto[], {}>({ url: API_ROUTES.PROJECTS, ...DANDLE_OPTIONS }) || undefined;
    const { data: projects } = response;
    /*
    useEffect(() => {
        setAppState({ ...state, projects });
    }, [projects, setAppState, state]);
    */
    return response;
};
export const useAPIProjectsWithAlertsGET = () => {
    const [state, setAppState] = useAppState();
    const response = useGet<ProjectsWithAlertsGetResponseDto[], {}>({ url: API_ROUTES.PROJECTS_WITH_ALERTS, ...DANDLE_OPTIONS }) || undefined;
    const { data: projects } = response;
    /*
    useEffect(() => {
        setAppState({ ...state, projects });
    }, [projects, setAppState, state]);
    */
    return response;
};
export const useAPISurveysGET = () => {
    const [state, setAppState] = useAppState();
    const response = useGet<SurveyGetAllResponseDto[], {}>({ url: API_ROUTES.SURVEYS, ...GUEST_OPTIONS }) || undefined;
    const { data: surveys } = response;
    /*
    useEffect(() => {
        setAppState({ ...state, surveys });
    }, [setAppState, state, surveys]);
    */
    return response;
};
// CACHED GET END
export const useAPIToolsGET = () => useGet<ToolGetResponseDto[], PProps>({ url: ({ projectId }: PProps) => DYNAMIC_API_ROUTES.TOOLS(projectId), ...DANDLE_OPTIONS });
export const useAPINotificationsGET = () => useGet<NotificationGetResponseDto[], PProps>({ url: ({ projectId }: PProps) => DYNAMIC_API_ROUTES.NOTIFICATIONS(projectId), ...DANDLE_OPTIONS });
export const useAPIMembersGET = () => useGet<MemberGetResponseDto[], PProps>({ url: ({ projectId }: PProps) => DYNAMIC_API_ROUTES.MEMBERS(projectId), ...DANDLE_OPTIONS });
export const useAPIRoutinesCalendarsGET = () => useGet<CalendarRoutinesGetResponseDto[], PProps>({ url: ({ projectId }: PProps) => DYNAMIC_API_ROUTES.ROUTINES(projectId), ...DANDLE_OPTIONS });
export const useAPIMeetingsCalendarsGET = () => useGet<CalendarMeetingsGetResponseDto[], PProps>({ url: ({ projectId }: PProps) => DYNAMIC_API_ROUTES.MEETINGS(projectId), ...DANDLE_OPTIONS });
// Task型に次のBacklogデータもある: https://developer.nulab.com/docs/backlog/api/2/get-issue-list
export const useAPITasksGET = () => useGet<TaskGetResponseDto[], ProjectStateProps>({ url: ({ projectId }: ProjectStateProps) => DYNAMIC_API_ROUTES.TASKS(projectId), ...DANDLE_OPTIONS });
export const useAPIAlertsGET = () => useGet<AlertsGetResponseDto, ProjectStateProps>({ url: ({ projectId }: ProjectStateProps) => DYNAMIC_API_ROUTES.ALERTS(projectId), ...DANDLE_OPTIONS });
export const useAPISurveyOneGET = () => useGet<SurveyGetResponseDto, SurveyProps>({ url: ({ surveyId }: SurveyProps) => DYNAMIC_API_ROUTES.SURVEY(surveyId), ...GUEST_OPTIONS });
export const useAPISurveyDiagnosisGET = () => useGet<SurveyDiagnosisGetResponseDto, DiagnosisProps>({ url: ({ surveyId, diagnosisId }: DiagnosisProps) => DYNAMIC_API_ROUTES.SURVEY_DIAGNOSIS(surveyId, diagnosisId), ...FREE_OPTIONS } );
export const useAPIDiagnosesGET = () => useGet<DiagnosesResponseDto[], {}>({ url: API_ROUTES.SURVEY_DIAGNOSES, ...FREE_OPTIONS });
export const useAPIProjectDiagnosesGET = () => useGet<ProjectDiagnosesGetResponseDto[], PProps>({ url: ({ projectId }: PProps) => DYNAMIC_API_ROUTES.PROJECT_DIAGNOSES(projectId), ...DANDLE_OPTIONS });
export const useAPIDeskTicketsGET = () => useGet<DeskTicketsGetResponseDto[], {}>({ url: () => API_ROUTES.DESK_TICKETS, ...DESK_OPTIONS });
export const useAPIDeskTicketGET = () => useGet<DeskTicketGetResponseDto, { ticketId: string }>({ url: ({ ticketId }: { ticketId: string }) => DYNAMIC_API_ROUTES.DESK_TICKET(ticketId), ...DESK_OPTIONS });
export const useAPIDeskTicketTemplatesGET = () => useGet<DeskTicketTemplatesGetResponseDto[], {}>({ url: () => API_ROUTES.DESK_TICKET_TEMPLATES, ...DESK_OPTIONS });
type UseGoogleProps = {
    code: string;
};
export const useAPIAuthGoogleGET = () => useGet<AuthGoogleResponseDto, UseGoogleProps>({ url: ({ code }: UseGoogleProps) => DYNAMIC_API_ROUTES.AUTH_GOOGLE(code), ...DANDLE_OPTIONS });
export const useAPIBookmarksGET = () => useGet<BookmarkGetResponseDto[], ProjectStateProps> ({ url: ({ projectId }: ProjectStateProps) => DYNAMIC_API_ROUTES.BOOKMARKS(projectId), ...DANDLE_OPTIONS });

// PUT
export const useAPIProjectPUT = () => {
    const { mutate, isLoading } = usePut<ProjectPutRequestDto, ProjectPutResponseDto>({ ...DANDLE_OPTIONS });
    return {
        mutate: ({ projectId, params }: { projectId: string, params: ProjectPutRequestDto }) => {
            return mutate({
                url: DYNAMIC_API_ROUTES.PROJECT(projectId),
                params,
            });
        },
        isLoading,
    };
}
export const useAPIBookmarkPUT = () => {
    const { mutate, isLoading } = usePut<BookmarkPutRequestDto, BookmarkPutResponseDto>({ ...DANDLE_OPTIONS });
    return {
        mutate: ({ projectId, bookmarkId, params }: { projectId: string; bookmarkId: string; params: BookmarkPutRequestDto }) => {
            return mutate({
                url: DYNAMIC_API_ROUTES.BOOKMARK(projectId, bookmarkId),
                params,
            });
        },
        isLoading,
    };
}

// POST
export const useAPIProjectPOST = () => {
    const { mutate, isLoading } = usePost<ProjectPostRequestDto, ProjectPostResponseDto>({ ...DANDLE_OPTIONS });
    return {
        mutate: ({ params }: { params: ProjectPostRequestDto }) => {
            return mutate({
                url: API_ROUTES.PROJECTS,
                params,
            });
        },
        isLoading,
    };
}
export const useAPISurveyAnswersPOST = () => {
    const { mutate, isLoading } = usePost<SurveyPostAnswersRequestDto, SurveyPostAnswersResponseDto>({ ...GUEST_OPTIONS, ...ALWAYS_SEND_AUTH_TOKEN_OPTIONS });
    return {
        mutate: ({ surveyId, params }: { surveyId: string, params: SurveyPostAnswersRequestDto }) => {
            return mutate({
                url: DYNAMIC_API_ROUTES.SURVEY_POST_ANSWERS(surveyId),
                params,
            });
        },
        isLoading,
    };
};
export const useAPIProjectDiagnosesPOST = () => {
    const { mutate, isLoading } = usePost<ProjectDiagnosesPostRequestDto, ProjectDiagnosesPostResponseDto>({ ...DANDLE_OPTIONS });
    return {
        mutate: ({ projectId, params }: { projectId: string, params: ProjectDiagnosesPostRequestDto }) => {
            return mutate({
                url: DYNAMIC_API_ROUTES.PROJECT_DIAGNOSES(projectId),
                params,
            });
        },
        isLoading,
    };
};
export const useAuthMemberPOST = () => {
    const { mutate, isLoading } = usePost<AuthMemberPostRequestDto, AuthMemberPostResponseDto>({ ...FREE_OPTIONS });
    return {
        mutate: ({ params }: { params: AuthMemberPostRequestDto }) => mutate({
            url: API_ROUTES.AUTH_MEMBER,
            params,
        }),
        isLoading,
    };
};
export const useAPIToolPOST = () => useCommonProjectDependentPOST<ToolPostRequestDto, ToolPostResponseDto>(DYNAMIC_API_ROUTES.TOOLS);
export const useAPICalendarRoutinePOST = () => useCommonProjectDependentPOST<CalendarRoutinesPostRequestDto, CalendarRoutinesPostResponseDto>(DYNAMIC_API_ROUTES.ROUTINES);
export const useAPICalendarMeetingPOST = () => useCommonProjectDependentPOST<CalendarMeetingsPostRequestDto, CalendarMeetingsPostResponseDto>(DYNAMIC_API_ROUTES.MEETINGS);
export const useAPINotificationPOST = () => useCommonProjectDependentPOST<NotificationPostRequestDto, NotificationPostResponseDto>(DYNAMIC_API_ROUTES.NOTIFICATIONS);
export const useAPIMemberPOST = () => useCommonProjectDependentPOST<MemberPostRequestDto, MemberPostResponseDto>(DYNAMIC_API_ROUTES.MEMBERS);
export const useAPIToolSettingsPOST = () => {
    const { mutate, isLoading } = usePost<ToolSettingsPostRequestDto, ToolSettingsPostResponseDto>({ ...DANDLE_OPTIONS });
    return {
        mutate: ({ projectId, toolId, params }: { projectId: string; toolId: string; params: ToolSettingsPostRequestDto }) => mutate({
            url: DYNAMIC_API_ROUTES.TOOLS_SETTINGS(projectId, toolId),
            params,
        }),
        isLoading,
    };
};
export const useAPIDeskUploadFilePOST = () => {
    const { mutate, isLoading } = usePost<any, DeskUploadFilePostResponeDto>({ ...DESK_OPTIONS });
    return {
        mutate: ({ params }: { params: File }) => mutate({
            url: API_ROUTES.DESK_UPLOADS,
            params,
        }),
        isLoading,
    }
}
export const useAPIDeskTicketPOST = () => {
    const { mutate, isLoading } = usePost<DeskTicketPostRequestDto, DeskTicketPostResponseDto>({ ...DESK_OPTIONS });
    return {
        mutate: ({ params }: { params: DeskTicketPostRequestDto }) => mutate({
            url: API_ROUTES.DESK_TICKETS,
            params,
        }),
        isLoading,
    }
};
export const useAPIDeskTicketCommentPOST = () => {
    const { mutate, isLoading } = usePost<DeskTicketCommentPostRequestDto, DeskTicketCommentPostResponseDto>({ ...DESK_OPTIONS });
    return {
        mutate: ({ ticketId, params }: { ticketId: string; params: DeskTicketCommentPostRequestDto }) => mutate({
            url: DYNAMIC_API_ROUTES.DESK_TICKET_COMMENT(ticketId),
            params,
        }),
        isLoading,
    }
}
export const useAPIDeskUserPOST = () => {
    const { mutate, isLoading } = usePost<{}, {}>({ ...DESK_OPTIONS });
    return {
        mutate: () => mutate({
            url: API_ROUTES.DESK_USER,
            params: {},
        }),
        isLoading,
    };
};
export const useAPIBookmarkPOST = () => {
    const { mutate, isLoading } = usePost<BookmarkPostRequestDto, BookmarkPostResponseDto>({ ...DANDLE_OPTIONS });
    return {
        mutate: ({ projectId, params }: { projectId: string; params: BookmarkPostRequestDto } ) => mutate({
            url: DYNAMIC_API_ROUTES.BOOKMARKS(projectId),
            params,
        }),
        isLoading,
    };
};


// DELETE
export const useAPIProjectDELETE = () => {
    const { mutate, isLoading } = useDelete();
    return {
        mutate: ({ id }: { id?: string }) => {
            return mutate(DYNAMIC_API_ROUTES.PROJECT(s(id)));
        },
        isLoading,
    };
};
export const useAPIRoutinesCalendarDELETE = () => useCommonProjectDependentDELETE(DYNAMIC_API_ROUTES.ROUTINES_DELETE);
export const useAPIToolsDELETE = () => useCommonProjectDependentDELETE(DYNAMIC_API_ROUTES.TOOLS_DELETE);
export const useAPINotificationsDELETE = () =>useCommonProjectDependentDELETE(DYNAMIC_API_ROUTES.NOTIFICATIONS_DELETE);
export const useAPIMeetingsCalendarDELETE = () => useCommonProjectDependentDELETE(DYNAMIC_API_ROUTES.MEETINGS_DELETE);
export const useAPIMemberDelete = () => useCommonProjectDependentDELETE(DYNAMIC_API_ROUTES.MEMBERS_DELETE);
export const useAPIBookmarkDelete = () => useCommonProjectDependentDELETE(DYNAMIC_API_ROUTES.BOOKMARK);

// BACKLOG // TODO: useGetに変更
type UseBacklogAPIProjectsGETParams = { tenantUrl: string, apiKey: string };
export const useBacklogAPIProjectsGET = () => useGet<BacklogProject[], UseBacklogAPIProjectsGETParams>({ url: ({ tenantUrl, apiKey }: UseBacklogAPIProjectsGETParams) => EXTERNAL_API_ROUTES.BACKLOG_PROJECTS(tenantUrl, apiKey) });
/*
export const useBacklogAPIProjectsGET = () => {
    const { mutate, isLoading } = useBacklog<BacklogProject[]>();
    return {
        mutate: ({ tenantUrl, apiKey }: UseBacklogAPIProjectsGETParams) => {
            return mutate({
                url: EXTERNAL_API_ROUTES.BACKLOG_PROJECTS(tenantUrl, apiKey),
            });
        },
        isLoading,
    };
};
*/
type UseBacklogAPIIssueTypesGETParams = { tenantUrl: string, projectId: string, apiKey: string };
 export const useBacklogAPIIssueTypesGET = () => useGet<BacklogIssueType[], UseBacklogAPIIssueTypesGETParams>({ url: ({ tenantUrl, projectId, apiKey }: UseBacklogAPIIssueTypesGETParams) => EXTERNAL_API_ROUTES.BACKLOG_ISSUE_TYPES(tenantUrl, projectId, apiKey) });
/*
export const useBacklogAPIIssueTypesGET = () => {
    const { mutate, isLoading } = useBacklog<BacklogIssueType[]>();
    return {
        mutate: ({ tenantUrl, projectId, apiKey }: UseBacklogAPIIssueTypesGETParams) => {
            return mutate({
                url: EXTERNAL_API_ROUTES.BACKLOG_ISSUE_TYPES(tenantUrl, projectId, apiKey),
            });
        },
        isLoading,
    };
};
*/
type UseBacklogAPIStatusesGETParams = UseBacklogAPIIssueTypesGETParams;
export const useBacklogAPIStatusesGET = () => useGet<BacklogStatus[], UseBacklogAPIStatusesGETParams>({ url: ({ tenantUrl, projectId, apiKey }: UseBacklogAPIStatusesGETParams) => EXTERNAL_API_ROUTES.BACKLOG_STATUSES(tenantUrl, projectId, apiKey) });
/*
export const useBacklogAPIStatusesGET = () => {
    const { mutate, isLoading } = useBacklog<BacklogStatus[]>();
    return {
        mutate: ({ tenantUrl, projectId, apiKey }: UseBacklogAPIStatusesGETParams) => {
            return mutate({
                url: EXTERNAL_API_ROUTES.BACKLOG_STATUSES(tenantUrl, projectId, apiKey),
            });
        },
        isLoading,
    };
};
*/
