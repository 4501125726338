import { Box } from "@mui/material";

const PageNotFound = () => {
  return (
    <Box sx={{ display: "block", textAlign: 'center' }}>
      ページが見つかりませんでした
    </Box>
  );
}
export default PageNotFound;
