import { Clear as ClearIcon } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { useCallback, useState } from "react";
import { UseFormRegister } from "react-hook-form";
import { FileAttachment, FormInputType } from "types";

const FileUploadArea = ({ label, name, register, onChange }: { label?: string; name?: string; register?: UseFormRegister<FormInputType>; onChange?: (attachments: FileAttachment[]) => void }) => {
  const [attachments, setAttachments] = useState<FileAttachment[]>([])

  const onAttachment = useCallback((fileList: FileList | null) => {
    console.debug('onAttachment', fileList);
    const files = Array.from(fileList || []);
    const newAttachments = files.map((f) => ({ filename: f.name, file: f }));
    const allAttachments = [...attachments, ...newAttachments];
    if (onChange) {
      onChange(allAttachments);
    }
    setAttachments(allAttachments);
  }, [attachments, onChange]);
  const onFileChange: React.ChangeEventHandler<HTMLInputElement> = useCallback((ev) => onAttachment(ev.target.files), [onAttachment]);
  const onClear = useCallback(() => setAttachments([]), []);

  let inputProps = { onChange: onFileChange };
  if (register) {
    inputProps = { ...register(name || label || 'attachments', { onChange: onFileChange }) };
  }
  console.debug({ inputProps });

  return (
    <Box sx={{ width: '100%', margin: '15px 0' }}>
      <Box>
        { label && <Typography variant="body1">{label}</Typography> }
        <Box>
          {
            attachments.map((attachment, index) => (
              <Box key={index}>{attachment.filename}</Box>
            ))
          }
        </Box>
      </Box>
      <Box sx={{ width: '100%' }}>
        <Button fullWidth variant="outlined" component="label">
          ファイルを追加
          <input hidden accept="image/*" multiple type="file" {...inputProps} />
        </Button>
        { attachments.length > 0 && <IconButton onClick={onClear}><ClearIcon /></IconButton> }
      </Box>
    </Box>
  )
};

export default FileUploadArea;
