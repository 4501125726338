import { Typography } from "@mui/material";
import { ACCOUNT_CREATION_PAGE } from "constants/constants";

const InvalidRoleTooltipContent = () => (
  <>
    <Typography>この機能を利用する権限がありません。</Typography><br />
    <Typography>有効化するためには、有料プランへのお申し込みが必要です。</Typography><br />
    <Typography><a href={ACCOUNT_CREATION_PAGE} target="_blank" rel="noreferrer">{"こちらのサイト"}</a>からお申し込みいただけます。</Typography><br />
  </>
);
export default InvalidRoleTooltipContent;
