import { ChoiceItemDto, SurveyDiagnosisGetResponseDto } from "types/api";

export const filterImportantSurveyDiagnosisData = (data: SurveyDiagnosisGetResponseDto) => {
    // チャートに必要なデータのみ抽出
  const results = data.results.choice.reduce(
    (object: Array<ChoiceItemDto>, result) => {
      // 空判定 (初回ループobject空)
      const isEmpty = (object: Array<ChoiceItemDto>) => {
        return Object.keys(object).length !== 0;
      };
      // 同カテゴリー判定
      const element =
        isEmpty(object) &&
        object.find((value: ChoiceItemDto) => value.category === result.category);
      if (element) {
        // 同カテゴリーがある場合、合算
        element.point += result.point;
        if (result.comment) {
          element.comment += `${result.comment} `;
        }
      } else {
        // 同カテゴリー1週目、新規に初期データを作成
        object.push({
          label: result.label,
          question: result.question,
          category: result.category,
          comment: result.comment || '',
          point: result.point,
        });
      }
      return object;
    },
    []
  );

  results.sort((a, b) => a.question.order > b.question.order ? 1 : -1);

  return results;
};
