import React from "react";
import { Box, Alert } from "@mui/material";
import { Loading } from "components";

type AlertType = {
  text: string;
  post: Object[]; // APIの項目など
};

interface AlertBoxProps {
  datas: AlertType[];
}

const AlertBox = ({ datas }: AlertBoxProps) => {
  const isLoading = datas.some((d) => !d.post);
  if (isLoading) {
    return <Loading name="AlertBox" />;
  }

  return (
    <Box>
      {datas.map(
        (data: AlertType, index: number) =>
          data.post.length === 0 && (
            <Alert key={`alert-${index}`} severity="error" sx={{ my: 1 }}>
              {data.text}
            </Alert>
          )
      )}
    </Box>
  );
};

export default AlertBox;
