import { Box, Typography } from "@mui/material";
import { FileLink } from "types";
import DownloadableFileLink from "./DownloadableFileLink";

const Attachments = ({ attachments } : { attachments: FileLink[] }) => {
  return attachments.length > 0 ? (
    <Box sx={{ marginTop: '10px' }}>
      <Typography variant="body1">添付ファイル</Typography>
      <Box sx={{ marginTop: '10px' }}>
        {
          attachments.map((attachment, index) => (
            <DownloadableFileLink key={index} filename={attachment.filename} filepath={attachment.filepath} />
          ))
        }
      </Box>
    </Box>
  ) : (<></>);
};

export default Attachments;
