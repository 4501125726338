import { useEffect, useState } from "react";
import { SxProps, Theme } from "@mui/material/styles";
import { Grid, InputAdornment } from "@mui/material";
import TextField from "@mui/material/TextField";
import { HeaderType } from "types";

export type ApiType = {
  tenantUrl: FormDataEntryValue | null;
  apiKey: FormDataEntryValue | null;
  projects: Array<string>;
};

interface TextFieldProps {
  item?: HeaderType;
  required?: boolean;
  values?: any;
  variant?: "outlined" | "standard" | "filled" | undefined;
  fullWidth?: boolean;
  multiline?: boolean;
  textarea?: boolean;
  InputPropsStart?: string;
  InputPropsEnd?: string;
  // register?: any;
  error?: boolean;
  // error?: FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined;
  helperText?: string;
  // helperText?:
  //   | string
  //   | FieldError
  //   | Merge<FieldError, FieldErrorsImpl<any>>
  //   | undefined;
  errorMessage?: string;
  sx?: SxProps<Theme>;
}

const BasicTextField = ({
  item,
  required = false,
  values,
  variant = "outlined",
  fullWidth = true,
  multiline = false,
  textarea = false,
  InputPropsStart,
  InputPropsEnd,
  // register,
  error = false,
  helperText,
  errorMessage,
  sx,
}: TextFieldProps) => {
  const [value, setValue] = useState("");
  const handleChange = (event: any) => {
    setValue(event.target.value);
  };
  useEffect(() => {
    if (values && item?.value === "tenantUrl") {
      setValue(values.tenantUrl);
    } else if (values && item?.value === "apiKey") {
      setValue(values.apiKey);
    } else if (values) {
      setValue(values);
    }
  }, [item?.value, values]);
  return (
    <Grid container key={item?.value} spacing={2} sx={{ mb: 1 }}>
      <Grid item xs={12}>
        <TextField
          required={required ? true : false}
          fullWidth={fullWidth ? true : false}
          multiline={multiline ? true : false}
          maxRows={textarea ? 10 : ""}
          id={item?.value}
          name={item?.value}
          label={item?.name}
          InputLabelProps={value ? { shrink: true } : undefined}
          value={value}
          onChange={handleChange}
          variant={variant}
          error={error}
          helperText={helperText}
          sx={sx}
          InputProps={
            InputPropsStart || InputPropsEnd
              ? {
                  startAdornment: (
                    <InputAdornment position="start">
                      {InputPropsStart}
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      {InputPropsEnd}
                    </InputAdornment>
                  ),
                }
              : undefined
          }
          // {...register("projectName", {
          //   required: errorMessage,
          // })}
        />
      </Grid>
    </Grid>
  );
};

export default BasicTextField;
