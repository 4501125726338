import React from "react";
import { Button, Typography } from "@mui/material";
import RocketIcon from "@mui/icons-material/Rocket";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { SxProps, Theme } from "@mui/material/styles";
import "./index.css";

type IconName = 'rocket' | 'addCircle' | 'PictureAsPdf';
export interface ButtonProps {
  text: string;
  id?: string;
  color?:
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning";
  variant?: "contained" | "outlined" | "text";
  icon?: IconName;
  submit?: boolean;
  sx?: SxProps<Theme>;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  fullWidth?: boolean;
}

const Icon = ({ icon }: { icon?: IconName }) => {
  switch (icon) {
    case "rocket":
      return <RocketIcon sx={{ mr: 1 }} />;
    case "addCircle":
      return <AddCircleIcon sx={{ mr: 1 }} />;
    case "PictureAsPdf":
      return <PictureAsPdfIcon sx={{ mr: 1 }} />;
    default:
      return <></>;
  }
};

const DEFAULT_SX = { my: 1 };

const CustomButton = ({
  text,
  id,
  color = "secondary",
  variant = "contained",
  icon,
  submit,
  sx,
  onClick,
  fullWidth,
}: ButtonProps) => {
  
  return (
    <Button
      type={submit ? "submit" : "button"}
      id={id ? id : undefined}
      color={color}
      variant={variant}
      onClick={onClick}
      sx={sx ? sx : DEFAULT_SX}
      fullWidth={fullWidth}
    >
      {icon && <Icon icon={icon} />}
      <Typography textAlign="center">{text}</Typography>
    </Button>
  );
};

export default CustomButton;
