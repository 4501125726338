import { Link, useNavigate, /* useNavigate */ } from "react-router-dom";
import { BasicTable } from "components";
import { TableRow, TableCell } from "@mui/material";
import { DeskTicketsGetResponseDto } from "types/api";
import { DYNAMIC_ROUTES } from "constants/routes";
import { format } from "date-fns";
import { HEADERS } from "constants/constants";
import { DESK_STATUSES } from "styles/constants";

const headers = [
  HEADERS.TITLE,
  HEADERS.ID,
  HEADERS.CREATED_DATE_TIME,
  HEADERS.UPDATED_DATE_TIME,
  HEADERS.STATUS,
];

const DeskListTable = ({ rows }: { rows: DeskTicketsGetResponseDto[]; }) => {
  const navigate = useNavigate();

  const onItemClick = (id: string) => {
    navigate(DYNAMIC_ROUTES.DESK_ITEM(id));
  };

  return (
    <BasicTable headers={headers}>
      {rows.length !== 0 ? (
        rows.map((row) => {
          const { subject, id, createdAt, updatedAt, status } = row;

          // DATE
          const DATE_FORMAT = 'yyyy/MM/dd HH:mm';
          const formattedCreatedAt = format(new Date(createdAt), DATE_FORMAT);
          const formattedUpdatedAt = format(new Date(updatedAt), DATE_FORMAT);

          const onClick = () => onItemClick(id);
          return (
            <TableRow
              key={id}
              sx={{ "&:last-child td": { border: "none" },  cursor: 'pointer', ':hover': { opacity: '0.8' } }}
              onClick={onClick}
            >
              <TableCell align="center">{subject}</TableCell>
              <TableCell align="center">{id}</TableCell>
              <TableCell align="center">{formattedCreatedAt}</TableCell>
              <TableCell align="center">{formattedUpdatedAt}</TableCell>
              <TableCell align="center">
                <span className={DESK_STATUSES[status as keyof typeof DESK_STATUSES]} style={{ padding: '5px 10px' }} >{status}</span>
              </TableCell>
            </TableRow>
          );
        })
      ) : (
        <TableRow sx={{ "&:last-child td": { border: "none" } }}>
          <TableCell align="center" colSpan={headers.length}>
            チケットがありません。
          </TableCell>
        </TableRow>
      )}
    </BasicTable>
  );
};

export default DeskListTable;
