// https://www.material-react-table.com/docs/guides/localization
// 古いバージョンに入っていない。最新のバージョンには入っている。
import { MRT_Localization_JA } from 'material-react-table/locales/ja';

/**
 * MaterialReactTableが使用されている場所でこのpropsを使うこと。
 */
export const MRT_COMMON_PROPS = {
  defaultColumn: {
    maxSize: 400,
    minSize: 50,
    size: 150, //default size is usually 180
  },
  enableColumnResizing: true,
  columnResizeMode: "onChange",
  localization: MRT_Localization_JA,
} as const;
