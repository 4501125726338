import { useCallback, useEffect, useMemo, useState } from "react";
import { BrowserRouter, Routes, Route, useParams, useLocation } from "react-router-dom";
import { AppState, useAuth0 } from "@auth0/auth0-react";
import { Layout, Loading } from "components";
// import MainLayout from "components/MainLayout/MainLayout";
import LeftNavMainLayout from "components/MainLayout/LeftNavMainLayout";
import {
  getDefaultItems,
  getNoPageItems,
  getRouteItemsByRequiredRoles,
  // getAllItems,
} from "routes/route-items";

// GLOBAL CSS
import "./App.css";
import "./styles/desk-status.css";

import { useAppState, useProject, useRoles } from "hooks";
import { RouteItem, RoleKey } from "types";
import PageNotFound from "components/PageNotFound";
import { AppStateContext } from "state";
import { Auth0Provider } from "@auth0/auth0-react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GOOGLE_TAG_MANAGER_EVENTS, DRAWER_INITIALLY_OPENED, DRAWER_TOGGLEABLE } from "constants/constants";
import TagManager from 'react-gtm-module';
// @ts-ignore
import { HubspotProvider } from "@aaronhayes/react-use-hubspot-form";
import { getRouteViews } from "routes/route-views";
import { getParsedEnvironmentVariables, shimCrypto } from "utilities";
import GlobalSnackbar from "components/GlobalSnackbar/GlobalSnackbar";
import React from "react";
import { debugHandling } from "./debug";


function onAppStart() {
  const { NODE_ENV, REACT_APP_DEBUG } = getParsedEnvironmentVariables();

  // consoleを無効にする
  // console.debug({ NODE_ENV });
  if (NODE_ENV === 'production') {
    console.debug = () => {}
    console.log = () => {}
    console.warn = () => {}
    console.error = () => {}
  } else if (NODE_ENV === 'test') {
    console.debug = () => {}
    console.log = () => {}
  } else {
    // ほかは開発環境です。
    if (REACT_APP_DEBUG) {
      debugHandling();
    }
  }

  // 開発環境 = development。productionの場合、consoleが無効になったため、表示しないはず。
  console.debug('Build:', NODE_ENV);
}

onAppStart();

const env = getParsedEnvironmentVariables();
// console.debug({ NODE_ENV: env.NODE_ENV });

shimCrypto();

const CustomLeftNavMainLayout = ({ listItems }: { listItems: RouteItem[] }) => {
  // レフトナビ表示非表示
  const [open, setOpen] = useState(DRAWER_INITIALLY_OPENED);
  const toggleDrawer = () => {
    if (DRAWER_TOGGLEABLE) {
      setOpen(!open);
    }
  };

  return (
    <LeftNavMainLayout
      open={open}
      toggleDrawer={toggleDrawer}
      listItems={listItems}
    />
  );
};

/*
const GuestRoutes = () => {
  return (
    <>{getRouteViews(getRouteItemsByRequiredRoles(['guest']))}</>
  );
};

const FreeRoutes = () => {
  return (
    <>{getRouteViews(getRouteItemsByRequiredRoles(['free']))}</>
  );
};

const DandleRoutes = () => {
  return (
    <>{getRouteViews(getRouteItemsByRequiredRoles(['dandle']))}</>
  );
};

const DeskRoutes = () => {
  return (
    <>{getRouteViews(getRouteItemsByRequiredRoles(['desk']))}</>
  );
};
*/

/**
 * TODO: QUICK HACK
 */
function getProjectIdFromPathname (pathname: string) {
  const parts = pathname.split('/');
  if (parts[1] === 'projects' && !!parts[2]) {
    return parts[2] as string;
  } else {
    return undefined;
  }
}

const StaticLayout = React.memo(Layout);
export function BrowserRouterContent() {
  console.debug('BrowserRouterContent render');
  const [state] = useAppState();
  // const projectId = state.projectId;
  const location = useLocation();
  const projectId = getProjectIdFromPathname(location.pathname);
  // const { projectId } = useProject();
  // const { project, projectId, setProjectFromParams } = useProject();
  // useEffect(() => setProjectFromParams(), [setProjectFromParams]);
  // const { projectId } = useParams();
  const params = useParams();
  const { isAuthenticated, isLoading: isAuth0Loading, getAccessTokenSilently } = useAuth0();
  const [roles, setRoles] = useState<RoleKey[]>([]); // TODO: ステート修正後、roles, setRolesはuseRolesから。
  const { getRoles } = useRoles();

  // Roles
  useEffect(() => {
    const attemptSetRoles = async () => {
      const r = await getRoles();
      setRoles(r);
    };
    attemptSetRoles();
  }, [getRoles, isAuthenticated]);

  // Logout check interval
  /*
  // 401チェック用。仕様上必要になれば、導入してみる:
  const getAccessToken = async () => getAccessTokenSilently({ ignoreCache: true })
  useEffect(() => {
    const onIsLoggedOut = () => {
      //
    };
    const clearInterval = startWatchingForLogout(getAccessToken, onIsLoggedOut);

    const startWatchingForLogout = (getAccessToken, onIsLoggedOut) => {
      const LOGOUT_CHECK_INTERVAL = 60 * 1000;
      const ssoInterval = setInterval(async () => {
        try {
          const sso = await getAccessToken()
          if (!sso) {
              onIsLoggedOut();
          }
        } catch (error) {
            const { error: auth0Error } = (error as { error: string });
            if (auth0Error === "login_required") {
              onIsLoggedOut();
            }
            // OFFLINE: OK
        }
      }, LOGOUT_CHECK_INTERVAL);
      return () => clearInterval(ssoInterval)
    };
  }, [getAccessTokenSilently]);
  */
 
  useEffect(() => console.debug('BrowserRouterContent projectId change', { projectId }), [projectId]);
  console.debug('BrowserRouterContent', { projectId, params, location, state, roles, isAuthenticated });

/*
{ roles.includes('dandle') && DandleRoutes() }
{ roles.includes('desk') && DeskRoutes() }
{ !(LICENSED_ROLES.some((r) => roles.includes(r))) && isAuthenticated && FreeRoutes() }
{ !isAuthenticated && GuestRoutes() }
*/
  
  const Elements = useCallback(() => {
    console.debug('Elements recreate', { projectId, isAuthenticated, roles });
    const params = { projectId };
    const routedListItems = getDefaultItems(roles, params);
    const noRouteListItems = getNoPageItems() as any;
    const routeItems = getRouteItemsByRequiredRoles(roles);
    const routeViews = getRouteViews(routeItems);

    console.debug({ params, roles, routedListItems, noRouteListItems, routeItems, routeViews })

    // TODO: PageNotFoundは一瞬表示する。なんで？なにがLoading?
    // TODO: is app loading boolean hook.

    return (
      <Routes>
        { /*
        { licensed && <DandleRoutes /> }
        { !licensed && isAuthenticated && <FreeRoutes /> }
        { !isAuthenticated && <GuestRoutes /> }
        */ }
        <Route element={<StaticLayout />} key="Layout">
          <Route element={<CustomLeftNavMainLayout listItems={routedListItems} />} >
            {routeViews}
          </Route>
        </Route>

        {/* 404エラー対応 */}
        <Route element={<StaticLayout />} key="Layout">
          <Route element={<CustomLeftNavMainLayout listItems={noRouteListItems} />} >
            <Route path="*" element={<PageNotFound />} />    
          </Route>
        </Route>
      </Routes>
    );
  }, [projectId, isAuthenticated, roles]);

  // Loading
  const isLoading = isAuth0Loading;
  if (isLoading) {
    return <Loading name="App" />;
  }

  return (
    <Elements />
  )
}

function initializeGoogleTagManager() {
  const gtmId = env.REACT_APP_GTM_ID;
  if (!gtmId) {
    console.warn('gtmIdがないため、無視する。')
    return;
  }
  const tagManagerArgs = {
    gtmId,
    events: GOOGLE_TAG_MANAGER_EVENTS,
  };

  TagManager.initialize(tagManagerArgs);
}
initializeGoogleTagManager();

const onAuthRedirectCallback: ((appState?: AppState | undefined) => void) = (state) => {
  // https://community.auth0.com/t/how-do-i-access-the-appstate-i-set-in-loginwithredirect-in-a-react-app/53162/3
  // loginWithRedirect({ appState })
  // TODO: ここでメンバーをDBに登録しておく?
};

const StaticBrowserRouterContent = React.memo(BrowserRouterContent);
const StaticGlobalSnackbar = React.memo(() => (
  <GlobalSnackbar>
    <BrowserRouter>
      <StaticBrowserRouterContent />
    </BrowserRouter>
  </GlobalSnackbar>
));

function App() {
  console.debug('App render');
  
  return (
    /*
    const [appProviderValue] = useAppState();
    return (
      <AppStateContext.Provider value={appProviderValue}>
        <BrowserRouter>
          <BrowserRouterContent />
        </BrowserRouter>
      </AppStateContext.Provider>
    );
    */
    <HubspotProvider>
      <Auth0Provider
        domain={env.REACT_APP_AUTH0_DOMAIN}
        clientId={env.REACT_APP_AUTH0_CLIENT_ID}
        audience={env.REACT_APP_AUTH0_AUDIENCE}
        redirectUri={window.location.origin}
        useRefreshTokens={true}
        scope="offline_access"
        onRedirectCallback={onAuthRedirectCallback}
      >
        <GoogleOAuthProvider clientId={env.REACT_APP_CLIENT_ID}>
          <StaticGlobalSnackbar />
        </GoogleOAuthProvider>
      </Auth0Provider>
    </HubspotProvider>
  );
}

export default App;
