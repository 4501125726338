import { Textarea } from "@mui/joy";
import { Box, Button, Card } from "@mui/material";
import FileUploadArea from "components/FileUploadArea";
import { useState } from "react";
import { Attachment, FileAttachment } from "types";
import { CssVarsProvider } from '@mui/joy/styles';

export interface DeskItemEditorSubmitParams {
    text: string;
    attachments: FileAttachment[];
}

const DeskItemEditor = ({ onSubmit }: { onSubmit: (params: DeskItemEditorSubmitParams) => void }) => {
    const [text, setText] = useState('');
    const [attachments, setAttachments] = useState<FileAttachment[]>([])
    const onSubmitClick = () => {
        if (!text) {
            return;
        }
        onSubmit({ text, attachments });
    };

    const onAttachmentsChange = (attachments: FileAttachment[]) => {
        console.debug('onAttachmentsChange', { attachments });
        setAttachments(attachments);
    };

    const isError = !text.trim();
    const errorText: string = isError ? '文字を入力してください。' : '';

    return (
        <Card sx={{ padding: '10px', margin: '10px' }}>
            <Box>
                <CssVarsProvider>
                    <Textarea required error={isError} defaultValue={errorText} minRows={4} style={{ width: '100%' }} value={text} variant="outlined" onChange={(ev) => setText(ev.target.value)} />
                </CssVarsProvider>
            </Box>
            <Box>
                <FileUploadArea onChange={onAttachmentsChange}  />
            </Box>
            <Box sx={{ textAlign: 'center' }}>
                <Button color="secondary" variant="contained" sx={{ my: 1 }} onClick={onSubmitClick}>送信</Button>
            </Box>
        </Card>
    )
};

export default DeskItemEditor;
