import { LOCAL_STORAGE_KEY } from "constants/constants";

/**
 * FLAT(階層なし)に保持。
 * 
 * version履歴:
 * 1.0.0
 */
interface LocalStorageData {
  version: string; // versionによって修正が入れば、対応が必要のため。
  tasksListTableRowsPerPage: number;
}

const createLocalStorageData = (): LocalStorageData => ({
  version: '1.0.0',
  tasksListTableRowsPerPage: 10,
});

export default class LocalStorageHandler {
  data() {
    return this.d();
  }

  set(key: keyof LocalStorageData, value: any) {
    const newObj = { ...(this.d()), [key]: value };
    this.s(newObj);
  }

  private d() {
    const rawCheck = localStorage.getItem(LOCAL_STORAGE_KEY) || '';
    let obj!: LocalStorageData;
    try {
      obj = JSON.parse(rawCheck);
      if (!obj) throw new Error('JSON parse error. Will catch and handle.');
    } catch (err) {
      obj = createLocalStorageData();
      this.s(obj);
    }
    return obj;
  }

  private s(obj: LocalStorageData) {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(obj));
  }
}
