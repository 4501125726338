import { useGoogleLogin,  } from "@react-oauth/google";
import { useAPIAuthGoogleGET } from "api";
import { GOOGLE_AUTH_SCOPE } from "constants/constants";
import { useGoogle } from "hooks";
import { useCallback } from "react";
import { AuthResponseDto, CalendarSuccessParams } from "types";

const useCalendars = (type: 'google', onCalendarsSuccess: (params: CalendarSuccessParams) => void, onCalendarsFailure: (errorMessage: string) => void) => {
  // const { mutate: execute, isLoading: isLoadingGoogle } = useGoogle(); // カレンダー情報取得
  const { execute, isLoading: isLoadingGoogle } = useAPIAuthGoogleGET(); // これで問題なければ、useGoogleを消すこと。

  const onCalendars = useCallback(({ data, success }: { data: AuthResponseDto; success: boolean; }) => {
    console.debug('onCalendars', { data, success });
    const calendars = (success && data) ? data.responses.calendars : null;
    const auth = data.auth;
    if (success) {
      onCalendarsSuccess({ calendars, auth });
    } else {
      console.error(data);
      onCalendarsFailure("カレンダーの取得に失敗しました。");
    }
  }, [onCalendarsFailure, onCalendarsSuccess]);
  // googleログイン処理
  const googleLogin = useGoogleLogin({
    flow: "auth-code",
    scope: GOOGLE_AUTH_SCOPE,
    onSuccess: async ({ code }) => {
      console.debug('onSuccess', { code });
      // ログイン出来た場合
      const r = await execute({ code }); if (!r) throw new Error('No response');
      const { success, data } = r;
      // const { success, data } = await execute({ code });
      onCalendars({ data, success });
    },
    onError: (errorResponse) => console.error(errorResponse),
  });
  //「カレンダーを登録する」ボタン押下時、ログインしてモーダル表示
  const handleClickAddCalendar = () => {
    if (type === 'google') {
      googleLogin(); // googleログイン
    } else {
      throw new Error(`Invalid type ${type}`);
    }
  };

  return {
    handleClickAddCalendar,
    isLoadingGoogle,
    isLoading: isLoadingGoogle,
  };
};

export default useCalendars;
