import * as React from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { Box, ListItemButton, ListItemIcon, ListItemText, styled, SxProps, Theme, Tooltip, tooltipClasses, TooltipProps } from "@mui/material";
import { RouteItem } from "types";
import { ICON_MAP } from "./iconMap";
import { routeItemHasUnparsedPathKey } from "utilities";

/**
 * @see https://mui.com/material-ui/react-tooltip/#variable-width
 */
const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'none',
  },
});

interface ListItemProps {
  item: RouteItem;
  isChild?: boolean;
}

const ListItem = ({ item, isChild }: ListItemProps) => {
  const { pathname } = useLocation();
  let { icon, text, to, items, disabled, tooltip } = item;

  const Icon = ({ icon }: { icon: keyof typeof ICON_MAP }) => {
    return Object.keys(ICON_MAP).includes(icon) ? ICON_MAP[icon]() : <></>;
  };

  const stopPropagation = (ev: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    ev.persist(); 
    ev.stopPropagation();
    ev.nativeEvent.stopImmediatePropagation();
  };

  const onMouseAction: React.MouseEventHandler<HTMLAnchorElement> = (ev) => {
    if (isChild) {
      stopPropagation(ev);
    }
  };

  const fItems = items || [];
  const hasChildren = fItems.length > 0;
  const textClassName = isChild ? 'ListItem_ListItemComponent_Child_ListItemText' : 'ListItem_ListItemComponent_Parent_ListItemText';
  const sx: SxProps<Theme> = { alignItems: 'baseline', flexDirection: 'column' };
  // if (disabled) sx.pointerEvents = 'none';

  const optional: Record<any, any> & { to: string } = { to: '' };
  /*
  const withNoParentClick = () => {
    if (hasChildren) {
      sx.cursor = 'default'; sx.pointerEvents = 'none';
    } else {
      optional.to = to || ''; // 親はクリック不可
    }
  };
  withNoParentClick();
  */
  optional.to = to || '';
  if (routeItemHasUnparsedPathKey(item)) {
    optional.to = '';
    disabled = true;
  }
  optional.className = isChild ? 'ListItem_ListItemComponent_Child' : 'ListItem_ListItemComponent_Parent';
  
  const ListItemComponent = () => {
    return (
      <Box>
        <ListItemButton
          component={Link}
          selected={to === pathname}
          sx={sx}
          onClick={onMouseAction as any}
          disabled={disabled || false}
          {...optional}
        >
          <span style={{ display: 'flex', alignItems: 'center' /* alignItems: 'baseline' */ }}>
            <ListItemIcon>
              <Icon icon={icon} />
            </ListItemIcon>
            <ListItemText primary={text} className={textClassName} />
          </span>
        </ListItemButton>
        <Box sx={{ width: '100%', paddingLeft: '16px' }}>
          {
            // disabledの項目は表示しなくてよい。
            (!disabled ? fItems : []).map((i, index) => (
              <ListItem key={index} item={i} isChild={true} />
            ))
          }
        </Box>
      </Box>
    );
  };

  const DisabledListItemComponent = () => {
    return (
      <NoMaxWidthTooltip title={tooltip || ''} >
        <div>
          <ListItemComponent />
        </div>
      </NoMaxWidthTooltip>
    );
  };

  // disabledにしてもTooltip表示ができるように: Tooltip > div > ListItemButton
  return (
    <React.Fragment>
      {
        !item.disabled ? (<ListItemComponent />) : (<DisabledListItemComponent />)
      }
    </React.Fragment>
  );
};
export default ListItem;
