import { TableRow, TableCell, IconButton, Box, TextField } from "@mui/material";
import { BasicTable, CustomButton, Loading, Title } from "components";
import { DATA_CLASSES, HEADERS, IDS } from "constants/constants";
import DeleteIcon from "@mui/icons-material/Delete";
import { BookmarkDto, BookmarkGetResponseDto, BookmarkPostRequestDto, BookmarkPutRequestDto } from "types/api";
import DeleteModal from "components/DeleteModal";
import { BOOKMARK_FOLDER_CREATE_TITLE, getDeleteTargetTitleText } from "constants/messages";
import { Add, ArrowCircleDown, ArrowCircleUp, KeyboardArrowDown, KeyboardArrowRight, OpenInNew } from "@mui/icons-material";
import { Dispatch, SetStateAction, useState } from "react";
import { openLinks } from "utilities";
import { BookmarkCreateFormData, BookmarkFormData, ValuesType } from "types";
import BookmarkModal from "./BookmarkModal";

const headers = [
  HEADERS.NAME,
  HEADERS.URL,
  HEADERS.ADD,
  HEADERS.OPEN,
  HEADERS.ORDER,
  HEADERS.DELETE,
];

interface BookmarkEvents {
  onCreate: (data: BookmarkPostRequestDto) => void;
  onEdit: (id: string, data: BookmarkPutRequestDto) => void;
  onDelete: (id: string) => void;
}

interface RowInfo { key: string; depth: number; parentItem?: BookmarkDto; isExpanded: boolean; isExpandedChild: boolean; index: number; lastSiblingIndex: number; row: BookmarkDto }

interface AddItemParams { order?: number; parent?: string, url?: string }

const getBookmarkBookmarkCreateFormDataDefaults = () => {
  const data: BookmarkPostRequestDto = {
    name: '',
    order: null,
    parent: null,
    url: null,
  };
  return data;
};
const createBookmarkCreateFormData = ({ order, parent, url }: AddItemParams = {}, rows?: BookmarkDto[]): BookmarkCreateFormData => {
  const DEFAULT_ORDER = 0;
  const maxOrder = (rows && rows.length > 0) ? Math.max(...(rows.map((row) => row.order)), DEFAULT_ORDER) : null;
  
  const defaults = getBookmarkBookmarkCreateFormDataDefaults();
  const data: BookmarkPostRequestDto = {
    name: defaults.name,
    order: order !== undefined ? order : (maxOrder !== null ? maxOrder + 1 : defaults.order), // Highest order + 1
    parent: parent !== undefined ? parent : defaults.parent,
    url: url !== undefined ? url : defaults.url,
  };
  const bookmarkFormatData: BookmarkCreateFormData = {
    type: 'create',
    data,
  };
  console.debug('createBookmarkCreateFormData', { order, parent, data, bookmarkFormatData });
  return bookmarkFormatData;
}

const BookmarkPageTableRow = ({ rowInfo, index, onExpandedChange, addItem, onEdit, onDeleteClick }: { rowInfo: RowInfo; index: number, onExpandedChange: (row: BookmarkDto, nextExpandedStatus: boolean) => void, onDeleteClick: (values: ValuesType) => void, addItem: (params?: AddItemParams) => void } & BookmarkEvents) => {
  const { row, depth, isExpanded, isExpandedChild, index: siblingIndex, lastSiblingIndex } = rowInfo;
  const { id, parent, url, name, order, items } = row;
  const [inputs, setInputs] = useState({ name, url });

  const getLinks = () => {
    const links: string[] = [];

    if (row.url) {
      links.push(row.url);
    }

    const childLinks = (row.items && row.items.length) ? row.items.map((item) => item.url).filter((url) => !!url) as string[] : [];
    links.push(...childLinks);

    return links;
  };

  // State
  const links = getLinks();
  const hasItems = items && items.length > 0;
  const urlTitle = links.join(', ');
  const isDirectory = url === null;
  const isShown = !parent || isExpanded || isExpandedChild;
  const addItemParent = isDirectory ? id : (parent || undefined);
  const allowAddHere = isDirectory;
  const allowOpenLinks = links.length > 0;
  console.debug({ id, name, url, order, links, depth, hasItems, urlTitle, isDirectory, isExpanded, addItemParent, allowAddHere, allowOpenLinks });

  const getOrderUpTitle = () => `${order} => ${order - 1}`;
  // const getOrderUpTitle = () => '';
  const getOrderDownTitle = () => `${order} => ${order + 1}`;
  // const getOrderDownTitle = () => '';

  const onItemTogglerClick = () => {
    onExpandedChange(row, !isExpanded);
  };

  const onAddItemHereClick = () => {
    if (!parent) {
      console.warn('ブックマークダイレクトリ以下で追加しようとしたが、parentがない。', row);
    }
    addItem({
      order,
      parent: addItemParent,
      url: '',
    })
  };
  
  const onOpenItemClick = () => {
    console.debug('openLinks', links);
    return openLinks(links);
  };

  const onItemOrderUp = () => {
    onEdit(id, { parent, url, name, order: order - 1 });
  };

  const onItemOrderDown = () => {
    onEdit(id, { parent, url, name, order: order + 1 });
  };

  const onDeleteItemClick = ({ id, name }: { id: string; name: string }) => {
    onDeleteClick({ id, name });
  };

  const onPropertyChange = (key: 'url' | 'name', value: string) => {
    const changed: Partial<BookmarkPutRequestDto> = {};
    if (key === 'url') {
      if (value === url) return; // ignore
      changed.url = value;
    }
    if (key === 'name') {
      if (value === name) return; // ignore
      changed.name = value;
    }
    onEdit(id, { parent, url, name, order, ...changed });
  };

  const onInputChange = (changed: { name?: string; url?: string }) => {
    console.debug('onInputChange', { changed });
    setInputs({
      ...inputs,
      ...changed,
    });
  };

  return (
    isShown ? <TableRow
      sx={{ "&:last-child td": { border: 0 } }}
    >
      <TableCell align="left">
        <>
          <span>
            { (new Array(depth)).fill('').map((_, index) => <span key={index} style={{ padding: '4px' }}>　</span>) }
          </span>
          <IconButton onClick={onItemTogglerClick} sx={{ visibility: hasItems ? 'visible' : 'hidden' }}>
            {isExpanded ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
          </IconButton>
          <TextField sx={{ marginLeft: '20px' }} placeholder="名" value={inputs.name} onChange={(ev) => onInputChange({ name: ev.target.value })} onBlur={(ev) => onPropertyChange('name', ev.target.value)} />

        </>
      </TableCell>
      <TableCell align="center">
        {
          !isDirectory && <TextField placeholder="URL" value={inputs.url} onChange={(ev) => onInputChange({ url: ev.target.value })} onBlur={(ev) => onPropertyChange('url', ev.target.value)} />
        }
      </TableCell>
      <TableCell align="center">
        {
          allowAddHere && <IconButton onClick={onAddItemHereClick}><Add /></IconButton>
        }
      </TableCell>
      <TableCell align="center">
        {
          allowOpenLinks && <IconButton title={urlTitle} onClick={onOpenItemClick}><OpenInNew /></IconButton>
        }
      </TableCell>
      <TableCell align="center">
        { siblingIndex > 0 && <IconButton title={getOrderUpTitle()} onClick={onItemOrderUp}><ArrowCircleUp /></IconButton> }
        { siblingIndex < lastSiblingIndex && <IconButton title={getOrderDownTitle()} onClick={onItemOrderDown}><ArrowCircleDown /></IconButton> }
      </TableCell>
      <TableCell align="center">
        <IconButton
          className={DATA_CLASSES.DELETE_BOOKMARK_ITEM_BUTTON}
          onClick={() =>
            onDeleteItemClick({
              id, name,
            })
          }
        >
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow> : <></>
  );
};

const LoadingRow = ({ colSpan }: { colSpan: number }) => {
  return (
    <TableRow
      sx={{ textAlign: 'center' }}
    >
      <TableCell align="center" colSpan={colSpan}><Loading /></TableCell>
    </TableRow>
  );
};

const flattenRows = (rows: BookmarkGetResponseDto[], expanded: string[]) => {
  const fRows: RowInfo[] = [];

  const handleItems = (items: BookmarkDto[], depth: number, parentItem?: BookmarkDto) => {
    const lastSiblingIndex = items.length - 1;
    items.forEach((item, index) => {
      fRows.push({
        key: item.id,
        depth,
        parentItem,
        isExpanded: expanded.includes(item.id),
        isExpandedChild: !!parentItem && expanded.includes(parentItem.id),
        index,
        lastSiblingIndex,
        row: item
      });

      const nestedItems = item.items;
      if (nestedItems) {
        handleItems(nestedItems, depth + 1, item);
      }
    })
  };
  handleItems(rows, 0);

  return fRows;
}

type BookmarksPageTableProps = {
  rows: BookmarkGetResponseDto[];
  loadingIds: string[];
  expanded: string[]; setExpanded: Dispatch<SetStateAction<string[]>> // expanded
} & BookmarkEvents;
const BookmarksPageTable = ({ rows, loadingIds, expanded, setExpanded, ...events }: BookmarksPageTableProps) => {
  const { onCreate, onEdit, onDelete } = events;

  // const [expanded, setExpanded] = useState<string[]>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalData, setModalData] = useState<BookmarkFormData|null>(null);
  const [selectedItem, setSelectedItem] = useState<ValuesType | null>(null);

  /**
   * idの重複は不可。
   * idなくても削除はエラーなし。
   */
  const onExpandedIdChange = (id: string, nextExpandedStatus: boolean) => {
    const inExpanded = expanded.includes(id);
    const nextExpanded = [...expanded];
    if (nextExpandedStatus && !inExpanded) {
      nextExpanded.push(id);  
    } else if (!nextExpandedStatus && inExpanded) {
      nextExpanded.splice(expanded.indexOf(id), 1);
    }

    setExpanded(nextExpanded);
  };
  const onExpandedChange = (row: BookmarkDto, nextExpandedStatus: boolean) => {
    const { id } = row;
    return onExpandedIdChange(id, nextExpandedStatus);
  };

  // DELETE
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const handleOpenDeleteModal = (values: ValuesType) => {
    setSelectedItem(values);
    setOpenDeleteModal(true);
  };
  const handleCloseDeleteModal = () => setOpenDeleteModal(false);
  const handleDelete = () => {
    if (selectedItem) {
      const { id } = selectedItem;
      onDelete(id);
      onExpandedIdChange(id, false);
    }
  };

  // BOOKMARK MODAL
  const openBookmarkModal = (bookmarkFormatData: BookmarkFormData) => {
    setModalData(bookmarkFormatData);
    setModalOpen(true);
  };
  const onModalClose = () => {
    setModalData(null);
    setModalOpen(false);
  };

  const flattenedRows = flattenRows(rows, expanded);

  const onSubmitData = (bookmarkFormData: BookmarkFormData) => {
    console.debug('onSubmitData', bookmarkFormData, JSON.stringify(bookmarkFormData));
    const nonEditedData = modalData?.data || {};
    bookmarkFormData.data = { ...nonEditedData, ...bookmarkFormData.data, };
    if (bookmarkFormData.type === 'create') {
      onCreate(bookmarkFormData.data);
    } else if (bookmarkFormData.type === 'edit') {
      onEdit(bookmarkFormData.id, bookmarkFormData.data);
    }
    if (bookmarkFormData.data.parent) {
      onExpandedIdChange(bookmarkFormData.data.parent, true);
    }
  };
  
  // ADD
  const addItem = (addItemParams: AddItemParams = {}) => {
    const bookmarkFormatData = createBookmarkCreateFormData(addItemParams, rows);
    openBookmarkModal(bookmarkFormatData);
  };
  const onAddItemClick = () => addItem();

  const isLoading = (id: string) => {
    return loadingIds.includes(id);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <>
          <Title title={'ブックマーク一覧'} icon="list" size="subtitle1" />
          <CustomButton
            id={IDS.CREATE_BOOKMARK_BUTTON}
            text={BOOKMARK_FOLDER_CREATE_TITLE}
            onClick={onAddItemClick}
            icon="addCircle"
          />
        </>
      </Box>

      <BasicTable headers={headers}>
        {flattenedRows.length !== 0 ? (
          flattenedRows.map((flattenedRow, i: number) => (
            isLoading(flattenedRow.row.id) ? <LoadingRow colSpan={headers.length} /> : <BookmarkPageTableRow rowInfo={flattenedRow} index={i} key={i} onExpandedChange={onExpandedChange} onDeleteClick={handleOpenDeleteModal} addItem={addItem} {...events} />
          ))
        ) : (
          <TableRow sx={{ "&:last-child td": { border: "none" } }}>
            <TableCell align="center" colSpan={headers.length}>
              ブックマークを登録してください。
            </TableCell>
          </TableRow>
        )}
      </BasicTable>

      { modalData ? <BookmarkModal data={modalData} open={modalOpen} onSubmit={onSubmitData} onClose={onModalClose} /> : <></> }
      { selectedItem ? <DeleteModal title="ブックマーク削除" text={getDeleteTargetTitleText(selectedItem.name, 'ブックマーク名')} open={openDeleteModal} onClick={handleDelete} onClose={handleCloseDeleteModal} /> : <></> }
    </>
  );
};

export default BookmarksPageTable;
