import { Link, /* useNavigate */ } from "react-router-dom";
import { BasicTable, CustomLinkButton } from "components";
import { TableRow, TableCell, IconButton } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import { formatDate } from "models";
import { DYNAMIC_ROUTES, ROUTES } from "constants/routes";
import { useAppState } from "hooks";
import { DATA_CLASSES, HEADERS } from "constants/constants";
import { ProjectsWithAlertsGetResponseDto } from "types/api";

const headers = [
  HEADERS.PROJECT_NAME,
  HEADERS.PROJECT_ID,
  HEADERS.CREATED_DATE,
  HEADERS.BOOKMARKS,
  HEADERS.NO_ASSIGNEE_TASK,
  HEADERS.EXPIRED_TASK,
  HEADERS.NO_DUE_TASK,
  HEADERS.SETTING
];

interface ProjectListTableProps {
  rows: ProjectsWithAlertsGetResponseDto[];
}

const ProjectListTable = ({ rows }: ProjectListTableProps) => {
  // const navigate = useNavigate();
  const [state, setAppState] = useAppState();

  const getProjectTasksURL = (projectId: string) => DYNAMIC_ROUTES.PROJECT_TASKS(projectId);
  const onProjectTasksClick = (projectId: string) => {
    setAppState({ ...state, projectId });
    // navigate(getProjectTasksURL(projectId));
  };
  const getProjectSettingsURL = (projectId: string) => DYNAMIC_ROUTES.PROJECT_SETTINGS(projectId);
  const onProjectSettingsClick = (projectId: string) => {
    setAppState({ ...state, projectId });
    // navigate(getProjectSettingsURL(to));
  };

  return (
    <BasicTable headers={headers}>
      {rows.length !== 0 ? (
        rows.map((row) => {
          const { id, name, createdAt, tools } = row;
          const alerts = {
            expired: 0,
            no_assignee: 0,
            no_due: 0,
          };
          
          alerts.expired += tools.expired;
          alerts.no_assignee += tools.no_assignee;
          alerts.no_due += tools.no_due;
        
          const date = formatDate(new Date(createdAt));
          const onCurrentProjectTasksClick = () => onProjectTasksClick(id);
          const onCurrentProjectSettingsClick = () => onProjectSettingsClick(id);
          return (
            <TableRow
              key={id}
              sx={{ "&:last-child td": { border: "none" } }}
            >
              <TableCell align="center">
                <CustomLinkButton
                  className={DATA_CLASSES.PROJECT_ITEM_TASKS_LINK}
                  text={name}
                  color="info"
                  to={getProjectTasksURL(id)}
                  onClick={onCurrentProjectTasksClick}
                  variant="text"
                  sx={{ textTransform: "none" }}
                />
              </TableCell>
              <TableCell align="center">{id}</TableCell>
              <TableCell align="center">{date}</TableCell>
              <TableCell align="center">
                <IconButton
                 className={DATA_CLASSES.PROJECT_ITEM_BOOKMARK_LINK}
                 component={Link}
                 to={DYNAMIC_ROUTES.BOOKMARKS(id)}
                 sx={{ 'svg': { marginRight: 0 } }}
                >
                  <BookmarksIcon />
                </IconButton>
              </TableCell>
              <TableCell align="center">{alerts.no_assignee}</TableCell>
              <TableCell align="center">{alerts.no_due}</TableCell>
              <TableCell align="center">{alerts.expired}</TableCell>
              <TableCell align="center">
                <IconButton
                  className={DATA_CLASSES.PROJECT_ITEM_SETTINGS_LINK}
                  component={Link}
                  to={getProjectSettingsURL(id)}
                  onClick={onCurrentProjectSettingsClick}
                  sx={{ display: "block", p: 0, mx: "auto" }}
                >
                  <SettingsIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          );
        })
      ) : (
        <TableRow sx={{ "&:last-child td": { border: "none" } }}>
          <TableCell align="center" colSpan={headers.length}>
            参加しているプロジェクトがありません。
          </TableCell>
        </TableRow>
      )}
    </BasicTable>
  );
};

export default ProjectListTable;
