import React from "react";
import { CustomButton } from "components";
import { Alert, AlertTitle } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ACCOUNT_CREATION_PAGE } from "constants/constants";

const AlertBox = () => {
  const navigate = useNavigate();
  const goToAccountCreationPage = () => {
    navigate(ACCOUNT_CREATION_PAGE);
  };

  return (
    <Alert
      severity="error"
      sx={{ my: 1, "& .MuiAlert-message": { width: "calc(100% - 65px)" } }}
    >
      <AlertTitle>
        &SURVEYの結果診断結果を保存するには、ANDGATEアカウントが必要です。
      </AlertTitle>
      <CustomButton
        text="ANDGATEのアカウントを作成する"
        sx={{ display: "block", m: "auto" }}
        onClick={goToAccountCreationPage}
      />
    </Alert>
  );
};

export default AlertBox;
