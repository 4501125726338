import APIErrorHandler from "./APIErrorHandler";
import Loading from "./Loading";

const APIGetHandler = ({ settings }: { settings: { name: string; isLoading: boolean; errorMessage?: string | null, retry: () => any } }) => {
  console.debug('APIGetHandler', settings);
  const { name, isLoading, errorMessage, retry } = settings;
  if (!isLoading && errorMessage) {
    return <APIErrorHandler message={errorMessage} retry={retry} />;
  }
  if (isLoading) {
    return <Loading name={name} />;
  }
  return <></>;
};

export default APIGetHandler;
