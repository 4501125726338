import { useState, Dispatch, SetStateAction } from "react";
import { SubmitHandler } from "react-hook-form";
import { Box, TableRow, TableCell, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useCalendars } from "hooks";
import { CalendarSuccessParams, FormInputType, ValuesType } from "types";
import {
  BasicTable,
  Title,
  FormModal,
  CustomButton,
  Loading,
} from "components";
import { DATA_CLASSES, HEADERS, IDS, LABELS } from "constants/constants";
import { useAPICalendarMeetingPOST, useAPIMeetingsCalendarDELETE } from "api";
import ErrorModal from "components/ErrorModal";
import DeleteModal from "components/DeleteModal";
import { getDeleteTargetTitleText, REGISTER_BUTTON_TEXT } from "constants/messages";
import { CalendarMeetingsGetResponseDto, GoogleCredentialsAuthResponseDto } from "types/api";
import { getCommonCalendarExternalData } from '../common/calendars';

const headers = [
  HEADERS.CALENDAR,
  HEADERS.DELETE,
];

const labels = [
  LABELS.CALENDAR,
];

interface MeetingsCalendarProps {
  rows: CalendarMeetingsGetResponseDto[] | null;
  projectId: string;
  requestUpdate: () => void;
}

/*
 * ミーティングカレンダー設定画面
 */
const MeetingsCalendar = ({
  rows,
  projectId,
  requestUpdate,
}: MeetingsCalendarProps) => {
  const [auth, setAuth] = useState<GoogleCredentialsAuthResponseDto>(); // auth
  const [errorMessage, setErrorMessage] = useState<string | null>(null); // エラーメッセージ
  const [deleteData, setDeleteData] = useState({ id: "", name: "" }); // 削除カレンダー
  const [calendarNames, setCalendarNames] = useState<{ id: string, name: string }[]>([]); // カレンダー選択肢
  const { mutate: mutatePost, isLoading: isLoadingPost } =  useAPICalendarMeetingPOST(); // カレンダー登録
  const { mutate: mutateDelete, isLoading: isLoadingDelete } = useAPIMeetingsCalendarDELETE(); //カレンダー削除

  //モーダル表示
  const [modalOpen, setModalOpen] = useState(false);
  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);
  // 一覧の削除ボタン押下時、モーダル表示
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const handleOpenDeleteModal = (values: ValuesType) => {
    setDeleteData(values);
    setOpenDeleteModal(true);
  };
  const handleCloseDeleteModal = () => setOpenDeleteModal(false);
  // エラーモーダル表示非表示処理
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const handleErrorModalOpen = () => setErrorModalOpen(true); // TODO: !!errorMessageで確認したら不要。
  const handleErrorModalClose = () => {
    setErrorModalOpen(false);
    setErrorMessage("");
  };

  const onCalendarsSuccess = ({ calendars, auth }: CalendarSuccessParams) => {
    console.debug('onCalendarsSuccess', { calendars, auth });
    setCalendarNames(calendars || []);
    setAuth(auth);
    // requestUpdate();
    handleModalOpen(); // モーダル表示
  };
  const onCalendarsFailure = (errorMessage: string) => {
    setErrorMessage(errorMessage);
    handleErrorModalOpen();
  };
  
  const { handleClickAddCalendar, isLoadingGoogle } = useCalendars('google', onCalendarsSuccess, onCalendarsFailure);

  /**
   * カレンダー登録処理
   */
  const handleSubmit: SubmitHandler<FormInputType> = async (data) => {
    const calendarId = data.calendar;
    const { commonExternalData } = getCommonCalendarExternalData(calendarId, auth);

    const response = await mutatePost({
      projectId,
      params: {
        type: "google",
        data: commonExternalData,
      },
    });
    //post終了後モーダル閉じる。失敗時エラー表示
    if (response.success) {
      requestUpdate();
      handleModalClose();
    } else {
      setErrorMessage("カレンダー新規作成に失敗しました。");
      handleModalClose();
      handleErrorModalOpen();
    }
  };

  /**
   * カレンダー削除処理
   */
  const handleDelete = async () => {
    const response = await mutateDelete({ projectId, id: deleteData.id });
    //削除後モーダル閉じる。失敗時エラーモーダル表示
    if (response.success) {
      requestUpdate();
      handleCloseDeleteModal();
    } else {
      setErrorMessage("カレンダーの削除に失敗しました。");
      handleCloseDeleteModal();
      handleErrorModalOpen();
    }
  };

  if (!rows || isLoadingPost || isLoadingDelete || isLoadingGoogle) {
    return <Loading name="MeetingsCalendar" />;
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Title title="登録済みカレンダー一覧" icon="list" size="subtitle1" />
        <CustomButton
          id={IDS.CREATE_MEETING_BUTTON}
          text="カレンダーを登録する"
          onClick={handleClickAddCalendar}
          icon="addCircle"
        />
      </Box>

      {modalOpen && (
        <FormModal
          title="カレンダー登録"
          buttonText={REGISTER_BUTTON_TEXT}
          open={modalOpen}
          onSubmit={handleSubmit}
          onClose={handleModalClose}
          labels={labels}
          selectValues={calendarNames}
        />
      )}

      <BasicTable headers={headers}>
        {rows.length !== 0 ? (
          rows.map((row, i) => (
            <TableRow
              key={`row-${i}`}
              sx={{ "&:last-child td": { border: 0 } }}
            >
              <TableCell align="center">{row.data.data?.name || ''}</TableCell>
              <TableCell align="center">
                <IconButton
                  className={DATA_CLASSES.DELETE_MEETING_ITEM_BUTTON}
                  onClick={() =>
                    handleOpenDeleteModal({
                      id: row.id,
                      name: row.data.data?.name || '',
                    })
                  }
                >
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))
        ) : (
          <TableRow sx={{ "&:last-child td": { border: "none" } }}>
            <TableCell align="center" colSpan={headers.length}>
              カレンダーを登録してください。
            </TableCell>
          </TableRow>
        )}
      </BasicTable>
      <DeleteModal text={`${getDeleteTargetTitleText(deleteData.name, 'カレンダー名')}`} open={openDeleteModal} onClick={handleDelete} onClose={handleCloseDeleteModal} />
      <ErrorModal text={errorMessage} open={errorModalOpen} onClose={handleErrorModalClose} />
    </>
  );
};

export default MeetingsCalendar;
