import { useState } from "react";
import useAuthAxios from "hooks/useAuthAxios";
import { SuccessFailureResponse, Params, UseAuthAxiosProps } from "types";
import { handleAxiosSuccessFailureResponse } from "utilities";
import { useAPIErrorMessage } from "components/GlobalSnackbar/globalSnackbarContext";

export interface usePutProps extends UseAuthAxiosProps {
  //
};

export type usePutExecuteProps<T extends Params> = {
  url: string;
  params: T;
};

/**
 * T=リクエスト
 * U=レスポンス
 */
const usePost = <T extends Params, U>(options: usePutProps = {}) => {
  const [isLoading, setIsLoading] = useState(false);
  // const [error, setError] = useState(null);
  const { authAxios, canExecute } = useAuthAxios(options);
  const [, setErrorMessage] = useAPIErrorMessage();

  const mutate = async ({ url, params }: usePutExecuteProps<T>): Promise<SuccessFailureResponse<U>> => {
    if (!canExecute) {
      throw new Error(`canExecute PUT FAILED: ${url}`);
    }

    setIsLoading(true);
    const axiosResponse = authAxios.put(url, params);
    return await handleAxiosSuccessFailureResponse(axiosResponse)
      .then((response) => {
        if (response.success) {
          setErrorMessage('');
        } else {
          setErrorMessage(String(response.data));
        }
        return response;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return { mutate, isLoading };
};

export default usePost;
