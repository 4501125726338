import { useParams } from "react-router-dom";
import useAppState from "./useAppState";
import useSurvey from "./useSurvey";

/**
 * 現在設定されたプロジェクト診断
 * プロジェクト診断を利用するにはsurveyIdも必要ですので、プロジェクト診断関連も返す。
 */
const useDiagnosis = () => {
    const { diagnosisId } = useParams();
    const [ state ] = useAppState();
    const { diagnosis } = state;

    const { surveyId, isLoading, errorMessage } = useSurvey();
    console.debug('useDiagnosis', { diagnosisId, diagnosis, surveyId });

    return {
        diagnosis, diagnosisId, surveyId, isLoading, errorMessage
    };
};

export default useDiagnosis;
