import { Box, Card, CardContent, CardHeader, Grid, List, ListItem, Typography } from "@mui/material";
import Attachments from "components/Attachments";
import MarkdownComponent from "components/MarkdownComponent";
import DeskUploads from "./DeskUploads";

const DeskItemQuestion = ({ subject, body, createdBy, createdAt, uploads }: { subject: string; body: string; createdBy: string; createdAt: string; uploads?: { content_url?: string }[] }) => {
    /*
    const { subject, createdBy, createdAt, body } = {
        subject: 'Fargateのコンテナの中に入りたい',
        createdBy: 'Shinichi Takahashi',
        createdAt: '2022/10/12 23:25',
        body: 'ABC',
    };
    */
    const CardHeaderContent = () => (
        <>
            <Grid container sx={{ padding: '10px', borderBottom: '1px solid lightgray' }}>
                <Grid item xs={8}>
                    <Typography variant='h4' sx={{ fontWeight: 'bold' }}>{subject}</Typography>
                </Grid>
                <Grid item xs={4} sx={{ textAlign: 'right' }}>
                    <Box>
                        <Typography>{createdBy}</Typography>
                        <Typography>{createdAt}</Typography>
                    </Box>
                </Grid>
            </Grid>
        </>
    );

    return (
        <Card sx={{ padding: '10px', margin: '10px' }}>
            <CardHeader component={Box} title={<CardHeaderContent />}>
            </CardHeader>
            <CardContent>
                <Box>
                    <MarkdownComponent value={body} pre />
                    <DeskUploads uploads={uploads} />
                </Box>
            </CardContent>
        </Card>
    )
};

export default DeskItemQuestion;
