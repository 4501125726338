import { useEffect, useState } from "react";
import { AxiosResponse } from "axios";
import useAuthAxios from "hooks/useAuthAxios";
import { FieldType, StateInc, SuccessFailureResponse, UseAuthAxiosProps } from "types";
import { handleAxiosSuccessFailureResponse } from "utilities";
import { useAPIErrorMessage } from "components/GlobalSnackbar/globalSnackbarContext";

type BaseParams = Record<string, FieldType>;
type URLValue = string | null; // null = まだ準備されていない
interface UseGetOptions<U extends BaseParams> extends UseAuthAxiosProps {
  /**
   *  動的文字列関数 | 固定文字列 | まだ準備されていない
   */
  url?: ((params: U) => string) | URLValue;
};
type FalsableRecord<T> = Partial<T>;

/**
 * T: レスポンス
 * U: URL作成用のパラメーター
 */
const useGet = <T, U extends BaseParams>(options: UseGetOptions<U> = {}) => {
  const { url: urlGetter, authRequired, canSend, requiredRoles } = options;
  console.debug('useGet', { urlGetter });
  
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [, setGlobalErrorMessage] = useAPIErrorMessage();
  const [data, setData] = useState<T|null>(null);
  const { authAxios, canExecute } = useAuthAxios({ authRequired, canSend, requiredRoles });
  useEffect(() => {
    setGlobalErrorMessage(errorMessage || '');
  }, [errorMessage]);

  // 更新すべきか。// if (shouldExecute) execute();
  const shouldExecute = !data && !isLoading && canExecute;

  /**
   * 毎回実行してもよい。
   * 更新をしたい場合、force=trueを使うこと。
   */
  const execute = async (params: FalsableRecord<U> = {}, force?: boolean): Promise<SuccessFailureResponse<T> | undefined> => {
    console.debug('useGet:execute');
    const fetchData = async (params: FalsableRecord<U>) => {
      // 使用可能か
      if (!canExecute) {
        console.debug('useGet:execute まだ利用不可。', { canExecute, authRequired, requiredRoles });
        return;
      }

      // 実行すべきか
      if (!shouldExecute && !force) {
        console.debug('useGet:execute 使用すべきでないと判断したため、実行を無視する。それでも実行したい場合、force=trueにすること。', { data, isLoading });
        return;
      }

      // URL PARAMS
      if (params && Object.values(params).some((v) => !v)) {
        console.debug('useGet:execute のparamsはまだ利用不可。', params);
        return;
      }

      // URL
      const url = typeof urlGetter === 'function' ? urlGetter(params as U) : urlGetter;
      if (!url) {
        console.debug('useGet:execute のurlはまだ利用不可。');
        return;
      }

      console.debug('useGet execute start', { url });

      // 新規対応
      setIsLoading(true);
      const axiosResponse = authAxios.get(url);
      const successFailureResponse = await handleAxiosSuccessFailureResponse(axiosResponse)
      .finally(() => {
        setIsLoading(false);
      });
      if (successFailureResponse.success) {
        setData(successFailureResponse.data);
        setErrorMessage(null);
      } else {
        setErrorMessage(String(successFailureResponse.data));
      }

      return successFailureResponse;

      /*
      // 古い対応
      authAxios.get(url)
      .then((response: AxiosResponse) => {
        setData(response.data);
        setErrorMessage(null);
      })
      .catch((error) => {
        console.error(error);
        // 表示が必要になれば、対応。
        setErrorMessage('エラー');
      })
      .finally(() => {
        setIsLoading(false);
      });
      */
    };

    if (!canExecute) {
      console.debug('useGet:canExecute FAILED: IGNORE', { options });
      return;
    }

    return fetchData(params);
  };

  useEffect(() => {
    console.debug('useGet:useEffect');
    // execute();
  }, []);

  // return data;
  return { execute, isLoading, data, shouldExecute, canExecute, errorMessage };
};

export default useGet;
