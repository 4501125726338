import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import useAppState from "./useAppState";
import { getItemFromIdRowList } from 'utilities';
import { useAPISurveysGET } from 'api';
import { validate as uuidValidate } from 'uuid';

/**
 * 現在設定されたプロジェクト診断
 * params: api-routes.ts内で統一されたキーを利用すること。現在は: id
 */
const useSurvey = () => {
    const [state] = useAppState();
    const { surveyId: surveyIdPart } = useParams(); // キャッシュする必要がないので、URLから取得。
    
    // const { surveys } = state;
    const { data: surveys, execute, isLoading, errorMessage } = useAPISurveysGET();

    useEffect(() => {
        execute({});
    }, []);

    const isSurveyIdUUID = !!(surveyIdPart && uuidValidate(surveyIdPart));
    const alias = (surveyIdPart && !isSurveyIdUUID) ? surveyIdPart : null;
    const surveyId = !alias ? surveyIdPart : surveys?.filter((s) => s.alias === alias)[0]?.id;
    const survey = surveys ? getItemFromIdRowList(surveyId, surveys) : null;
    return {
        surveys, surveyId, survey, alias, isLoading, errorMessage
    };
};

export default useSurvey;
