import { Snackbar, IconButton, Alert, AlertColor } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { useEffect, useState } from "react";

/**
 * messageがあれば、表示。
 * close後、messageが変わるまで表示しない。
 */
const CustomSnackbar = ({ severity, message }: { severity?: AlertColor, message?: string | null }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(!!message);
  }, [message]);

  if (!severity || !message) {
    return (
      <></>
    );
  }

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
        ContentProps={{
          "aria-describedby": "message-id"
        }}
        action={[
          <IconButton key="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        ]}
      >
        <Alert severity="error">{message}</Alert>
      </Snackbar>
    </>
  )
};

export default CustomSnackbar;
