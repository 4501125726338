import React from "react";
import { SxProps, Theme } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";

// Icons
import ListIcon from "@mui/icons-material/List";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import DeleteIcon from "@mui/icons-material/Delete";
import MoodBadIcon from "@mui/icons-material/MoodBad";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import SentimentNeutralIcon from "@mui/icons-material/SentimentNeutral";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import CreateIcon from "@mui/icons-material/Create";
import SdCardAlertIcon from "@mui/icons-material/SdCardAlert";
import SearchIcon from "@mui/icons-material/Search";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

export type IconName = 'list' | 'bag' | 'delete' | 'expired' | 'due_soon' | 'no_due' | 'no_assignee' | 'content_paste' | 'assignment_turned_in' | 'workspaces' | 'playlistAddCheckIcon' | 'sentimentSatisfiedAltIcon' | 'pen' | 'alert' | 'search' | 'play';

interface TitleProps {
  title: string;
  subTitle?: string;
  subTitle2?: string;
  icon?: IconName;
  size?: "h1" | "h6" | "subtitle1";
  bold?: boolean;
  sx?: SxProps<Theme>;
}

const Title = ({
  title,
  subTitle,
  subTitle2,
  icon,
  size = "h6",
  bold = false,
  sx,
}: TitleProps) => {
  const Icon = ({ icon }: { icon?: IconName }) => {
    switch (icon) {
      case "list":
        return <ListIcon fontSize="large" />;
      case "bag":
        return <BusinessCenterIcon fontSize="large" />;
      case "delete":
        return <DeleteIcon />;
      case "expired":
        return <MoodBadIcon />;
      case "due_soon":
        return <SentimentVeryDissatisfiedIcon />;
      case "no_due":
        return <SentimentNeutralIcon />;
      case "no_assignee":
        return <PersonOffIcon />;
      case "content_paste":
        return <ContentPasteIcon />;
      case "assignment_turned_in":
        return <AssignmentTurnedInIcon />;
      case "workspaces":
        return <WorkspacesIcon />;
      case "playlistAddCheckIcon":
        return <PlaylistAddCheckIcon />;
      case "sentimentSatisfiedAltIcon":
        return <SentimentSatisfiedAltIcon />;
      case "pen":
        return <CreateIcon />;
      case "alert":
        return <SdCardAlertIcon />;
      case "search":
        return <SearchIcon />;
      case "play":
        return <PlayArrowIcon />;
      default:
        return <></>;
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        my: 1,
        mr: 1,
      }}
    >
      <Icon icon={icon} />
      <Typography
        sx={{ fontWeight: bold ? "bold" : "", ml: "5px" }}
        variant={size}
        textAlign="left"
      >
        {title}
      </Typography>
      {subTitle && (
        <Typography
          sx={{
            ml: "5px",
          }}
          textAlign="left"
          variant="subtitle1"
        >
          ＞ {subTitle}
        </Typography>
      )}
      {subTitle2 && (
        <Typography
          sx={{
            ml: "5px",
          }}
          textAlign="left"
          variant="subtitle1"
        >
          ＞ {subTitle2}
        </Typography>
      )}
    </Box>
  );
};

export default Title;
