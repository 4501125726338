import { List, ListItem, ListItemText, Card } from "@mui/material";
import { MAX_DESK_COMMENT_COUNT } from "constants/constants";
import { DESK_STATUSES } from "styles/constants";

const DeskItemMeta = ({ createdAt, updatedAt, status, commentCount }: { createdAt: string; updatedAt: string; status: string; commentCount: number; }) => {
    return (
        <Card sx={{ width: 'fit-content', margin: '10px' }}>
            <List sx={{ '.MuiListItemText-root': { margin: '0 20px' } }}>
                <ListItem>
                    <ListItemText>作成日時</ListItemText>
                    <ListItemText>{createdAt}</ListItemText>
                </ListItem>

                <ListItem>
                    <ListItemText>更新日時</ListItemText>
                    <ListItemText>{updatedAt}</ListItemText>
                </ListItem>

                <ListItem>
                    <ListItemText>ステータス</ListItemText>
                    <ListItemText>
                        <span className={DESK_STATUSES[status as keyof typeof DESK_STATUSES]} style={{ padding: '5px 10px' }} >{status}</span>
                    </ListItemText>
                </ListItem>

                <ListItem>
                    <ListItemText>コメント数</ListItemText>
                    <ListItemText>{commentCount}/{MAX_DESK_COMMENT_COUNT}</ListItemText>
                </ListItem>
            </List>
        </Card>
    )
};

export default DeskItemMeta;
