import {
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import Select, { SelectChangeEvent, SelectProps } from "@mui/material/Select";
import { FormType, SelectType, ValueType } from "types";

interface FormSelectProps {
  selectValues: Array<SelectType>;
  selects: boolean;
  label: FormType;
  register: (s: string, o: Record<string, any>) => SelectProps;
  errors: any;
  valueDatas: ValueType;
  handleChange: (e: SelectChangeEvent) => void;
}

const FormSelect = ({
  selectValues,
  selects = false,
  label,
  register,
  errors,
  valueDatas,
  handleChange,
}: FormSelectProps) => {
  return (
    <FormControl
      fullWidth
      required={label.required}
      sx={{
        mb: 1,
      }}
      error={errors[label.value] ? true : false}
    >
      <InputLabel id={label.value}>{label.name}</InputLabel>
      <Select
        id={label.value}
        value={valueDatas[label.value] || ""}
        label={label.name}
        {...register(label.value, {
          required: label.errorMessage,
        })}
        onChange={handleChange as any}
      >
        {selects && selectValues // TODO: 一緒！ ternaryを消す？元のselectsは何のためでした？
          ? selectValues.map((select, index: number) => (
              <MenuItem key={`select-${index}`} value={select.id}>
                {select.name}
              </MenuItem>
            ))
          : selectValues &&
            selectValues.map((select, index: number) => (
              <MenuItem key={`select-${index}`} value={select.id}>
                {select.name}
              </MenuItem>
            ))}
      </Select>
      <FormHelperText>{errors[label.value]?.message}</FormHelperText>
    </FormControl>
  );
};

export default FormSelect;
