import { Title, Text } from "components";
import APIGetHandler from "components/APIGetHandler";
import { ACADEMY_COURSES_TITLE } from "constants/messages";
import { useCourse } from "hooks";
import { formatDate } from "models";

const AcademyPlayPage = () => {
  const { course, isLoading, execute, errorMessage } = useCourse();

  if (isLoading || errorMessage || !course) {
    return <APIGetHandler settings={{ name: 'AcademyPlayPage', isLoading, errorMessage, retry: () => execute() }} />
  }

  const date = formatDate(new Date(course.createdAt));

  return (
    <>
      <Title title={ACADEMY_COURSES_TITLE} subTitle={course.name} icon="play" />
      <iframe
        title="vimeo-player"
        src={course.url}
        width="700"
        height="400"
        frameBorder="0"
        allowFullScreen
      ></iframe>
      <Text text={course.name} size="subtitle1" />
      <Text text={date} />
    </>
  );
};

export default AcademyPlayPage;
