import { Box } from "@mui/material";
import { useAPICoursesGET } from "api";
import { Title, Text, CustomLinkButton } from "components";
import { DYNAMIC_ROUTES } from "constants/routes";
import { formatDate } from "models";
import { useEffect } from "react";
import { DATA_CLASSES } from "constants/constants";
import { ACADEMY_COURSES_TITLE } from "constants/messages";
import APIGetHandler from "components/APIGetHandler";

/*
 * 講座一覧画面
 */
const AcademyPage = () => {
  // 講座一覧取得処理
  const { data: courses, execute, isLoading, errorMessage } = useAPICoursesGET();
  console.debug('AcademyPage', { courses });
  useEffect(() => {
    execute({});
  }, []);

  if (isLoading || errorMessage || !courses) {
    return <APIGetHandler settings={{ name: 'AcademyPage', isLoading, errorMessage, retry: () => execute() }} />
  }

  return (
    <>
      <Title title={ACADEMY_COURSES_TITLE} icon="play" />
      <Title title="最新動画" icon="list" size="subtitle1" />
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          "div:nth-of-type(3n)": {
            mr: 0,
          },
        }}
      >
        {courses.map((course, index) => {
          const date = formatDate(new Date(course.createdAt));
          return (
            <Box
              key={`course-${index}`}
              sx={{
                mr: 0.8,
              }}
            >
              <iframe
                title="vimeo-player"
                src={course.url}
                width="320"
                height="180"
                frameBorder="0"
                allowFullScreen
              ></iframe>
              <CustomLinkButton
                className={DATA_CLASSES.COURSE_ITEM_LINK}
                text={course.name}
                to={DYNAMIC_ROUTES.ACADEMY_COURSE(course.id)}
                variant="text"
                textAlign="left"
                sx={{ display: "block" }}
              />
              <Text text={date} />
            </Box>
          );
        })}
      </Box>
    </>
  );
};

export default AcademyPage;
