import { ALERT_TYPES } from "./messages";

type Writeable<T> = { -readonly [P in keyof T]: T[P] };

/**
 * valueはIDと同じ。必ずユニークなvalueを使用すること。
 * この設定で各場所の順番を決めること。
 */
export const ALERTS_SETTINGS = [
  { name: ALERT_TYPES.NO_ASSIGNEE, value: "no_assignee" },
  { name: ALERT_TYPES.NO_DUE, value: "no_due" },
  { name: ALERT_TYPES.EXPIRED, value: "expired" },  
  { name: ALERT_TYPES.DUE_SOON, value: "due_soon" },
] as const;

export const ROLES_KEY = "https://app.andgate.jp/roles";
export const ROLES = ['guest', 'free', 'dandle', 'desk'] as const;
export const DEFAULT_ROLES = ['guest'] as unknown as Writeable<typeof ROLES>;
export const DEFAULT_USER_ROLES = ['free'] as unknown as Writeable<typeof ROLES>;
export const LICENSED_ROLES = ['dandle', 'desk'] as unknown as Writeable<typeof ROLES>;

export const GOOGLE_AUTH_SCOPE = "email profile https://www.googleapis.com/auth/calendar openid https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email";
export const WALL_PAGE_MESSAGE_ORIGIN = 'https://andgate-platform.youcanbook.me';
export const WALL_PAGE_IFRAME_SRC = "https://andgate-platform.youcanbook.me/?noframe=true&skipHeaderFooter=true";
export const ACCOUNT_CREATION_PAGE = 'https://www.andgate.jp/#document'; // 'https://www.andgate.jp/#form';
export const HUBSPOT_OPEN_BETA_FORM = 'https://share.hsforms.com/1_zNNT6voTcKGVqbMnzUAPw5jdki';

/**
 * 一つのみの要素。
 * テストなどで利用可能。
 */
export const IDS = {
  HOME_BUTTON: 'home-button',
  CURRENT_USER_BUTTON: 'current-user-button',
  LOGIN_BUTTON: 'login-button',
  LOGOUT_BUTTON: 'logout-button',
  CREATE_PROJECT_BUTTON: 'create-project-button',
  CREATE_TOOL_BUTTON: 'create-tool-button',
  CREATE_ROUTINE_TASK_BUTTON: 'create-routine-task-button',
  CREATE_MEETING_BUTTON: 'create-meeting-button',
  CREATE_NOTIFICATION_BUTTON: 'create-notification-button',
  ASSIGN_MEMBER_BUTTON: 'assign-member-button',
  DELETE_PROJECT_BUTTON: 'delete-project-button',
  CHECK_ALERTS_BUTTON: 'check-alerts-button',
  TASK_SEARCH_BUTTON: 'task-search-button',
  GENERATE_AGENDA_BUTTON: 'generate-agenda-button',
  CREATE_COMPLETE_STATUSES_BUTTON: 'complete-statuses-button',
  SEND_TICKET_BUTTON: 'send-ticket-button',
  MODAL_TITLE: 'modal-modal-title',
  HEADER_POPUP_POPOVER: 'menu-appbar2',
  LOGIN_STATUS_MENU: 'menu-appbar',
  HEADER_PROJECT_MENU: 'basic-menu',
  CREATE_BOOKMARK_BUTTON: 'create-bookmark-button',
  HUBSPOT_FORM_TARGET: 'open-beta-form',
} as const;

/**
 * 複数項目のIDとして利用。
 */
export const DATA_CLASSES = {
  COURSE_ITEM_LINK: 'course-item-link',
  SURVEY_ITEM_LINK: 'survey-item-link',
  DIAGNOSIS_ITEM_LINK: 'diagnosis-item-link',
  PROJECT_ITEM_TASKS_LINK: 'project-item-tasks-link',
  PROJECT_ITEM_SETTINGS_LINK: 'project-item-settings-link',
  DELETE_TOOL_ITEM_BUTTON: 'delete-tool-item-button',
  DELETE_ROUTINE_TASK_ITEM_BUTTON: 'delete-routine-task-item-button',
  DELETE_MEETING_ITEM_BUTTON: 'delete-meeting-item-button',
  DELETE_NOTIFICATION_ITEM_BUTTON: 'delete-notification-button',
  DELETE_COMPLETE_STATUSES_BUTTON: 'delete-complete-statuses-button',
  UNASSIGN_MEMBER_ITEM_BUTTON: 'unassign-member-item-button',
  LOADING_SPINNER: 'loading-spinner',
  DELETE_BOOKMARK_ITEM_BUTTON: 'delete-bookmark-item-button',
  PROJECT_ITEM_BOOKMARK_LINK: 'project-item-bookmark-link',
} as const;

export const TASK_LIST_SEARCH_FIELDS = {
  START_DATE_1: 'startDate1',
  START_DATE_2: 'startDate2',
  DUE_DATE_1: 'dueDate1',
  DUE_DATE_2: 'dueDate2',
  UPDATED_1: 'update1',
  UPDATED_2: 'updated2',
  KEYWORD: 'keyword',
  // STATUS: 'status',
} as const;

export const GOOGLE_TAG_MANAGER_EVENT_ITEMS = {
  SURVEY_RESPONSE_GUEST: 'surveyResponseGuest',
} as const;
export const GOOGLE_TAG_MANAGER_EVENTS = {
  [GOOGLE_TAG_MANAGER_EVENT_ITEMS.SURVEY_RESPONSE_GUEST]: 'Survey Response Guest',
} as const;
export const MAX_DESK_COMMENT_COUNT = 9;

export const HEADERS = {
  TITLE: { name: "件名", value: "title" },
  ID: { name: "ID", value: "id" },
  CREATED_DATE_TIME: { name: "作成日時", value: "createdDate" },
  UPDATED_DATE_TIME: { name: "更新日時", value: "updatedDate" },
  STATUS: { name: "ステータス", value: "status" },
  PROJECT_NAME: { name: "プロジェクト名", value: "name" },
  PROJECT_ID: { name: "プロジェクトID", value: "id" },
  CREATED_DATE: { name: "作成日", value: "date" },
  SETTING: { name: "設定", value: "setting" },
  TOOL: { name: "プロジェクト管理ツール", value: "tool" },
  // COMPLETE_STATUSES: { name: "完了ステータス", value: "complete-statuses" },
  COMPLETE_STATUSES: { name: "完了設定", value: "complete-statuses" },
  DELETE: { name: "削除", value: "delete" },
  CALENDAR: { name: "カレンダー", value: "calendar" },
  NAME: { name: "名前", value: "name" },
  URL: { name: 'URL', value: 'url' },
  EMAIL: { name: "メールアドレス", value: "email" },
  ROLES: { name: "ロール", value: "roles" },
  ISSUE_TYPE: { name: "起票種別", value: "issueType" },
  EDIT: { name: "編集", value: "edit" },
  TOOL_NAME: { name: "ツール名", value: "name" },
  EXTERNAL_PROJECT_NAME: { name: "プロジェクト名", value: "external-name" }, // BACKLOGなど用。
  SURVEY: { name: "診断", value: "survey" },
  LOOK: { name: "", value: "look" }, // 項目を確認する。
  ITEM: { name: "項目", value: "item" },
  QUESTION: { name: "質問", value: "item" },
  DIAGNOSIS: { name: "今回の結果", value: "diagnoses" },
  STATE: { name: "状態", value: "state" },
  TASK_COUNT: { name: "タスク数", value: "tasks" },
  ADD: { name: '追加', value: 'add' },
  OPEN: { name: '開く', value: 'open' },
  ORDER: { name: '順番', value: 'order' },
  BOOKMARKS: { name: 'ブックマーク', value: 'bookmarks' },
  NO_ASSIGNEE_TASK: { name: "担当者なし", value: "no_assignee_task" },
  EXPIRED_TASK: { name: "期限切れ", value: "expired_task" },
  NO_DUE_TASK: { name: "期限なし", value: "no_due_task" },
} as const;

const LABEL_PARTS = {
  /**
   * inputPropsStartとInputPropsEndは入力後のデータに入らない。
   * httpsのみ。
   */
  SAFE_URL: {
    type: "textField",
    InputPropsStart: "https://",
    InputPropsEnd: "/",
  },
  /**
   * 保存可能のURL。ローカルでもよろしい。
   */
  URL_PATH: {
    type: "textField",
  }
} as const;

export const LABELS = {
  TOOL: {
    name: "プロジェクト管理ツール",
    value: "tool",
    type: "select",
    required: true,
    errorMessage: "プロジェクト管理ツールを選択してください。",
  },
  COMPLETE_STATUSES: {
    name: "状態",
    value: "complete-statuses",
    type: "check",
    required: true,
    errorMessage: "状態を選択してください。",
  },
  CALENDAR: {
    name: "カレンダー",
    value: "calendar",
    type: "select",
    required: true,
    errorMessage: "カレンダーを選択してください。",
  },
  EMAIL: {
    name: "メールアドレス",
    value: "email",
    type: "textField",
    required: true,
    errorMessage: "メールアドレスを入力してください。",
  },
  NAME: {
    name: "名前",
    value: "name",
    type: "textField",
    required: true,
    errorMessage: "名前を入力してください。",
  },
  WEBHOOK: {
    name: "Webhook URL",
    value: "webhookUrl",
    type: "textField",
    required: true,
    errorMessage: "webhookUrlを入力してください。",
    // InputPropsStart: "https://",
    // InputPropsEnd: "/",
  },
  ISSUE_TYPE: {
    name: "起票種別",
    value: "issueType",
    type: "select",
    required: true,
    errorMessage: "起票種別を選択してください。",
  },
  TENANT_URL: {
    ...LABEL_PARTS.SAFE_URL,
    name: "テナントURL",
    value: "tenantUrl",
    required: true,
    errorMessage: "テナントURLを入力してください。",
  },
  API_KEY: {
    name: "API KEY",
    value: "apiKey",
    type: "textField",
    required: true,
    errorMessage: "API KEYを入力してください。",
  },
  PROJECT: {
    name: "プロジェクト",
    value: "projects",
    type: "select",
    required: true,
    errorMessage: "プロジェクトを選択してください。",
  },
  TOOL_NAME: {
    name: "ツール一覧に表示する名称",
    value: "toolName",
    type: "textField",
    required: true,
    errorMessage: "ツール一覧に表示する名称を入力してください。",
  },
  PROJECT_NAME: {
    name: "プロジェクト名",
    value: "projectName",
    type: "textField",
    required: true,
    errorMessage: "プロジェクト名を入力してください。",
  },
  TICKET_TEMPLATE: {
    name: "テンプレート",
    value: "template",
    type: "select",
    required: true,
    errorMessage: "チケットテンプレートを選択してください。",
  },
  TITLE: {
    name: '件名',
    value: 'subject',
    required: true,
    type: 'textField',
    errorMessage: '件名を入力してください。',
  },
  ATTACHMENTS: {
    name: '添付ファイル',
    value: 'attachments',
    required: false,
    type: 'attachments',
    errorMessage: '添付ファイルに問題があります。',
  },
  URL: {
    ...LABEL_PARTS.URL_PATH,
    name: "URL",
    value: "url",
    required: true,
    errorMessage: "URLを入力してください。",
  },
} as const;

export const DRAWER_INITIALLY_OPENED = true;
export const DRAWER_TOGGLEABLE = false;
export const DRAWER_HIDE_SUBITEMS_ICONS_ON_SHRINK = false;

export const LOCAL_STORAGE_KEY = 'ANDGATE';
