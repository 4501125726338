import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Loading, Progressbar, CustomButton } from "components";
import {
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  FormHelperText,
  Card,
  TextField,
} from "@mui/material";

// Emoticon choice icons
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import SentimentNeutralIcon from "@mui/icons-material/SentimentNeutral";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";

import { useAppState } from "hooks";
import { DYNAMIC_ROUTES } from "constants/routes";
import { useAPISurveyAnswersPOST } from "api";
import { QUESTION_BOX_LABEL_COLOR, QUESTION_BOX_ERROR_COLOR } from "styles/constants";
import { AnswerDto, QuestionDto } from "types/api";
import { getRandomIndexedId } from "utilities";

interface QuestionBoxProps {
  questions: QuestionDto[];
  surveyId: string;
}

type Answer = ReturnType<typeof initializeAnswer>;

function initializeAnswer(type: 'choice' | 'text'): AnswerDto {
  if (type === 'choice') return { questionId: "", choiceId: "" };
  if (type === 'text') return { questionId: "", text: "" };
  throw new Error(`Invalid answer type: ${type}`);
}

// 選択項目アイコン
const Icons = (index: number) => {

  switch (index) {
    case 0:
      return <SentimentSatisfiedAltIcon />;
    case 1:
      return <SentimentNeutralIcon />;
    case 2:
      return <SentimentDissatisfiedIcon />;
    case 3:
      return <SentimentVeryDissatisfiedIcon />;
    case 4:
      return <InsertEmoticonIcon />;
    default:
      return <></>;
  }
  // }
};

interface AnswerData {
  question: QuestionDto;
  value: string;
  answeredIndex: number;
}
interface HandleAnswerData extends AnswerData {
  answers: Answer[];
}

const handleAnswer = ({ question, value, answeredIndex, answers }: HandleAnswerData) => {
  return answers.map((e, i) => {
    if (i !== answeredIndex) {
      return e; // 変更なし
    }

    const { id: questionId, type } = question;

    if (type === 'choice') {
      return {
        questionId,
        choiceId: value,
      };
    } else if (type === 'text') {
      return {
        questionId,
        text: value,
      };
    }
    throw new Error('Invalid answer');
  });
};

// チェック
const isAnswered = (e: AnswerDto) => {
  const NOT_SET = ['', undefined, null];
  return !NOT_SET.includes(e.choiceId) || !NOT_SET.includes(e.text);
};
const isNotAnswered = (e: AnswerDto) => !isAnswered(e);

const getProgress = (questions: QuestionDto[], answers: Answer[]) => {
  const perItemWeight = 100 / questions.length; //1問に対する％単位
  const answeredCount = answers.filter((item) => isAnswered(item)).length;
  return perItemWeight * answeredCount; // 回答ごとに%増加
};

const Question = ({ index, question, isError, onAnswer }: { index: number; question: QuestionDto, isError: boolean; onAnswer: (data: AnswerData) => void }) => {
  const id = getRandomIndexedId(index + 1, 'form-label-');
  /**
   * 入力がおそくなるため、すぐに実行しないなどの対応。
   */
  const onChange = (data: AnswerData) => {
    setTimeout(() => onAnswer(data), 10);
  };
  return (
    <FormControl error={isError} sx={{ width: "100%" }}>
      <Card
        sx={{
          alignItems: "center",
          marginBottom: "10px",
          px: 1,
          py: 2,
        }}
      >
        <FormLabel
          id={id}
          sx={{ textAlign: "left", ml: 1 }}
        >
          {index + 1}. {question.body}
        </FormLabel>

        {
          question.type === 'choice' &&
          // IPO準備の設問30の回答はすべて同じアイコンとする
          question.id === '90911a6f-73c1-4c7e-9d61-03f8fc749610' &&
          (
            <RadioGroup
              row
              aria-labelledby={id}
              name={question.id}
              onChange={(_evt, value) => {
                onChange({ question, answeredIndex: index, value });
              }}
              sx={{ justifyContent: "center", mt: 1 }}
            >
              {(question.choices || []).map((choice, index: number) => (
                <FormControlLabel
                  key={`radio-button-${index}`}
                  value={choice.id}
                  control={
                    <Radio checkedIcon={Icons(4)} icon={Icons(4)} />
                  }
                  label={choice.label}
                  labelPlacement="top"
                  sx={{
                    ".Mui-checked + .MuiTypography-root": {
                      color: QUESTION_BOX_LABEL_COLOR,
                    },
                  }}
                />
              ))}
            </RadioGroup>
          )
        }

        {
          question.type === 'choice' &&
          question.id !== '90911a6f-73c1-4c7e-9d61-03f8fc749610' &&
          (
            <RadioGroup
              row
              aria-labelledby={id}
              name={question.id}
              onChange={(_evt, value) => {
                onChange({ question, answeredIndex: index, value });
              }}
              sx={{ justifyContent: "center", mt: 1 }}
            >
              {(question.choices || []).map((choice, index: number) => (
                <FormControlLabel
                  key={`radio-button-${index}`}
                  value={choice.id}
                  control={
                    <Radio checkedIcon={Icons(index)} icon={Icons(index)} />
                  }
                  label={choice.label}
                  labelPlacement="top"
                  sx={{
                    ".Mui-checked + .MuiTypography-root": {
                      color: QUESTION_BOX_LABEL_COLOR,
                    },
                  }}
                />
              ))}
            </RadioGroup>
          )
        }

        {
          question.type === 'text' && (
            <TextField
              onChange={(_evt) => {
                const value = _evt.target.value;
                onChange({ question, answeredIndex: index, value });
              }}
              fullWidth
              sx={{ justifyContent: "center", mt: 1 }}
              required
              aria-labelledby={id}
              name={question.id}
            />
          )
        }

      </Card>
    </FormControl>
  );
};

const QuestionBox = ({
  questions,
  surveyId,
}: QuestionBoxProps) => {
  const navigate = useNavigate();
  const [state, setAppState] = useAppState();
  const [progress, setProgress] = useState(0); // プログレスバー
  const [answers, setAnswers] = useState(
    questions.map((item) => initializeAnswer(item.type))
  ); // 回答
  const [isError, setIsError] = useState(true); // エラー判定
  const [errors, setErrors] = useState<boolean[]>(Array(questions.length).fill(false)); //　各エラー
  const [helperTexts, setHelperTexts] = useState(
    Array(questions.length).fill(null)
  ); //　エラー文
  const { mutate: mutateCDQ, isLoading: isLoadingCDQ } = useAPISurveyAnswersPOST();

  // プログレスバー
  const updateProgress = () => setProgress(getProgress(questions, answers)); 

  useEffect(() => {
    // 回答時点でエラー判定
    const error = answers.some(isNotAnswered);
    setIsError(error);

    // プログレス
    updateProgress();
  }, [answers]);

 
  /*
  const onAnswer = useCallback((answerData: AnswerData) => {
    const newAnswer = handleAnswer({ ...answerData, answers });
    setAnswers(newAnswer);
  }, [setAnswers]);
  */
 
  const onAnswer = (answerData: AnswerData) => {
    const newAnswer = handleAnswer({ ...answerData, answers });
    setAnswers(newAnswer);
  };
  
  /*
   * プロジェクト診断回答の送信処理
   */
  const handleSubmit = async (event: ({ preventDefault: () => void } | undefined)) => {
    if (event) {
      event.preventDefault();
    }

    // 未回答に関してはエラー表示
    setErrors(answers.map((e, i) => (isNotAnswered(e) ? true : false)));
    setHelperTexts(
      answers.map((e, i) =>
        isAnswered(e) ? null : `問題${i + 1}が未回答です。`
      )
    );

    // 未回答があれば結果画面に遷移しない
    if (isError) {
      console.warn('isError');
      return;
    }

    const response = await mutateCDQ({ surveyId, params: { answers: answers } });
    //post成功時結果画面へ遷移、失敗時エラー表示
    if (response.success) {
      const diagnosis = response.data;
      const newState = { diagnosis }
      // setAppState({...state, ...newState });
      navigate(DYNAMIC_ROUTES.SURVEY_DIAGNOSIS(surveyId, diagnosis.id), { state: newState });
    } else {
      console.error("新規プロジェクト診断作成に失敗しました。入力値を確認してください。");
      return;
    }
  };

  if (isLoadingCDQ) {
    return <Loading name="QuestionBox" />;
  }

  return (
    <>
      <Progressbar value={progress} />
      <form onSubmit={handleSubmit}>
        {      
          questions.map((question, index: number) => {
            const error = errors[index];
            const isError = error !== undefined ? error : true;
            return (
              <Question key={index} index={index} question={question} isError={isError} onAnswer={onAnswer} />
            );
          })
        }
        <Box sx={{ mb: 1 }}>
          {helperTexts.map(
            (helperText, index) =>
              helperText && (
                <FormHelperText
                  key={`error-${index}`}
                  sx={{ textAlign: "center", color: QUESTION_BOX_ERROR_COLOR }}
                >
                  {helperText}
                </FormHelperText>
              )
          )}
        </Box>

        <CustomButton
          text="結果を見る"
          submit={true}
          sx={{ display: "block", m: "auto" }}
        />
      </form>
    </>
  );
};

export default QuestionBox;
