import { Box } from "@mui/material";
import { Title } from "components";
import { PROJECT_SETTINGS_TITLE } from "constants/messages";
import ProjectEdit from "./ProjectEdit";
import ProjectDelete from "./ProjectDelete";
import { ProjectsGetResponseDto } from "types/api";

const ProjectSettings = ({ project, requestUpdate }: { project: ProjectsGetResponseDto, requestUpdate?: () => void }) => {
    const projectId = project.id;
    const projectName = project.name;

    return (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Title title={PROJECT_SETTINGS_TITLE} icon="list" size="subtitle1" />
          </Box>
          <Box sx={{ margin: '10px 0' }}>
            <ProjectEdit project={project} onUpdate={requestUpdate} />
          </Box>
          <Box sx={{ margin: '10px 0', padding: '10px 0', borderTop: '1px solid lightgray' }}>
            <ProjectDelete projectId={projectId} projectName={projectName} />
          </Box>
        </>
      );
};
export default ProjectSettings;
