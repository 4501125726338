import { /* useContext, */ useState } from "react";
import { useLocation } from "react-router-dom";
import { AppState, /* AppStateContext */ } from "state";
import { NullableStateType, StateType } from "types";

// const globalState: StateType = {};
// let stateRef: StateType = {};

/**
 * useLocation: navigateなど、ページ遷移の際にステートを設定して利用するため。
 */
const useAppState = () => {
    const location = useLocation();
    // TODO: これか似ている動作ができるかをテストする:
    const updateLocationState = (newState: StateType) => {
        location.state = newState;
    };
    const getLocationState = () => {
        const locationState = location.state as NullableStateType;
        return locationState || ({} as StateType);
    }
    const state = getLocationState();
    // const state = {...getLocationState(), ...globalState};
    /*
    const [innerState, setInnerState] = useState<StateType>(AppState());
    const state = innerState;

    // TODO:
    // const cState = useContext(AppStateContext);
    */

    const setAppState = (newState: StateType) => {
        console.warn('TODO: 対応中。現在はuseLocationを利用している', newState);
        // Object.entries(newState).forEach(([k, v]) => (globalState as any)[k as keyof StateType] = v as any);
        // location.stateを変更できれば、contextなしでこれを使ってもよいが、本当に上書きができるかはまず確認すべき。できても、location.stateがnullの場合はどう対処するかの処理が必要。
        if (location.state) {
            Object.entries(newState).forEach(([k, v]) => (location.state as any)[k as keyof StateType] = v as any);
        }
        // return setInnerState(newState);
    };

    return [state, setAppState] as const;
}

export default useAppState;
