import React from "react";
import { Typography, TypographyProps } from "@mui/material";

const Copyright = (props: TypographyProps) => {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"©2022 ANDGATE"}
    </Typography>
  );
};

export default Copyright;
