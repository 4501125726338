import React from "react";
import { Chart as ChartJS } from "chart.js";
import { Radar } from "react-chartjs-2";
import { registerables } from "chart.js";
import { CHART_BACKGROUND_COLOR, CHART_BORDER_COLOR } from "styles/constants";
console.log({ type: typeof ChartJS, registerables }); // TODO: registerables is undefined for npm run test.
ChartJS.register(...(registerables || []));

interface ChartProps {
  label: string;
  labels: Array<string>;
  data: Array<number>;
}

const options = {
  maintainAspectRatio: true,
  scale: {
    ticks: {
      stepSize: 25,
    },
    r: {
      suggestedMin: 0,
      suggestedMax: 100,
    },
  },
  plugins: {
    legend: {
      display: false,
    },
  },
};

export const Chart = ({ label, labels, data }: ChartProps) => {
  const datas = {
    labels: labels,
    datasets: [
      {
        label: label,
        data: data,
        backgroundColor: CHART_BACKGROUND_COLOR,
        borderColor: CHART_BORDER_COLOR,
        borderWidth: 2,
      },
    ],
  };

  return <Radar data={datas} options={options} />;
};

export default Chart;
