import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { HeaderType } from "types";
import { BASIC_TABLE_BORDER_BOTTOM_COLOR } from "styles/constants";
import { Theme } from "@emotion/react";
import { SxProps } from "@mui/material";

interface TableProps {
  headers: Array<HeaderType>;
  children: React.ReactNode;
  boxShadow?: boolean;
  headerStyle?: SxProps<Theme>;
}

const BasicTable = ({ headers, children, boxShadow = true, headerStyle }: TableProps) => {
  return (
    <TableContainer
      component={Paper}
      sx={{ boxShadow: boxShadow ? "auto" : "unset" }}
    >
      <Table aria-label="simple table">
        <TableHead sx={{ borderBottom: `2px solid ${BASIC_TABLE_BORDER_BOTTOM_COLOR}`, ...(headerStyle || {}) }}>
          <TableRow>
            {headers.map((header: HeaderType) => (
              <TableCell
                key={header.value}
                align="center"
                sx={{ whiteSpace: "nowrap" }}
                title={header.title || ''}
              >
                {header.name}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>{children}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default BasicTable;
