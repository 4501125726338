import { useState } from "react";
import useAuthAxios from "hooks/useAuthAxios";
import { SuccessFailureResponse, Params, UseAuthAxiosProps } from "types";
import { handleAxiosSuccessFailureResponse } from "utilities";
import { useAPIErrorMessage } from "components/GlobalSnackbar/globalSnackbarContext";

export interface usePostProps extends UseAuthAxiosProps {
  //
};

export type usePostExecuteProps<T extends Params> = {
  url: string;
  params: T;
};

const AXIOS_POST_FILE_CONFIG = {
  headers: {
    'Content-Type': `multipart/form-data`,
  },
};

const fileToFormatData = (file: File) => {
  const formData = new FormData();
  formData.append('file', file, file.name);
  return formData;
};

/**
 * T=リクエスト
 * U=レスポンス
 */
const usePost = <T extends Params, U>(options: usePostProps = {}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { authAxios, canExecute } = useAuthAxios(options);
  const [, setErrorMessage] = useAPIErrorMessage();

  const mutate = async ({ url, params }: usePostExecuteProps<T>): Promise<SuccessFailureResponse<U>> => {
    if (!canExecute) {
      throw new Error(`canExecute POST FAILED: ${url}`);
    }

    setIsLoading(true);
    const axiosResponse = params instanceof File ? authAxios.post(url, fileToFormatData(params), AXIOS_POST_FILE_CONFIG) : authAxios.post(url, params);
    return await handleAxiosSuccessFailureResponse(axiosResponse)
      .then((response) => {
        if (response.success) {
          setErrorMessage('');
        } else {
          setErrorMessage(String(response.data));
        }
        return response;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return { mutate, isLoading };
};

export default usePost;
