import { useAPIBookmarkDelete, useAPIBookmarkPOST, useAPIBookmarkPUT, useAPIBookmarksGET } from "api";
import APIGetHandler from "components/APIGetHandler";
import { useProject } from "hooks";
import { useEffect, useState } from "react";
import { BookmarkPostRequestDto, BookmarkPutRequestDto } from "types/api";
import { unique, removeFromArray } from "utilities";
import BookmarksPageTable from "./block/BookmarksPageTable";

const BookmarksPage = () => {
  const { project, projectId } = useProject();
  const { data, execute: getBookmarks, isLoading: isLoadingGET, errorMessage } = useAPIBookmarksGET();
  const { mutate: putBookmark, isLoading: isLoadingPUT } = useAPIBookmarkPUT();
  const { mutate: postBookmark, isLoading: isLoadingPOST } = useAPIBookmarkPOST();
  const { mutate: deleteBookmark, isLoading: isLoadingDELETE } = useAPIBookmarkDelete();

  // const isLoading = isLoadingGET || isLoadingPUT || isLoadingPOST || isLoadingDELETE;
  const isLoading = isLoadingGET || isLoadingPOST || isLoadingDELETE;

  const execute = (force = false) => {
    getBookmarks({ projectId }, force);
  };
  const forceRefresh = () => execute(true);

  useEffect(() => {
    execute();
  }, [projectId]);

  const [loadingIds, setLoadingIds] = useState<string[]>([]);
  const [expanded, setExpanded] = useState<string[]>([]);

  if (isLoading || errorMessage || !projectId || !data || !project) {
    return <APIGetHandler settings={{ name: 'BookmarksPage', isLoading, errorMessage, retry: () => execute() }} />
  }

  const onCreate = async (params: BookmarkPostRequestDto) => {
    await postBookmark({ projectId, params });
    forceRefresh();
  };
  const onEdit = async (bookmarkId: string, params: BookmarkPutRequestDto) => {
    setLoadingIds(unique([...loadingIds, bookmarkId]));
    await putBookmark({ projectId, bookmarkId, params });
    setLoadingIds(removeFromArray([...loadingIds], bookmarkId));
    forceRefresh();
  }
  const onDelete = async (id: string) => {
    await deleteBookmark({ projectId, id });
    forceRefresh();
  }

  const rows = data;

  return (
    <>
      <BookmarksPageTable rows={rows} loadingIds={loadingIds} expanded={expanded} setExpanded={setExpanded} onCreate={onCreate} onEdit={onEdit} onDelete={onDelete} />
    </>
  );
};

export default BookmarksPage;
