import { useEffect, useState, Dispatch, SetStateAction } from "react";
import { SubmitHandler } from "react-hook-form";
import { Box, TableRow, TableCell, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useCalendars } from "hooks";
import {
  CalendarSuccessParams,
  FormInputType,
  RoutinesInputType,
  TypedFormInputType,
  ValuesType,
  IssueType,
} from "types";
import {
  BasicTable,
  Title,
  FormModal,
  CustomButton,
  Loading,
} from "components";
import { DATA_CLASSES, HEADERS, IDS, LABELS } from "constants/constants";
import { useAPICalendarRoutinePOST, useAPIRoutinesCalendarDELETE, useBacklogAPIIssueTypesGET } from "api";
import ErrorModal from "components/ErrorModal";
import DeleteModal from "components/DeleteModal";
import { getDeleteTargetTitleText, REGISTER_BUTTON_TEXT } from "constants/messages";
import { CalendarRoutinesGetResponseDto, GoogleCredentialsAuthResponseDto, ToolGetResponseDto } from "types/api";
import { getCommonCalendarExternalData } from '../common/calendars';

const headers = [
  HEADERS.CALENDAR,
  HEADERS.TOOL,
  HEADERS.ISSUE_TYPE,
  HEADERS.DELETE,
];

const nextLabels = [
  LABELS.CALENDAR,
  LABELS.TOOL,
];

const submitLabels = [
  LABELS.ISSUE_TYPE,
];

interface RoutinesCalendarProps {
  rows: CalendarRoutinesGetResponseDto[] | null;
  projectId: string;
  tools: ToolGetResponseDto[];
  requestUpdate: () => void;
}

type NextData = { calendar: string; tool: string; };

const handleNextTool = (data: NextData, tools: ToolGetResponseDto[]) => {
  const toolId = data.tool;
  const toolData = tools.filter((elm) => elm.id === toolId)[0];
  if (!toolData) {
    throw new Error(`Could not find tool with id: ${toolId}`);
  }

  return { toolId, toolData };
};

const handleNextIssueTypes = async (toolData: ToolGetResponseDto, mutateBacklog: ReturnType<typeof useBacklogAPIIssueTypesGET>['execute']) => {
  let success = false;
  let issueTypes: IssueType[] | undefined;
  if (toolData.type === 'backlog') {
    const { tenantUrl, projectId, apiKey } = toolData.data;
    // const response = await mutateBacklog({ tenantUrl, projectId, apiKey });
    const response = await mutateBacklog({ tenantUrl, projectId, apiKey }, true);
    if (!response) throw new Error('No response');
    // backlogAPI通信後モーダル閉じる。成功時次のモーダル表示、失敗時エラーモーダル表示
    success = response.success;
    if (response.success) {
      issueTypes = response.data;
    }
  } else {
    throw new Error(`Invalid tool type: ${toolData.type}`);
  }

  return {
    success, issueTypes
  };
}

/*
 * ルーチンタスクカレンダー設定画面
 */
const RoutinesCalendar = ({
  rows,
  projectId,
  requestUpdate,
  tools,
}: RoutinesCalendarProps) => {
  const [auth, setAuth] = useState<GoogleCredentialsAuthResponseDto>(); // auth
  const [deleteData, setDeleteData] = useState({ id: "", name: "" }); // 削除カレンダー
  const [inputDatas, setInputDatas] = useState<RoutinesInputType>(); // 入力データ
  const [selects, setSelects] = useState<Array<any>>([]); // セレクトタブ
  const [errorMessage, setErrorMessage] = useState<string | null>(null); // エラーメッセージ
  // const { mutate: mutateBacklog, isLoading: isLoadingBacklog } = useBacklogAPIIssueTypesGET(); // backlogApi通信
  const { execute: mutateBacklog, isLoading: isLoadingBacklog } = useBacklogAPIIssueTypesGET(); // backlogApi通信
  const { mutate: mutatePost, isLoading: isLoadingPost } = useAPICalendarRoutinePOST(); // カレンダー登録
  
  const { mutate: mutateDelete, isLoading: isLoadingDelete } = useAPIRoutinesCalendarDELETE(); //カレンダー削除

  // モーダル表示
  const [modal1Open, setModal1Open] = useState(false);
  const handleModal1Open = () => setModal1Open(true);
  const handleModal1Close = () => setModal1Open(false);
  // モーダル表示
  const [modal2Open, setModal2Open] = useState(false);
  const handleModal2Open = () => setModal2Open(true);
  const handleModal2Close = () => setModal2Open(false);
  // 一覧の削除ボタン押下時、モーダル表示
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const handleOpenDeleteModal = (values: ValuesType) => {
    setDeleteData(values);
    setOpenDeleteModal(true);
  };
  const handleCloseDeleteModal = () => setOpenDeleteModal(false);
  // エラーモーダル表示非表示処理
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const handleErrorModalOpen = () => setErrorModalOpen(true);
  const handleErrorModalClose = () => {
    setErrorModalOpen(false);
    setErrorMessage("");
  };

  //「戻る」ボタン押下時処理
  const handleBack = () => {
    handleModal2Close();
    handleModal1Open();
  };

  const onCalendarsSuccess = ({ calendars, auth }: CalendarSuccessParams) => {
    console.debug('onCalendarsSuccess', { calendars, auth });
    setSelects([calendars, tools]);
    setAuth(auth);
    // requestUpdate();
    handleModal1Open(); // モーダル表示
  };
  const onCalendarsFailure = (errorMessage: string) => {
    console.debug('onCalendarsFailure', { errorMessage });
    setErrorMessage(errorMessage);
    handleErrorModalOpen();
  };

  const { handleClickAddCalendar, isLoadingGoogle } = useCalendars('google', onCalendarsSuccess, onCalendarsFailure);

  //モーダル閉じた際、データ保持しない。
  useEffect(() => {
    if (!modal1Open && !modal2Open) {
      setInputDatas(undefined);
    }
  }, [modal1Open, modal2Open]);

  /**
   * 「次へ」ボタン押下時、external tool API通信処理
   * @param data
   */
  const handleNext: SubmitHandler<TypedFormInputType<NextData>> = async (data) => {
    const calendar = data.calendar;

    const { toolId, toolData } = handleNextTool(data, tools);
    const { success, issueTypes } = await handleNextIssueTypes(toolData, mutateBacklog);

    if (success && issueTypes) {
      setInputDatas({
        calendar: calendar,
        tool: toolId,
        issueType: issueTypes,
      });
      handleModal1Close();
      handleModal2Open();
    } else {
      setErrorMessage(`通信に失敗しました。\n入力値を確認してください。`);
    }
  };

  /**
   * カレンダー登録処理
   * @param data
   */
  const handleSubmit: SubmitHandler<FormInputType> = async (data) => {
    const type = 'google';
    const issueType = String(data.issueType);
    if (!inputDatas) {
      throw new Error('No inputDatas');
    }

    const { commonExternalData } = getCommonCalendarExternalData(inputDatas?.calendar, auth);    

    const response = await mutatePost({
      projectId,
      params: {
        type,
        data: commonExternalData,
        projectManagementToolId: inputDatas.tool,
        issueTypeId: issueType,
      },
    });
    //post終了後モーダル閉じる。失敗時エラー表示
    if (response.success) {
      requestUpdate();
      handleModal2Close();
    } else {
      setErrorMessage("カレンダー新規作成に失敗しました。");
      handleModal2Close();
      handleErrorModalOpen();
    }
  };

  /*
   * カレンダー削除処理
   */
  const handleDelete = async () => {
    const response = await mutateDelete({ projectId, id: deleteData.id });
    //削除後モーダル閉じる。失敗時エラーモーダル表示
    if (response.success) {
      requestUpdate();
      handleCloseDeleteModal();
    } else {
      setErrorMessage("カレンダーの削除に失敗しました。");
      handleCloseDeleteModal();
      handleErrorModalOpen();
    }
  };

  if (
    !rows ||
    isLoadingPost ||
    isLoadingDelete ||
    isLoadingGoogle ||
    isLoadingBacklog
  ) {
    return <Loading name="RoutinesCalendar" />;
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Title title="登録済みカレンダー一覧" icon="list" size="subtitle1" />
        <CustomButton
          id={IDS.CREATE_ROUTINE_TASK_BUTTON}
          text="カレンダーを登録する"
          onClick={handleClickAddCalendar}
          icon="addCircle"
        />
      </Box>

      {modal1Open && (
        <FormModal
          title="カレンダー登録"
          buttonText="次へ"
          open={modal1Open}
          onSubmit={handleNext as SubmitHandler<FormInputType>}
          onClose={handleModal1Close}
          labels={nextLabels}
          values={inputDatas}
          selectValues={selects}
          selects={true}
        />
      )}
      {modal2Open && (
        <FormModal
          title="カレンダー登録"
          buttonText={REGISTER_BUTTON_TEXT}
          open={modal2Open}
          onSubmit={handleSubmit}
          onClose={handleModal2Close}
          onBack={handleBack}
          labels={submitLabels}
          selectValues={inputDatas?.issueType}
        />
      )}

      <BasicTable headers={headers}>
        {rows.length !== 0 ? (
          rows.map((row, i: number) => (
            <TableRow
              key={`row-${i}`}
              sx={{ "&:last-child td": { border: 0 } }}
            >
              <TableCell align="center">{row.data.data?.name || ''}</TableCell>
              <TableCell align="center">
                {row.projectManagementTool.name}
              </TableCell>
              <TableCell align="center">{row.issueType?.name || ''}</TableCell>
              <TableCell align="center">
                <IconButton
                  className={DATA_CLASSES.DELETE_ROUTINE_TASK_ITEM_BUTTON}
                  onClick={() =>
                    handleOpenDeleteModal({
                      id: row.id,
                      name: row.data.data?.name || '',
                    })
                  }
                >
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))
        ) : (
          <TableRow sx={{ "&:last-child td": { border: "none" } }}>
            <TableCell align="center" colSpan={headers.length}>
              カレンダーを登録してください。
            </TableCell>
          </TableRow>
        )}
      </BasicTable>
      <DeleteModal text={getDeleteTargetTitleText(deleteData.name, 'カレンダー名')} open={openDeleteModal} onClick={handleDelete} onClose={handleCloseDeleteModal} />
      <ErrorModal text={errorMessage} open={errorModalOpen} onClose={handleErrorModalClose} />
    </>
  );
};

export default RoutinesCalendar;
