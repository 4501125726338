import { useAPIProjectsGET } from "api";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { getItemFromIdRowList } from "utilities";
import useAppState from "./useAppState";

/**
 * 現在設定されたプロジェクト
 */
const useProject = () => {
    const params = useParams();

    const [state, setAppState] = useAppState();
    
    // const { projects } = state;
    const { projectId } = params;
    // useEffect(() => console.debug('useProject params change', { params }), [params]);
    // useEffect(() => console.debug('useProject projectId change', { projectId }), [projectId]);
    
    const { data: projects, execute, isLoading, errorMessage } = useAPIProjectsGET();
    
    useEffect(() => {
        execute({});
    }, []);

    const project = projects ? getItemFromIdRowList(projectId, projects) : null;
    
    const setProjectFromParams = () => {
        const { projectId } = params;
        if (!projectId) {
            throw new Error('No projectId');
        }
        setAppState({ ...state, projectId });
    };

    return {
        projectId, project, execute, isLoading, errorMessage, setProjectFromParams,
    };
};

export default useProject;
