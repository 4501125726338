import { Box, Button, Grid } from "@mui/material";
import { useAPIDeskTicketCommentPOST, useAPIDeskTicketGET, useAPIDeskUploadFilePOST } from "api";
import { Loading } from "components";
import { MAX_DESK_COMMENT_COUNT } from "constants/constants";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import DeskItemEditor, { DeskItemEditorSubmitParams } from "./block/DeskItemEditor";
import DeskItemMeta from "./block/DeskItemMeta";
import DeskItemQuestion from "./block/DeskItemQuestion";
import DeskItemResponse from "./block/DeskItemResponse";
import { format } from 'date-fns';
import APIGetHandler from "components/APIGetHandler";

const handleSubmit = async ({ ticketId, message, uploadFile, createTicketComment }: {
    ticketId: string | undefined;
    message: DeskItemEditorSubmitParams;
    uploadFile: ReturnType<typeof useAPIDeskUploadFilePOST>['mutate'];
    createTicketComment: ReturnType<typeof useAPIDeskTicketCommentPOST>['mutate'];
}) => {
    if (!ticketId) throw new Error('No ticketId');
    const { text, attachments } = message;
    const uploadResponses = await Promise.all(attachments.map((attachment) => {
        return uploadFile({ params: attachment.file });
    }));
    const uploads: string[] = [];
    uploadResponses.forEach((response) => {
        if (response.success) {
            uploads.push(response.data.token);
        }
    });
    await createTicketComment({ ticketId, params: { body: text, uploads } });
    console.debug('DeskItemPage:onSubmit END');
};

const DeskItemPage = () => {
    const { ticketId } = useParams();
    const { data: ticket, execute: getTicket, isLoading: isLoading1DeskTicketGET, errorMessage } = useAPIDeskTicketGET();
    const { mutate: uploadFile, isLoading: isLoadingDeskUploadFilePOST } = useAPIDeskUploadFilePOST()
    const { mutate: createTicketComment, isLoading: isLoadingTicketCommentPOST } = useAPIDeskTicketCommentPOST();
    const isLoading = isLoading1DeskTicketGET || isLoadingDeskUploadFilePOST || isLoadingTicketCommentPOST;

    const execute = (force = false) => getTicket({ ticketId }, force);
    const forceRefresh = () => execute(true);
    useEffect(() => {
        execute();
    }, []);

    const onSubmit = async (message: DeskItemEditorSubmitParams) => {
        await handleSubmit({ ticketId, message, uploadFile, createTicketComment });
        forceRefresh();
    };
    /*
    const onSubmit = async (message: DeskItemEditorSubmitParams) => {
        if (!ticketId) throw new Error('No ticketId');
        const { text, attachments } = message;
        const uploadResponses = await Promise.all(attachments.map((attachment) => {
            return uploadFile({ params: attachment.file });
        }));
        const uploads: string[] = [];
        uploadResponses.forEach((response) => {
            if (response.success) {
                uploads.push(response.data.token);
            }
        });
        await createTicketComment({ ticketId, params: { body: text, uploads } });
        forceRefresh();
        console.debug('DeskItemPage:onSubmit END');
    };
    */

    if (isLoading || errorMessage || !ticket) {
        return <APIGetHandler settings={{ name: 'DeskItemPage', isLoading, errorMessage, retry: () => execute() }} />
    }

    const { createdAt, updatedAt, comments, status, description: body, subject, uploads, createdBy } = ticket;
    const commentCount = comments.length + 1; // 最初の投稿は仕様上、一つのコメントとしてみなされている。

    // DATE
    const DATE_FORMAT = 'yyyy/MM/dd HH:mm';
    const formattedCreatedAt = format(new Date(createdAt), DATE_FORMAT);
    const formattedUpdatedAt = format(new Date(updatedAt), DATE_FORMAT);

    const maxCommentCountReached = commentCount >= MAX_DESK_COMMENT_COUNT;
    const ticketComplete = status === 'solved';
    const showEditor = !maxCommentCountReached && !ticketComplete;

    return (
        <Box>
            <Grid container>
                <Grid item xs={8}>
                    <Box>
                        <DeskItemQuestion subject={subject} body={body} createdBy={createdBy ? createdBy.name : ''} createdAt={formattedCreatedAt} uploads={uploads} ></DeskItemQuestion>
                        {
                            comments.map((item) => <DeskItemResponse comment={item}></DeskItemResponse>)
                        }
                        {
                            showEditor && (
                                <DeskItemEditor onSubmit={onSubmit} />
                            )
                        }
                        {
                            maxCommentCountReached && (
                                <Button color="error">
                                    1つのチケットに対するコメントが上限に達しました。新たにチケットを作成してください。
                                </Button>
                            )
                        }
                    </Box>
                </Grid>
                <Grid item xs={4}>
                    <Box>
                        <DeskItemMeta createdAt={formattedCreatedAt}  updatedAt={formattedUpdatedAt} status={status} commentCount={commentCount}></DeskItemMeta>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};
export default DeskItemPage;
