import { Route } from "react-router-dom";
// VIEWS
import Home from "views/Home";
// import WallPage from "views/WallPage";
import SurveysPage from "views/SurveysPage";
import SurveysHistoryPage from "views/SurveysHistoryPage";
import SurveysQuestionsPage from "views/SurveysQuestionsPage";
import SurveysResultsPage from "views/SurveysResultsPage";
import ProjectListPage from "views/ProjectListPage";
import ProjectSettingPage from "views/ProjectSettingPage";
import TaskListPage from "views/TaskListPage";
import AlertsPage from "views/AlertsPage";
import AcademyPage from "views/Academy15Page";
import AcademyPlayPage from "views/Academy15PlayPage";
import Dashboard from "views/Dashboard/Dashboard";
import DeskListPage from "views/DeskListPage";
import DeskItemPage from "views/DeskItemPage";
import { RouteItem } from "types";
import BookmarksPage from "views/BookmarksPage";
//

export const Routes = {
  LoggedOutHome: Home,
  /**
   * ログイン後のHome
   */
  LoggedInHome: AcademyPage,
  /**
   * ログイン後（契約済み）のHome
   */
  LoggedInAndLicensedHome: ProjectListPage,
  // DANDLE
  ProjectSettingPage: ProjectSettingPage,
  TaskListPage: TaskListPage,
  AlertsPage: AlertsPage,
  BookmarksPage: BookmarksPage,
  // ACADEMY
  AcademyPage: AcademyPage,
  AcademyPlayPage: AcademyPlayPage,
  // SURVEY
  SurveysPage: SurveysPage,
  SurveysQuestionsPage: SurveysQuestionsPage,
  SurveysResultsPage: SurveysResultsPage,
  SurveysResultsWithNoIdPage: SurveysResultsPage,
  SurveysHistoryPage: SurveysHistoryPage,
  // DESK
  DeskListPage: DeskListPage,
  DeskItemPage: DeskItemPage,
  // OTHER
  // WallPage: WallPage,
  Dashboard: Dashboard,
};

export const getRouteView = (key: keyof typeof Routes, path: string) => {
  const Element = Routes[key];
  return () => <Route path={path} element={<Element />} key={key} />
};

/**
 * 複数ルートの取得
 */
export const getRouteViews = (routeItems: RouteItem[]) => {
  // const routes: (() => JSX.Element)[] = [];
  const routes: JSX.Element[] = [];

  routeItems.forEach((item) => {
    const { route, to, disabled } = item;
    if (route && to && !disabled) {
      const routeView = getRouteView(route, to);
      routes.push(routeView());
    }
  });

  console.debug('getRouteViews', { routeItems, routes });

  return routes;
};
