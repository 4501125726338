import React from "react";
import { Typography } from "@mui/material";
import { useHubspotForm } from "@aaronhayes/react-use-hubspot-form";
import { IDS } from "constants/constants";
const Home = () => {
  const { loaded, error, formCreated } = useHubspotForm({
    portalId: '9302130',
    formId: 'ff334d4f-abe8-4dc2-8656-a6cc9f35003f',
    target: `#${IDS.HUBSPOT_FORM_TARGET}`
  });

  return (
    <>
      <Typography variant="h5">ようこそ、ANDGATEへ。</Typography>
      <Typography>
        現在オープンベータ版を提供しています。
      </Typography>
      <Typography>
        すべての機能を利用したい方は、以下フォームよりお問い合わせください。
      </Typography>
      <div id={IDS.HUBSPOT_FORM_TARGET}></div>
    </>
  );
};

export default Home;
