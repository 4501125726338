import { formatDate } from "models";
import { CreatedAtObject, DateParameter, RoleKey, RouteItem } from "types";
import { TaskDto } from "types/api";
import { AxiosError, AxiosResponse } from "axios";
import { marked } from 'marked';
// import DOMPurify from 'dompurify'; // markedでHTML制限をする。
import { v4 as uuidv4 } from 'uuid';

export const getParsedEnvironmentVariables = () => {
  return {
    // BUILD
    NODE_ENV: process.env.NODE_ENV || "",
    // APP
    REACT_APP_API_URL: process.env.REACT_APP_API_URL || "",
    REACT_APP_DEBUG: process.env.REACT_APP_DEBUG === 'true',
    // AUTH0
    REACT_APP_AUTH0_DOMAIN: process.env.REACT_APP_AUTH0_DOMAIN || "",
    REACT_APP_AUTH0_CLIENT_ID: process.env.REACT_APP_AUTH0_CLIENT_ID || "",
    REACT_APP_AUTH0_AUDIENCE: process.env.REACT_APP_AUTH0_AUDIENCE || "",
    // GOOGLE:
    REACT_APP_CLIENT_ID: process.env.REACT_APP_CLIENT_ID || "",
    REACT_APP_GTM_ID: process.env.REACT_APP_GTM_ID || "",
  };
};

/*
 * 各日付変換処理
 */
export const toTaskDates = (
  taskStartDate?: DateParameter | null,
  taskDueDate?: DateParameter | null,
  taskUpdated?: DateParameter | null
) => {
  const startDate = taskStartDate ? formatDate(new Date(taskStartDate)) : "";
  const dueDate = taskDueDate ? formatDate(new Date(taskDueDate)) : "";
  const updated = taskUpdated ? formatDate(new Date(taskUpdated)) : "";
  return { startDate, dueDate, updated };
};

export const taskToTaskDates = (task: TaskDto) => toTaskDates(task.startDate, task.dueDate, task.data.updated);
export const createSetTaskRowsFunc = (task: TaskDto) => {
  return (prevState: (TaskDto[] | null | undefined)) => {
    const { startDate, dueDate, updated } = taskToTaskDates(task);
    return prevState ? prevState.map((obj) => {
      return obj.id === task.id ? {
        ...obj,
        startDate,
        dueDate,
        data: {
          updated: updated,
          category: obj.data.category,
        },
      } : obj
    }) : null;
  };
};

/**
 * クリップボードにコピー
 */
export const copyToClipboard = async (text: string) => {
  try {
    await navigator.clipboard.writeText(text);
    alert("コピーしました");
  } catch (error) {
    alert(error && "コピーに失敗しました");
  }
};

/*
  * データ日付変換処理
  */
export const toDate = (date?: string) => {
  if (date) {
    return new Date(date);
  } else {
    return null;
  }
};

/**
 * 各パラメーターのfalsyチェック
 */
export const checkIsTruthy = <T>(params: Record<string, any>, callback: () => (T)) => {
  Object.entries(params).forEach(([key, value]) => {
      if (!value) {
          throw new Error(`Falsy check fail: ${key}`);
      }
  });
  return callback();
};

export const getItemFromIdRowList = <T extends { id: string }>(id: string | undefined, items: T[] | undefined) => items && id ? items.filter((i) => i.id === id)[0] : null;

/**
 * Bearer [TOKEN]
 */
export const getBearerToken = (authorizationHeader: string) => authorizationHeader.split(' ')[1];

export const createIncremetor = (start = 0) => {
  let cur = start;
  return {
    cur: () => cur,
    next: () => ++cur,
  };
};

export const sortByCreatedAtAsc = (items: CreatedAtObject[]) => {
  items.sort((a, b) => a.createdAt > b.createdAt ? 1 : -1);
};

export const sortByCreatedAtDesc = (items: CreatedAtObject[]) => {
  sortByCreatedAtAsc(items);
  items.reverse();
};

export const handleAxiosSuccessFailureResponse = (axiosResponse: Promise<AxiosResponse<any, any>>) => {
  return axiosResponse
  .then((response) => {
    return { success: true as true, data: response.data, statusCode: response!.status };
  })
  .catch((error: AxiosError) => {
    console.error(error);
    return { success: false as false, data: error, statusCode: error.response!.status };
  })
};

/**
 * Axios Errorから409の対応。
 * messageが設定されていれば、それを使うこと。
 * @example if(handleConflictError(res, (message) => setErrorMessage(message))) { ... } else { ... }
 */
export const handleConflictError = (response: any, onMatch: (message: string) => void, message: string = 'コンフリクトがありました。') => {
  if (response.statusCode === 409) {
    const usedMessage = message || response?.data?.response?.data?.message || 'コンフリクトがありました。' as string;
    onMatch(usedMessage);
    return true;
  }
  return false;
};

export const hasRequiredRoles = (roles: RoleKey[], requiredRoles: RoleKey[]) => {
  const isMatch = (
    requiredRoles.length === 0 ||
    !requiredRoles.some((requiredRole) => !roles.includes(requiredRole))
  );
  return isMatch;
};

/**
 * // TEST用: For security reasons, `window.crypto` is required to run `auth0-spa-js`.
 */
 export function shimCrypto() {
  if (!window.crypto) {
    Object.defineProperty(global.self, 'crypto', {
      value: {
        getRandomValues: (arr: Int8Array[]) => {
          const length = arr.length;
          let i = 0;
          while (i <= length) {
            (arr as unknown as number[])[i] = Math.floor(Math.random() * 255);
            i++;
          }
          return arr;
        }
      },
    });
    (global.crypto as any).subtle = {} // this gets around the 'auth0-spa-js must run on a secure origin' error
  }
}

/**
 * @see https://github.com/cure53/DOMPurify
 * @see https://github.com/markedjs/marked/issues/1628
 * @see https://marked.js.org/using_advanced#options
 */
export function markdownToHTML(html: string) {
  return marked.parse(html);
}

export const routeItemHasUnparsedPathKey = (item: RouteItem) => item.to && item.to.includes('/:');

export const getRandomId = (prefix = '') => `${prefix}${uuidv4()}`;
export const getRandomIndexedId = (index: number, prefix = '') => `${getRandomId(prefix)}${index}`;

export const openLinks = (urls: string[]) => {
  urls.forEach((url) => {
    // 新規タブで仕様確定: https://andgate-co.backlog.com/view/AP-417#comment-131543203
    window.open(url, '_blank'); // new tab
    /*
    const open = () => {
      const width = 570;
      const height = 520;
      window.open(url, '_blank', `location=yes,height=${height},width=${width},scrollbars=yes,status=yes`); // new window
    };
    // open();
    window.setTimeout(open, 1);
    */
  });
};

export const unique = (arr: any[]) => {
  const newArr: any[] = [];
  arr.forEach((item) => {
    if (!newArr.includes(item)) {
      newArr.push(item);
    }
  });
  return newArr;
};

export const removeFromArray = (arr: any[], val: any) => {
  const index = arr.indexOf(val);
  if (index >= 0) {
    arr.splice(index);
  }
  return arr;
};
