import CustomSnackbar from "./CustomSnackbar";

/**
 * APIのPOST/PUT/DELETEの処理用
 */
const APIMutateHandler = ({ message }: { message?: string | null }) => {
  return (
    <CustomSnackbar severity="error" message={message} />
  );
};

export default APIMutateHandler;
