import { BasicTable } from "components";
import { TableRow, TableCell } from "@mui/material";
import { formatDate } from "models";
import { DiagnosesResponseDto } from "types/api";

interface SurveysHistoryTableProps {
  rows: DiagnosesResponseDto[];
}

interface Row {
  id: string;
  createdAt: string;
  question: string;
  answers: { text: string, createdAt: string }[];
}

/**
 * 既にSurveyでグループ化された。
 * row.results.textは仕様上、項目数=0ができない。
 * 
 */
const SurveysHistoryComparisonTable = ({ rows: allRows }: SurveysHistoryTableProps) => {
  /**
   * 質問でグループ化されている。
   */
  const rows: Row[] = [];
  allRows.forEach((row) => {
    const { createdAt } = row;
    row.results.text.forEach((text) => {
      const id = text.question.id;
      const getRow = () => rows.filter((r) => r.id === id)[0];

      // 初期化
      if (!getRow()) {
        const r: Row = {
          id,
          createdAt,
          question: text.question.id,
          answers: []
        };
        rows.push(r);
      }

      const r = getRow() as Row;
      r.answers.push({ text: text.answer, createdAt });
    });
  });

  // Headers
  const headers = allRows.map((row) => {
    const dateString = row && formatDate(new Date(row.createdAt));
    return {
      name: dateString, value: 'value', title: row.createdAt,
    }
  });
  headers.unshift({ name: '', value: 'question', title: '' });

  const Row = ({ row }: { row: Row }) => {
    return (
      <TableRow sx={{ "&:last-child td": { border: "none" } }}>
        <TableCell sx={{ textAlign: "center" }}>{row.question}</TableCell>
        {
          row.answers.map((answer, index) => (
            <TableCell sx={{ textAlign: "center" }} key={index} >{answer.text}</TableCell>
          ))
        }
      </TableRow>
    );
  };
  return (
    <BasicTable headers={headers}>
      {rows.length !== 0 ? (
        rows.map((row, index) => (
          <Row key={`row-${index}`} row={row} />
        ))
      ) : (
        <></>
      )}
    </BasicTable>
  );
};

export default SurveysHistoryComparisonTable;
