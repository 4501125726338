/**
 * 日付フォーマット処理(yyyy/mm/dd)
 * @param date
 * @returns
 */
export const formatDate = (date: Date) => {
  const Year = date.getFullYear();
  const Month = ("0" + (date.getMonth() + 1)).slice(-2);
  const Day = ("0" + date.getDate()).slice(-2);
  return `${Year}/${Month}/${Day}`;
};
