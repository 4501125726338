import Modal from "./Modal";

const ErrorModal = ({ text, open, onClose }: { text?: string | null; open: boolean; onClose: () => void; } ) => {
    return (
        <>
        {open && (
          <Modal
            title="エラー"
            text={text || ''}
            open={open}
            onClose={onClose}
            onClick={onClose}
            error
          />
        )}
      </>
    );
};

export default ErrorModal;
