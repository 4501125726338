import { useEffect, useState } from "react";
import { SubmitHandler } from "react-hook-form";
import { Box, Typography } from "@mui/material";
import { Title, FormModal, CustomButton, Loading } from "components";
import { Attachment, FormInputType, FormType, TypedFormInputType } from "types";
import { useAPIDeskTicketPOST, useAPIDeskTicketTemplatesGET, useAPIDeskUploadFilePOST, useAPIDeskUserPOST } from "api";
import ErrorModal from "components/ErrorModal";
import { IDS, LABELS } from "constants/constants";
import DeskListTable from "./block/DeskListTable";
import { DESK_TITLE } from "constants/messages";
import { useAPIDeskTicketsGET } from "api";
import { LARGE_FORM_MODAL_STYLES } from "styles/constants";
import APIGetHandler from "components/APIGetHandler";
import APIMutateHandler from "components/APIMutateHandler";
import { DeskTicketTemplatesGetResponseDto, TicketTemplateFieldDto } from "types/api";

const TICKET_TEMPLATE_LABELS: FormType[] = [
  LABELS.TICKET_TEMPLATE,
];
const FIELD_PREFIX = 'field_';

type GetCreateDataParams = Record<string, string> & { attachments: FileList, subject: string };
interface CreateDataField { question: string; response: string; }
interface CreateData { attachments: Attachment[]; subject: string; fields: CreateDataField[] }

const getCreateData = (data: GetCreateDataParams, templateFields: TicketTemplateFieldDto[]) => {
  console.debug('getCreatedData', { data }); // TODO: attachments: 現在のfileがあるが、ほかに選択したfileが残らない。clearもチェック。
  // return; // DEBUG用
  const attachments: Attachment[] = Array.from(data.attachments).map((item) => ({ filename: item.name, file: item }));

  const fields: CreateDataField[] = [];
  Object.entries(data).forEach(([rawKey, value]) => {
    if (rawKey.startsWith(FIELD_PREFIX) && typeof value === 'string') {
      const id = rawKey.substring(FIELD_PREFIX.length); // ticket template id.
      const templateItem = templateFields.filter((item) => item.id === id)[0];
      const question = templateItem ? templateItem.label : id;
      const response = value;
      fields.push({
        question, response,
      });
    }
  })

  const d: CreateData = {
    attachments,
    subject: data.subject,
    fields,
  };

  return d;
};

const uploadFiles = async (data: CreateData, uploadFile: ReturnType<typeof useAPIDeskUploadFilePOST>['mutate']) => {
  const { attachments } = data;
  const files = attachments.map((item) => item.file);
  const uploads: string[] = [];
  await Promise.all(files.map(async (file) => {
    if (!file) return;
    const response = await uploadFile({ params: file });
    if (response.success) {
      uploads.push(response.data.token);
    }
  }));
  return uploads;
}

const createTicketParams = (data: CreateData, uploads: string[]) => {
  /**
  * 送信時に「フィールド名」「回答」「フィールド名」「回答」...となるようにする
  */
  // const formatField = (question: string, response: string) => `「${question}」\n${response}`; // PLAIN
  // const formatField = (question: string, response: string) => `**${question}**\n${response}`; // MARKDOWN **
  const formatField = (question: string, response: string) => `## ${question}\n${response}`; // MARKDOWN #
  const body = data.fields.map(({ question, response }) => formatField(question, response)).join('\n\n');
  const { subject } = data;

  return { body, subject, uploads };
}

const getCurrentTemplateInfo = (templates: DeskTicketTemplatesGetResponseDto[], currentTemplate: string | undefined) => {
  const getCurrentTemplate = () => templates.filter((template) => template.id === currentTemplate)[0];
  const getCurrentTemplateFields = () => {
    const t = getCurrentTemplate();
    return t ? t.fields : [];
  };

  const templateSelectValue = templates.map((item) => {
    return {
      id: item.id,
      name: item.name
    };
  });

  const NO_INPUT_LABEL_STYLE = { variant: 'standard', label: '' };
  const fields = getCurrentTemplateFields();
  const labels: FormType[] = fields.map((item) => ({
    name: item.label,
    value: FIELD_PREFIX + item.id,
    // type: 'textField',
    // type: 'textArea',
    type: item.label === '発生日時' ? 'textField' : 'textArea',
    required: item.required,
    errorMessage: '値を入力してください。',
    props: NO_INPUT_LABEL_STYLE,
  }));
  labels.unshift({ ...LABELS.TITLE, type: 'textField', props: NO_INPUT_LABEL_STYLE });
  labels.push(LABELS.ATTACHMENTS);
  const selectValues = Object.fromEntries(labels.map((item) => {
    const key = item.name;
    const value = '';
    return [key, value]
  }));

  return {
    labels, fields, selectValues, templateSelectValue,
  };
};

/**
 * Deskのチケット一覧画面
 */
const DeskListPage = () => {
  const [mutateErrorMessage, setMutateErrorMessage] = useState<string | null>(null);
  // const [labels, setLabels] = useState<FormType[]>([]);
  const [currentTemplate, setCurrentTemplate] = useState<string | undefined>();
  const { data: items, execute: getTickets, isLoading: isLoadingAPIDeskTicketsGET, errorMessage: errorMessageDeskTicketsGET } = useAPIDeskTicketsGET();
  const { data: templates, execute: getTicketTemplates, isLoading: isLoadingDeskTicketTemplatesGET, errorMessage: errorMessageDeskTicketTemplatesGET } = useAPIDeskTicketTemplatesGET();
  const { mutate: createTicket, isLoading: isLoadingDeskTicketPOST } = useAPIDeskTicketPOST();
  const { mutate: uploadFile, isLoading: isLoadingDeskUploadFilePOST } = useAPIDeskUploadFilePOST();
  const { mutate: ensureDeskUser, isLoading: isLoadingDeskUserPOST } = useAPIDeskUserPOST();

  const isLoading = isLoadingAPIDeskTicketsGET || isLoadingDeskTicketTemplatesGET || isLoadingDeskTicketPOST || isLoadingDeskUploadFilePOST || isLoadingDeskUserPOST;
  const errorMessage = errorMessageDeskTicketsGET || errorMessageDeskTicketTemplatesGET;
  const execute = async (force = false) => {
    // await ensureDeskUser();
    getTickets({}, force);
    getTicketTemplates({}, force);
  };
  useEffect(() => {
    execute();
  }, []);
  //
  const requestUpdate = () => execute(true);

  // TEMPLATE
  const [templateModalOpen, setTemplateModalOpen] = useState(false);
  const handleTemplateModalOpen = () => setTemplateModalOpen(true);
  const handleTemplateModalClose = () => setTemplateModalOpen(false);
  const handleTemplateSelection = ({ template: templateId }: { template: string }) => {
    setCurrentTemplate(templateId);
    handleTemplateModalClose();
    handleFormModalOpen();
  };
  const handleGoBackToTemplateSelection = () => {
    handleFormModalClose();
    handleTemplateModalOpen();
  };
  const handleClick = () => {
    // 新規タブを開いて遷移
    window.open('https://www.andgate.jp/hubfs/AGPFマニュアル/&SUPPORTチケット起票ガイドライン.pdf');
  };
  // FORM
  const [formModalOpen, setFormModalOpen] = useState(false);
  const handleFormModalOpen = () => setFormModalOpen(true);
  const handleFormModalClose = () => setFormModalOpen(false);
  //
  // エラーモーダル表示非表示処理
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const handleErrorModalClose = () => setErrorModalOpen(false);

  /**
   * FORM Create
   */
  const handleCreate: SubmitHandler<TypedFormInputType<CreateData>> = async (data) => {
    const isValid = !mutateErrorMessage;
    if (isValid) {
      await ensureDeskUser();
      const uploads = await uploadFiles(data, uploadFile);
      await createTicket({ params: createTicketParams(data, uploads) });
      
      requestUpdate();
      handleFormModalClose();
    } else {
      setMutateErrorMessage("新規チケット作成に失敗しました。");
    }
  };

  if (isLoading || errorMessage || !items || !templates) {
    return <APIGetHandler settings={{ name: 'DeskListPage', isLoading, errorMessage, retry: () => execute() }} />
  }

  const { fields, labels, selectValues, templateSelectValue } = getCurrentTemplateInfo(templates, currentTemplate);

  return (
    <>
      <Title title={DESK_TITLE} icon="list" />
      <Typography sx={{mb: "10px"}}>チケットを送信される前に、ガイドラインをご確認ください。</Typography>
      <CustomButton
          id={IDS.SEND_TICKET_BUTTON}
          text="チケット起票ガイドライン"
          color="info"
          onClick={handleClick}
          icon="PictureAsPdf"
        />
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        {templateModalOpen && (
          <FormModal
            title="チケットテンプレートを選択"
            buttonText="次へ"
            open={templateModalOpen}
            onSubmit={handleTemplateSelection as any as SubmitHandler<FormInputType>}
            onClose={handleTemplateModalClose}
            labels={TICKET_TEMPLATE_LABELS}
            selectValues={templateSelectValue}
          />
        )}
        {formModalOpen && (
          <FormModal
            title="チケットを登録"
            buttonText="送信"
            open={formModalOpen}
            onSubmit={(ev) => handleCreate(getCreateData(ev as GetCreateDataParams, fields)) as any as SubmitHandler<FormInputType>}
            onClose={handleFormModalClose}
            onBack={handleGoBackToTemplateSelection}
            labels={labels}
            selectValues={selectValues}
            sx={LARGE_FORM_MODAL_STYLES}
            externalLabel
          />
        )}
        <APIMutateHandler message={mutateErrorMessage} />
        <CustomButton
          id={IDS.SEND_TICKET_BUTTON}
          text="チケットを送信する"
          onClick={handleTemplateModalOpen}
          icon="addCircle"
        />
      </Box>
      <DeskListTable rows={items} />
    </>
  );
};

export default DeskListPage;
