import { useState } from "react";
import useAuthAxios from "hooks/useAuthAxios";
import { DeleteResponse, UseAuthAxiosProps } from "types";
import { handleAxiosSuccessFailureResponse } from "utilities";
import { useAPIErrorMessage } from "components/GlobalSnackbar/globalSnackbarContext";

interface UseDeleteProps extends UseAuthAxiosProps {
  //
};

/**
 * authRequiredはいつもtrueになっている。例外が決まれば、対応すること。
 * requiredRolesはいつも['dandle']になっている。例外が決まれば、対応すること。
 */
const useDelete = (options: UseDeleteProps = {}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { authAxios, canExecute } = useAuthAxios({ authRequired: true, requiredRoles: ['dandle'], ...options });
  const [, setErrorMessage] = useAPIErrorMessage();

  const mutate = async (url: string): Promise<DeleteResponse> => {
    if (!canExecute) {
      throw new Error(`canExecute POST FAILED: ${url}`);
    }

    setIsLoading(true);
    return handleAxiosSuccessFailureResponse(authAxios.delete(url))
      .then((response) => {
        if (response.success) {
          setErrorMessage('');
        } else {
          setErrorMessage(String(response.data));
        }
        return response;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return { mutate, isLoading };
};

export default useDelete;
