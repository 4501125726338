import { useEffect } from 'react';
import { Box } from "@mui/material";
import { useSurvey } from "hooks";
import { Title, Text, Loading } from "components";
import QuestionBox from "./block/QuestionBox";
import { useAPISurveyOneGET } from "api";
import { SURVEYS_TITLE } from 'constants/messages';
import APIGetHandler from 'components/APIGetHandler';

const SurveysQuestionsPage = () => {
  const { surveyId, alias } = useSurvey();

  // プロジェクト診断を取得
  const { data: surveyDatas, execute, isLoading, errorMessage } = useAPISurveyOneGET();
  useEffect(() => {
    execute({ surveyId });
  }, [surveyId]);

  if (isLoading || errorMessage || !surveyId || !surveyDatas) {
    return <APIGetHandler settings={{ name: 'SurveysQuestionsPage', isLoading, errorMessage, retry: () => execute() }} />
  }

  return (
    <>
      <Title
        title={SURVEYS_TITLE}
        subTitle={surveyDatas.name}
        icon="content_paste"
      />
      <Box sx={{ width: "80%", mx: "auto" }}>
        <Title title={surveyDatas.name} size="subtitle1" />
        <Text text="以下の設問にご回答ください。" />
        <QuestionBox
          questions={surveyDatas.questions}
          surveyId={surveyDatas.id}
        />
      </Box>
    </>
  );
};

export default SurveysQuestionsPage;
