import { useEffect, useState } from "react";
import { useMemo } from "react";
//import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import MaterialReactTable, { MRT_Cell, MRT_Column, MRT_ColumnDef, /* MRT_FilterFn, */ MRT_Header, MRT_Row, MRT_TableInstance } from "material-react-table";
import { Loading, Title } from "components";
import { Box, Link } from "@mui/material";
import { formatDate } from "models";
import { createSetTaskRowsFunc } from "utilities";
import { TaskDto } from "types/api";
import { IconName } from "components/Title";
import { GenericObject } from "types";
import { MRT_COMMON_PROPS } from "constants/material-react-table";
import LocalStorageHandler from "utilities/local-storage-handler";

const localStorageHandler = new LocalStorageHandler();

type DateStringExtractor<T> = (data: T) => string | null;
type TData = Record<string, any>;
type MRTCellParams = {
  cell: MRT_Cell<any>;
  column: MRT_Column<any>;
  row: MRT_Row<any>;
  table: MRT_TableInstance<any>;
};

interface AlertsPageTableProps {
  category: { name: string; value: IconName; };
  alerts: TaskDto[];
  index: number;
}
interface PaginationState {
  pageIndex: number;
  pageSize: number;
}
const hasDate = <T extends TData,>(row: MRT_Row<T>, filterValue: Date, extractor: DateStringExtractor<T>) => {
  const comparisonTarget = extractor(row.original);

  const date = formatDate(new Date(filterValue));
  console.debug('hasDate', { comparisonTarget, date });
  return comparisonTarget === date;
};

const INITIAL_STATE = {
  showColumnFilters: true,
  pagination: { pageSize: 5, pageIndex: 0 },
};

const sx = {
  WRAPPER: {
    mb: "20px",
  },
  INNER_WRAPPER: {
    display: "flex",
  },
};

const AlertsPageTable = ({ category, alerts, index }: AlertsPageTableProps) => {
  // テーブルの行
  const [rows, setRows] = useState<TaskDto[] | null>();
  const [flag, setFlag] = useState(false);

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: localStorageHandler.data().tasksListTableRowsPerPage,
  });
  
  useEffect(() => {
    console.debug({ pagination });
    localStorageHandler.set('tasksListTableRowsPerPage', pagination.pageSize);
  }, [pagination])

  useEffect(() => {
    setFlag(!flag);
    setRows(alerts);
  }, [alerts, flag]);

  const createDateFilterFn = <T extends TData,>(extractor: DateStringExtractor<T>) => {
    // const filterFn: MRT_FilterFn<any> = (row: MRT_Row<T>, _id: any, filterValue: Date) => {
    const filterFn: any = (row: MRT_Row<T>, _id: any, filterValue: Date) => {
      return hasDate(row, filterValue, extractor);
    };
    return filterFn;
  };
  
  const createDateFilterData = <T extends TData,>(extractor: DateStringExtractor<T>) => {
    return {
      muiTableHeadCellFilterTextFieldProps: {
        type: "date",
      },
      filterFn: createDateFilterFn<T>(extractor),
      size: 180,
    };
  }
  const createTaskDateFilterData = (extractor: DateStringExtractor<TaskDto>) => createDateFilterData<TaskDto>(extractor);

  const createTaskDateColumnData = createTaskDateFilterData; // Date check
  /*
   * APIデータ更新ごとに日付を変換
   */
   /*
  useEffect(() => {
    // if (alerts) {
    alerts.forEach((task) => {
      setRows(createSetTaskRowsFunc(task));
    });
    // }
  }, [alerts, flag]);
    */
  // TS2589: Type instantiation is excessively deep and possibly infinite.
  const columns = useMemo<MRT_ColumnDef<GenericObject>[]>(
  // const columns = useMemo<MRT_ColumnDef<TaskDto>[]>(
    () => [
      {
        accessorKey: "name",
        header: "タスク",
        filterVariant: "text",
        muiTableHeadCellFilterTextFieldProps: { placeholder: "タスク名" },
        Cell: ({ cell }) => (
          <Link
            target="_blank"
            href={
              alerts.find((a) => a.id === cell.row.original.id)?.taskUrl
            }
          >
            {cell.getValue<number>().toLocaleString()}
          </Link>
        ),
      },
      {
        accessorKey: "assignee",
        header: "担当者",
        filterVariant: "text",
        muiTableHeadCellFilterTextFieldProps: { placeholder: "担当者名" },
      },
      {
        accessorKey: "status",
        header: "ステータス",
        filterVariant: "text",
        muiTableHeadCellFilterTextFieldProps: {
          placeholder: "ステータス",
        },
      },
      {
        accessorKey: "dueDate",
        header: "期限日",
        ...createTaskDateColumnData((d) => d.dueDate),
        filterFn: (row, _id, filterValue) => {
          const date = formatDate(new Date(filterValue));
          return row.original.dueDate === date;
        },
        size: 180,
      },
    ],
    [alerts]
  );

  if (!rows) {
    return <Loading name="AlertsPageTable" />;
  }

  return (
    <Box sx={sx.WRAPPER}>
      <Box sx={sx.INNER_WRAPPER}>
        <Title title={category.name} icon={category.value} size="subtitle1" />
      </Box>
      <MaterialReactTable
        columns={columns}
        data={rows}
        /*
        initialState={INITIAL_STATE}
        */
        initialState={{ showColumnFilters: true, }}
        state={{
          pagination,
        }}
        onPaginationChange={setPagination}
        {...MRT_COMMON_PROPS}
      />
    </Box>
  );
};

export default AlertsPageTable;
