import { Text } from "components";
import { Card } from "@mui/material";
import ResultTable from "./ResultTable";
import TextTable from "./TextTable";
import { SurveyDiagnosisGetResponseDto } from "types/api";
import { filterImportantSurveyDiagnosisData } from './helpers';
import ResultChart from "./ResultChart";

interface ResultBoxProps {
  data: SurveyDiagnosisGetResponseDto;
}

const ResultBox = ({ data }: ResultBoxProps) => {

  const results = filterImportantSurveyDiagnosisData(data);
  
  // 文字の回答
  const textRows = data.results.text;

  return (
    <Card variant="outlined" sx={{ p: 2 }}>

      <ResultChart data={data} />

      <Text text={data.comment || ''} />

      <ResultTable rows={results} />

      <TextTable rows={textRows} />
    </Card>
  );
};

export default ResultBox;
