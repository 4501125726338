import { useEffect, useMemo, useState } from "react";
import { Link } from "@mui/material";
import MaterialReactTable, { MRT_Cell, MRT_Column, MRT_ColumnDef, /* MRT_FilterFn, */ MRT_Header, MRT_Row, MRT_TableInstance } from "material-react-table";
// import { Row } from '@tanstack/react-table';
import { formatDate } from "models";
import { TaskDto } from "types/api";
import { DateRange, GenericObject } from "types";
import LocalStorageHandler from "utilities/local-storage-handler";
import DateRangePicker, { OptionalDateRange } from "components/DateRangePicker";
import { Box } from "@mui/joy";
import { MRT_COMMON_PROPS } from "constants/material-react-table";

const localStorageHandler = new LocalStorageHandler();

interface TaskListPageTableProps {
  rows: TaskDto[];
}

/*
Date Range Filter について:
https://github.com/KevinVandy/material-react-table/discussions/13
https://github.com/KevinVandy/material-react-table/discussions/262
https://www.material-react-table.com/docs/examples/advanced
*/

type DateStringExtractor<T> = (data: T) => string | null;

type TData = Record<string, any>;
type MRTFilterParams = {
  column: MRT_Column<any>;
  header: MRT_Header<any>;
  table: MRT_TableInstance<any>;
};
type MRTCellParams = {
  cell: MRT_Cell<any>;
  column: MRT_Column<any>;
  row: MRT_Row<any>;
  table: MRT_TableInstance<any>;
};
interface PaginationState {
  pageIndex: number;
  pageSize: number;
}

const hasDate = <T extends TData,>(row: MRT_Row<T>, filterValue: Date, extractor: DateStringExtractor<T>) => {
  const comparisonTarget = extractor(row.original);

  const date = formatDate(new Date(filterValue));
  console.debug('hasDate', { comparisonTarget, date });
  return comparisonTarget === date;
};

/**
 * filterFn: betweenInclusive
 */
const inDateRange = <T extends TData,>(row: MRT_Row<T>, range: DateRange, extractor: DateStringExtractor<T>) => {
  const comparisonTarget = extractor(row.original);

  const { from, to } = range;

  const fromCheck = !from || !comparisonTarget || (comparisonTarget >= formatDate(new Date(from)));
  const toCheck = !to || !comparisonTarget || (comparisonTarget <= formatDate(new Date(to)));
  console.debug('inDateRange', { comparisonTarget, from, to, fromCheck, toCheck });
  return fromCheck && toCheck;
};

const createDateFilterFn = <T extends TData,>(extractor: DateStringExtractor<T>) => {
  // const filterFn: MRT_FilterFn<any> = (row: MRT_Row<T>, _id: any, filterValue: Date) => {
  const filterFn: any = (row: MRT_Row<T>, _id: any, filterValue: Date) => {
    return hasDate(row, filterValue, extractor);
  };
  return filterFn;
};
const createDateRangeFilterFn = <T extends TData,>(extractor: DateStringExtractor<T>) => {
  const filterFn: any = (row: MRT_Row<T>, _id: any, filterValue: DateRange) => {
    return inDateRange(row, filterValue, extractor);
  };
  return filterFn;
};
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const createDateRangeFilterData = <T extends TData,>(extractor: DateStringExtractor<T>) => {
  // 範囲表示:
  // const range?: DateRange;
  // return (range && range.from && range.to) ? range.from`${range.from || ''} ～ ${range.to || ''}` : '';
  return {
    Cell: ({ cell }: MRTCellParams) => {
      const val = cell.getValue() || '';
      return (
        <Box><>{val}</></Box>
      );
    },
    Filter: ({ column }: MRTFilterParams) => (
      <Box sx={{ overflow: 'auto', width: '100%' }}>
        <DateRangePicker value={column.getFilterValue() as (OptionalDateRange)} onChange={(newValue) => {
          console.debug('createDateRangeFilterData onChange value', newValue);
          return column.setFilterValue(newValue);
        }} />
      </Box>
    ),
    filterFn: createDateRangeFilterFn<T>(extractor),
    maxSize: 400,
    minSize: 50,
    // size: 200, // default size is usually 180
  };
};
const createDateFilterData = <T extends TData,>(extractor: DateStringExtractor<T>) => {
  return {
    muiTableHeadCellFilterTextFieldProps: {
      type: "date",
    },
    filterFn: createDateFilterFn<T>(extractor),
    size: 180,
  };
}

// TASK
// const createTaskDateFilterFn = (extractor: DateStringExtractor<TaskDto>) => createDateFilterFn<TaskDto>(extractor);
// const createTaskDateRangeFilterFn = (extractor: DateStringExtractor<TaskDto>) => createDateRangeFilterFn<TaskDto>(extractor);
// const createTaskDateRangeFilterData = (extractor: DateStringExtractor<TaskDto>) => createDateRangeFilterData<TaskDto>(extractor);
const createTaskDateFilterData = (extractor: DateStringExtractor<TaskDto>) => createDateFilterData<TaskDto>(extractor);

const createTaskDateColumnData = createTaskDateFilterData; // Date check
// const createTaskDateColumnData = createTaskDateRangeFilterData; // Date range check

// // TS2589: Type instantiation is excessively deep and possibly infinite.
const createColumns = (rows: TaskDto[] ) => {
  const columns: MRT_ColumnDef<GenericObject>[] = (
    // const columns = useMemo<MRT_ColumnDef<TaskDto>[]>(
    [
      /*
      {
        accessorKey: "projectManagementTool.data.projectName",
        header: 'プロジェクト名',
        filterVariant: 'text',
        muiTableHeadCellFilterTextFieldProps: { placeholder: "プロジェクト名" },
      },
      */
      {
        // accessorKey: "data.issueKey", // Not working. WHY?
        accessorKey: "id",
        header: 'ID',
        filterVariant: 'text',
        muiTableHeadCellFilterTextFieldProps: { placeholder: "ID" },
      },
      {
        accessorKey: "name",
        header: "タスク",
        filterVariant: "text",
        muiTableHeadCellFilterTextFieldProps: { placeholder: "タスク名" },
        Cell: ({ cell }) => (
          <Link
            target="_blank"
            href={rows.find((a) => a.id === cell.row.original.id)?.taskUrl}
          >
            {cell.getValue<any>().toLocaleString()}
          </Link>
        ),
      },
      {
        accessorKey: "assignee",
        header: "担当者",
        filterVariant: "text",
        muiTableHeadCellFilterTextFieldProps: { placeholder: "担当者名" },
      },
      {
        accessorKey: "status",
        header: "ステータス",
        filterVariant: "text",
        muiTableHeadCellFilterTextFieldProps: {
          placeholder: "ステータス",
        },
      },
      {
        accessorFn: (row) => row.data.category.map((c: any) => c.name).join(','), 
        header: "カテゴリー",
        filterVariant: "text",
        muiTableHeadCellFilterTextFieldProps: {
          placeholder: "カテゴリー",
        },
      },
      {
        accessorKey: "startDate",
        header: "開始日",
        ...createTaskDateColumnData((d) => d.startDate),
      },
      {
        accessorKey: "dueDate",
        header: "期限日",
        ...createTaskDateColumnData((d) => d.dueDate),
      },
      /*
      {
        accessorKey: "data.updated",
        header: "更新日",
        muiTableHeadCellFilterTextFieldProps: {
          type: "date",
        },
        filterFn: createTaskDateFilterFn((d) => d.data.updated),
        size: 180,
      },
      */
      {
        accessorKey: "data.updated",
        header: "更新日",
        ...createTaskDateColumnData((d) => d.data.updated),
      },
    ]
  );
  return columns;
};

const TaskListPageTable = ({ rows }: TaskListPageTableProps) => {
  const columns = useMemo(() => createColumns(rows), [rows]);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: localStorageHandler.data().tasksListTableRowsPerPage,
  });
  useEffect(() => {
    console.debug({ pagination });
    localStorageHandler.set('tasksListTableRowsPerPage', pagination.pageSize);
  }, [pagination])

  return (
    <>
      <MaterialReactTable
        columns={columns}
        data={rows}
        initialState={{ showColumnFilters: true, }}
        state={{
          pagination,
        }}
        onPaginationChange={setPagination}
        {...MRT_COMMON_PROPS}
      />
    </>
  );
};

export default TaskListPageTable;
