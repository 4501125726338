import { useCallback, useEffect, useState } from "react";
import { Title } from "components";
import { Box, Grid } from "@mui/material";
import { useProject } from "hooks";
import AlertsListTable from "./block/AlertsListTable";
import TaskListPageTable from "./block/TaskListPageTable";
import Agenda from "./block/Agenda";
import SearchBox from "./block/SearchBox";
import { toDate, taskToTaskDates, createSetTaskRowsFunc } from "utilities";
import { useAPITasksGET, useAPIAlertsGET } from "api";
import { TaskGetResponseDto } from "types/api";
import { TASK_LIST_SEARCH_FIELDS } from "constants/constants";
import { PROJECT_TASKS_PART_TITLES } from "constants/messages";
import APIGetHandler from "components/APIGetHandler";

/**
 * falsyを返すとまだ準備していないと判断する。
 */
const getRowsFromSearch = (event:  React.FormEvent<HTMLFormElement>, tasks: TaskGetResponseDto[] | null) => {
  // テーブルフィルタークリア
  const clearFilterBtns = document.getElementsByClassName(
    "css-1cyhbc3-MuiButtonBase-root-MuiIconButton-root"
  ) as HTMLCollectionOf<HTMLElement>;
  Object.values(clearFilterBtns).map((clearBtn) => clearBtn.click());
  const clearSearchBtns = document.getElementsByClassName(
    "css-1pe4mpk-MuiButtonBase-root-MuiIconButton-root"
  ) as HTMLCollectionOf<HTMLElement>;
  Object.values(clearSearchBtns).map((clearBtn) => clearBtn.click());

  event.preventDefault();
  const data = new FormData(event.currentTarget);
  const fields = {
    startDate1: data.get(TASK_LIST_SEARCH_FIELDS.START_DATE_1) as string, // 開始日 から
    startDate2: data.get(TASK_LIST_SEARCH_FIELDS.START_DATE_2) as string, // 開始日 まで
    dueDate1: data.get(TASK_LIST_SEARCH_FIELDS.DUE_DATE_1) as string, // 期限日 から
    dueDate2: data.get(TASK_LIST_SEARCH_FIELDS.DUE_DATE_2) as string, // 期限日 まで
    updated1: data.get(TASK_LIST_SEARCH_FIELDS.UPDATED_1) as string, // 更新日 から
    updated2: data.get(TASK_LIST_SEARCH_FIELDS.UPDATED_2) as string, // 更新日 まで
    keyword: data.get(TASK_LIST_SEARCH_FIELDS.KEYWORD) as string, // キーワード
  };
  const { dueDate1, dueDate2, keyword, startDate1, startDate2, updated1, updated2 } = fields;

  /*
   * 検索する日付変換処理
   */
  const toSearchData = (searchDate?: string) => {
    if (searchDate) {
      return new Date(searchDate.replace(/-/g, "/")); // 置換 yyyy-mm-dd→yyyy/mm/dd
    } else {
      return null;
    }
  };

  /**
   * 日付絞り込み処理
   * 各チェックは必要なfrom/to/dateがある場合、失敗の条件をチェック、失敗の場合、falseを返す。
   */
  const filterDate = (
    from: Date | null,
    to: Date | null,
    date: Date | null
  ) => {
    // yyyy/mm/dd～yyyy/mm/dd
    if (from && to && date && (from > date || to < date)) {
      return false;
    }
    // yyyy/mm/dd～
    if (from && date && !startDate2 && from > date) {
      return false;
    }
    // ～yyyy/mm/dd
    if (to && date && !startDate1 && to < date) {
      return false;
    }
    // 期限設定されていて、期限日がない場合
    if ((from || to) && !date) {
      return false;
    }
    return true;
  };

  if (!tasks) {
    return;
  }

  // 検索フィールドに入力されている場合date型
  const search_startDate1 = toSearchData(startDate1);
  const search_startDate2 = toSearchData(startDate2);
  const search_dueDate1 = toSearchData(dueDate1);
  const search_dueDate2 = toSearchData(dueDate2);
  const search_updated1 = toSearchData(updated1);
  const search_updated2 = toSearchData(updated2);
  console.debug('getRowsFromSearch search fields', {
    search_startDate1,
    search_startDate2,
    search_dueDate1,
    search_dueDate2,
    search_updated1,
    search_updated2,
  });

  //タスク一覧を絞り込み
  const response = tasks.filter((task) => {
    //日付変換
    const { startDate, dueDate, updated } = taskToTaskDates(task);
    // データが入っている場合date型変換
    const data_startDate = toDate(startDate);
    const data_dueDate = toDate(dueDate);
    const data_updated = toDate(updated);
    //絞り込み検索
    //検索窓に入力値が無い場合すべて取得
    if (!(Object.values(fields).some((field) => !!field))) {
      return true;
    }
    //日付絞り込み処理
    if (!filterDate(search_startDate1, search_startDate2, data_startDate)) {
      return false;
    }
    if (!filterDate(search_dueDate1, search_dueDate2, data_dueDate)) {
      return false;
    }
    if (!filterDate(search_updated1, search_updated2, data_updated)) {
      return false;
    }
    // キーワード検索
    if (keyword && task.name.indexOf(keyword) === -1) {
      return false;
    }
    return task;
  });
  // 日付変換
  const result = response.map((res) => {
    const { startDate, dueDate, updated } = taskToTaskDates(res);
    return {
      ...res,
      startDate: startDate,
      dueDate: dueDate,
      data: { 
        updated: updated,
        category: res.data.category,
       },
    };
  });
  return result;
};

const TaskListPage = () => {
  const { project, projectId } = useProject();
  console.debug('TaskListPage', { projectId });

  // タスク一覧取得処理
  const { data: tasks, execute: executeTasks, isLoading: isLoadingTasks, errorMessage: tasksErrorMessage } = useAPITasksGET();

  // 要注意タスク一覧取得処理
  const { data: alerts, execute: executeAlerts, isLoading: isLoadingAlerts, errorMessage: alertsErrorMessage } = useAPIAlertsGET();
  
  const isLoading = isLoadingTasks || isLoadingAlerts;
  const errorMessage = tasksErrorMessage || alertsErrorMessage;
  const execute = useCallback(() => {
    executeTasks({ projectId });
    executeAlerts({ projectId });
  }, [executeTasks, executeAlerts, projectId]);

  useEffect(() => {
    console.debug('TaskListPage:useEffect', { projectId });
    execute();
  }, [projectId, execute]);

  // テーブルの行
  const [rows, setRows] = useState<(TaskGetResponseDto[] | null)>();

  useEffect(() => {
    setRows(tasks);
  }, [tasks]);

  /*
   * APIデータ更新ごとに日付を変換
   */
  useEffect(() => {
    if (tasks) {
    // if (tasks && rows) {
      tasks.forEach((task) => {
        setRows(createSetTaskRowsFunc(task));
      });
    }
  }, [tasks]);

  /*
   * 絞り込み検索処理
   */
  const handleSearch = async (event: React.FormEvent<HTMLFormElement>) => {
    const result = getRowsFromSearch(event, tasks);
    if (!result) {
      console.warn('まだ準備していない');
      return;
    }
    setRows(result);
  };

  if (isLoading || errorMessage || !projectId || !rows || !alerts || !project) {
    return <APIGetHandler settings={{ name: 'TaskListPage2', isLoading, errorMessage, retry: () => execute() }} />
  }

  const projectName = project.name;

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Title title={projectName} icon="bag" />
      </Box>
      <Box sx={{ display: "flex" }}>
        <Grid container>
          <Grid item xs={12} sm={6} md={4}>
            <AlertsListTable
              alerts={alerts}
              projectId={projectId}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={8}>
            <SearchBox onSubmit={handleSearch} />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ display: "flex", mt: 2 }}>
        <Title title={PROJECT_TASKS_PART_TITLES.TASK_LIST} icon="list" size="subtitle1" />
        <Agenda alerts={alerts} projectName={projectName} />
      </Box>
      <TaskListPageTable rows={rows} />
    </>
  );
};

export default TaskListPage;
