import React from "react";
import { BasicTable, Title, CustomLinkButton } from "components";
import { TableRow, TableCell, Box } from "@mui/material";
import { DYNAMIC_ROUTES } from "constants/routes";
import { AlertsGetResponseDto } from "types/api";
import { ALERTS_SETTINGS, HEADERS, IDS } from "constants/constants";
import { PROJECT_TASKS_PART_TITLES } from "constants/messages";

const headers = [
  HEADERS.STATE,
  HEADERS.TASK_COUNT,
];
interface RowProps {
  name: string;
  number: number;
}

interface RowsProps {
  row: RowProps;
}

interface AlertsListTableProps {
  alerts: AlertsGetResponseDto;
  projectId: string;
}

const Row = ({ row }: RowsProps) => {
  return (
    <TableRow sx={{ "&:last-child td": { border: 0 } }}>
      <TableCell align="center">{row.name}</TableCell>
      <TableCell align="center">{row.number}</TableCell>
    </TableRow>
  );
};

const AlertsListTable = ({
  alerts,
  projectId,
}: AlertsListTableProps) => {
  const alertsData = ALERTS_SETTINGS.map((item) => ({
    name: item.name,
    number: alerts[item.value].length,
  }));
  return (
    <Box sx={{ overflow: 'auto' }}>
      <Box
        sx={{
          // height: "50px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Title title={PROJECT_TASKS_PART_TITLES.ALERTS} icon="alert" size="subtitle1" />
        <CustomLinkButton
          id={IDS.CHECK_ALERTS_BUTTON}
          text="確認する"
          variant="contained"
          icon="check"
          to={DYNAMIC_ROUTES.PROJECT_ALERTS(projectId)}
        />
      </Box>
      <BasicTable headers={headers}>
        {alertsData.map((alertData: RowProps, index: number) => (
          <Row key={`alert-${index}`} row={alertData} />
        ))}
      </BasicTable>
    </Box>
  );
};

export default AlertsListTable;
