import React from "react";
import { SxProps, Theme } from "@mui/material/styles";
import { InputLabel, MenuItem, FormControl, Grid } from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { HeaderType, SelectType } from "types";
import { getRandomId } from "utilities";

interface SelectProps {
  selects: Array<SelectType> | undefined;
  item: HeaderType;
  required?: boolean;
  sx?: SxProps<Theme>;
  variant?: "outlined" | "standard" | "filled" | undefined;
  fullWidth?: boolean;
  value?: string | null;
  setValue?: (e: string) => void;
}

const BasicSelect = ({
  selects,
  item,
  required = false,
  sx,
  variant = "outlined",
  fullWidth = true,
  value,
  setValue,
}: SelectProps) => {
  const handleChange = (event: SelectChangeEvent) => {
    if (setValue) {
      setValue(event.target.value);
    }
  };

  const labelId = getRandomId('select-label');
  return (
    <Grid container sx={sx ? sx : {}}>
      <FormControl
        variant={variant}
        required={required ? true : false}
        fullWidth={fullWidth ? true : false}
        sx={{
          mb: 1,
        }}
      >
        <InputLabel id={labelId}>{item.name}</InputLabel>
        <Select
          labelId={labelId}
          id={item.value}
          name={item.value}
          value={value ? value : ""}
          label={item.name}
          onChange={handleChange}
        >
          {selects &&
            selects.map((select, index: number) => (
              <MenuItem key={`select-${index}`} value={select.id}>
                {select.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </Grid>
  );
};

export default BasicSelect;
