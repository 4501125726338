import { Box, TextField } from "@mui/material";
import { useAPIProjectPUT } from "api";
import { CustomButton, Loading } from "components";
import { LABELS } from "constants/constants";
import { useState } from "react";
import { ProjectPutRequestDto, ProjectsGetResponseDto } from "types/api";

const projectNameLabel = {
    ...LABELS.PROJECT_NAME,
    required: true,
};

const ProjectSettings = ({ project, onUpdate }: { project: ProjectsGetResponseDto, onUpdate?: () => void }) => {

    const { mutate, isLoading } = useAPIProjectPUT();
    const [dataToUpdate, setDataToUpdate] = useState<ProjectPutRequestDto>({ name: project.name });

    const onSubmit = () => {
      const projectId = project.id;
      mutate({ projectId, params: dataToUpdate }).then((response) => {
        if (response.success) {
          if (onUpdate) onUpdate();
        }
      });
    };

    if (isLoading) {
      return (
        <Loading name="ProjectSettings" />
      );
    }

    return (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: 'center',
              '> *': { margin: '10px' }
            }}
          >
            <TextField
              sx={{ mb: 1, minWidth: '200px', width: '60%' }}
              required={projectNameLabel.required}
              id={projectNameLabel.value}
              label={projectNameLabel.name}
              value={dataToUpdate.name}
              onChange={(ev) => setDataToUpdate({ ...dataToUpdate, ...{ name: ev.target.value }})}
            />
            <span style={{ flexGrow: 1 }}>
              <CustomButton
                text={'更新'}
                sx={{ }}
                onClick={onSubmit}
              />
            </span>
          </Box>
        </>
      );
};
export default ProjectSettings;
