import { Box } from "@mui/material";
import { markdownToHTML } from "utilities";

const MarkdownComponent = ({ value, pre } : { value: string; pre?: boolean }) => {
  const markdown = markdownToHTML(value);
  return (
      <Box component={pre ? 'pre' : 'div'} dangerouslySetInnerHTML={{ __html: markdown }}></Box>
  );
};

export default MarkdownComponent;
