import { FormModal } from "components";
import { LABELS } from "constants/constants";
import { useState } from "react";
import { SubmitHandler } from "react-hook-form";
import { BookmarkFormData, FormInputType, FormType } from "types";
/*
parent: string;
url: string | null;
name: string;
order: number;
*/
const BOOKMARK_DIRECTORY_LABELS = [
  LABELS.NAME,
];
const BOOKMARK_ITEM_LABELS = [
  LABELS.NAME,
  LABELS.URL,
];

const BookmarkModal = ({ data, open, onSubmit, onClose }: { data: BookmarkFormData; open: boolean; onSubmit: (data: BookmarkFormData) => void; onClose: () => void }) => {
  // const [errorMessage, setErrorMessage] = useState(''); // API共通エラー対応で十分なはずです。

  const isDirectory = data.data.url === null;

  const values = data.data;
  console.debug('BookmarkModal', { data, values });
  const handleSubmit = (values: BookmarkFormData['data']) => {
    console.debug('handleSubmit', { values: JSON.stringify(values), data: JSON.stringify(data) });
    const { data: initialData, ...fixed } = data;
    const bookmarkFormData: BookmarkFormData = {
      data: { ...initialData, ...values},
      // data: values,
      ...(fixed as any),
    };
    onSubmit(bookmarkFormData);
  };

  return open ?
    <FormModal
      title={isDirectory ? 'ブックマークフォルダー登録' : 'ブックマーク登録'}
      buttonText="登録"
      open={open}
      onSubmit={handleSubmit as any}
      onClose={onClose}
      labels={isDirectory ? BOOKMARK_DIRECTORY_LABELS : BOOKMARK_ITEM_LABELS}
    /> : <></>;
    // values={values}
    // error={errorMessage}
};

export default BookmarkModal;
