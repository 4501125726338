import { createContext, useContext } from 'react';
import { createGlobalSnackbarState, GlobalSnackbarState } from './GlobalSnackbar';

interface GlobalSnackbarContext {
  globalSnackbar: GlobalSnackbarState;
  setGlobalSnackbar: (d: GlobalSnackbarState) => any;
}
export const SnackbarContext = createContext<GlobalSnackbarContext>({  } as GlobalSnackbarContext);

export const useAPIErrorMessage = () => {
  const { globalSnackbar, setGlobalSnackbar } = useContext(SnackbarContext);
  const setMessage = (message: string) => setGlobalSnackbar({ message, severity: 'error' });
  return [globalSnackbar.message, setMessage] as const;
};

export const useAPISuccessMessage = () => {
  const { globalSnackbar, setGlobalSnackbar } = useContext(SnackbarContext);
  const setMessage = (message: string) => setGlobalSnackbar({ message, severity: 'success' });
  return [globalSnackbar.message, setMessage] as const;
};
