import React from "react";
import { CircularProgress, Box } from "@mui/material";
import { DATA_CLASSES } from "constants/constants";

const Loading = ({ name } : { name?: string }) => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        my: 5,
      }}
    >
      <span className={DATA_CLASSES.LOADING_SPINNER}  style={{ visibility: 'hidden' }} data-name={name || ''} />
      <CircularProgress />
    </Box>
  );
};

export default Loading;
