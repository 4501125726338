import { Theme, ThemeOptions } from "@mui/material";

export const FORM_MODAL_CLOSE_BUTTON_COLOR = (theme: Theme) => theme.palette.grey[500];
export const FORM_MODAL_ERROR_TEXT_COLOR = "#d32f2f";
export const MAIN_COMPONENT_BACKGROUND_COLOR = (theme: Theme) =>
theme.palette.mode === "light"
  ? theme.palette.grey[100]
  : theme.palette.grey[900];
export const MODAL_ERROR_TITLE_COLOR = "#d32f2f";
export const MODAL_CLOSE_BUTTON_COLOR = (theme: Theme) => theme.palette.grey[500];
export const QUESTION_BOX_LABEL_COLOR = "#1976d2";
export const QUESTION_BOX_ERROR_COLOR = "#d32f2f";
export const CHART_BACKGROUND_COLOR = "rgba(84,121,169,0.3)";
export const CHART_BORDER_COLOR = "#5479A9";
export const SURVEY_RESULTS_PAGE_BOX_BACKGROUND_COLOR = "rgba(84,121,169,0.3)";
export const SURVEY_RESULTS_PAGE_BOX_BORDER_COLOR = "#5479A9";
export const BASIC_TABLE_BORDER_BOTTOM_COLOR = "rgba(224, 224, 224, 1)";

/**
 * @see https://mui.com/material-ui/customization/default-theme/
 */
export const THEME_OPTIONS: ThemeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: '#0c0c0c',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#f2c817',
      contrastText: '#0c0c0c',
    },
    info: { // CustomLinkButtonに利用
      main: "#0288d1"
    }
  }
};

const DESK_STATUS_PREFIX = 'status-label-';
export const DESK_STATUSES = {
  'not-planned': `${DESK_STATUS_PREFIX}not-planned`,
  'closed': `${DESK_STATUS_PREFIX}closed`,
  'pending': `${DESK_STATUS_PREFIX}pending`,
  'pending-moderation': `${DESK_STATUS_PREFIX}pending-moderation`,
  'open': `${DESK_STATUS_PREFIX}open`,
  'solved': `${DESK_STATUS_PREFIX}solved`,
  'new': `${DESK_STATUS_PREFIX}new`,
  'hold': `${DESK_STATUS_PREFIX}hold`,
} as const;

export const LARGE_FORM_MODAL_STYLES = {
  width: 800, // 「横幅を2倍くらい」: src\components\FormModal\index.tsx の400*2
  maxWidth: '90%',
  maxHeight: '95%',
  overflow: 'auto',
};

export const MODAL_STYLE = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 400,
  minWidth: 400,
  maxWidth: "70%",
  bgcolor: "background.paper",
  boxShadow: 24,
  overflow: 'auto',
} as const;
