import React from "react";
import { Title, CustomLinkButton, Text } from "components";
import { Box, Card } from "@mui/material";
import { DYNAMIC_ROUTES } from "constants/routes";
import { SurveyDto } from "types/api";
import { DATA_CLASSES } from "constants/constants";

interface SurveysBoxProps {
  survey: SurveyDto;
}

const SurveysBox = ({ survey }: SurveysBoxProps) => {
  const { id, name, summary, alias } = survey;
  // const link = DYNAMIC_ROUTES.SURVEY(id);
  const link = DYNAMIC_ROUTES.SURVEY(alias);

  return (
    <Card
      sx={{
        display: "flex",
        alignItems: "center",
        flexFlow: "column",
        width: "100%",
        height: "100%",
      }}
    >
      <Title title={name} size="subtitle1" bold={true} />
      <Text text={summary.split('\n')} />
      <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column-reverse' }}>
        <Box sx={{ alignSelf: 'center' }}>
          <CustomLinkButton 
            text="診断する" 
            to={link} 
            className={DATA_CLASSES.SURVEY_ITEM_LINK}
            sx={{mt: "10px", mb: "20px"}}
          />
        </Box>
      </Box>
    </Card>
  );
};

export default SurveysBox;
