import React from "react";
import { SxProps, Theme } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";

interface TextProps {
  text: string | string[];
  size?: "h1" | "caption" | "subtitle1";
  fontWeight?: string;
  textAlign?: string;
  justifyContent?: string;
  sx?: SxProps<Theme>;
}

const Text = ({
  text,
  size = "caption",
  fontWeight = "unset",
  textAlign = "left",
  justifyContent = "left",
  sx,
}: TextProps) => {
  return (
    <Box
      sx={
        sx
          ? sx
          : {
              display: "flex",
              alignItems: "center",
              justifyContent: { justifyContent },
              my: 1,
              mr: 1,
            }
      }
    >
      <Typography
        sx={{
          fontWeight: { fontWeight },
          textAlign: { textAlign },
          ml: "5px",
        }}
        variant={size}
      >
        {
          typeof text === 'string' ? text : text.map((t, index) => <React.Fragment key={index}>{t}<br /></React.Fragment>)
        }
      </Typography>
    </Box>
  );
};

export default Text;
