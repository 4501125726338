import { useBacklogAPIStatusesGET } from "api";
import { ToolGetResponseDto } from "types/api";
import { Status } from "./CompleteStatusesCommon";

const createBacklogStatusesHandler = (tools: ToolGetResponseDto[], execute: ReturnType<typeof useBacklogAPIStatusesGET>['execute']) => {
  class BacklogStatusesHandler {
    onError: (error: Error) => void;
    constructor(onError: (error: Error) => void) {
      this.onError = onError;
    }
    async getBacklogStatuses (tool: ToolGetResponseDto) {
      const { tenantUrl, projectId, apiKey } = tool.data;
      // const response = await mutateBacklog({ tenantUrl, projectId, apiKey });
      const response = await execute({ tenantUrl, projectId, apiKey }, true);
      if (!response) throw new Error('Not executed');
      if (response.success) {
        console.log(response.data);
        return response.data;
      } else {
        this.onError(new Error(`通信に失敗しました。\n入力値を確認してください。`));
        return [] as any[];
      }
    }
    async getStatuses (tool: ToolGetResponseDto): Promise<Status[]> {
      if (tool.type === 'backlog') {
        const backlogStatuses = await this.getBacklogStatuses(tool);
        return backlogStatuses.map((s) => ({
          id: String(s.id),
          name: s.name,
        }));
      } else {
        return [] as Status[];
      }
    }
    async getStatusesMap () {
      const statusesMap: Record<string, Status[]> = {};
      const promises = tools.map(async (tool) => {
          const statuses = await this.getStatuses(tool);
          statusesMap[tool.id] = statuses;
      });
      await Promise.all(promises);
      return statusesMap;
    }
  }

  return BacklogStatusesHandler;
};

const useBacklogAPIStatuses = (tools: ToolGetResponseDto[]) => {
  // const { mutate: mutateBacklog, isLoading } = useBacklogAPIStatusesGET(); // backlogApi通信
  const { execute, isLoading } = useBacklogAPIStatusesGET(); // backlogApi通信
  
  return {
    BacklogStatusesHandler: createBacklogStatusesHandler(tools, execute),
    isLoading
  };
};
export default useBacklogAPIStatuses;
