import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { Loading, CustomButton, Text, BasicSelect } from "components";
import { ROUTES } from "constants/routes";
import { useAPIProjectDiagnosesPOST, useAPIProjectsGET } from "api";

interface SaveBoxProps {
  diagnosisId: string;
  projectId?: string;
}

const SaveBox = ({ diagnosisId, projectId }: SaveBoxProps) => {
  const [dynamicProjectId, setProject] = useState(projectId ? projectId : "");
  //プロジェクト取得処理
  const { data: projects, execute } = useAPIProjectsGET();
  useEffect(() => {
    execute({});
  }, []);
  console.log(projects);

  /*
   * 診断結果登録
   */
  const { mutate: mutatePost } = useAPIProjectDiagnosesPOST();
  const navigate = useNavigate();
  // 保存処理
  const handleSave = async () => {
    await mutatePost({
      projectId: dynamicProjectId,
      params: {
        id: diagnosisId,
      },
    });
    setProject("");
    navigate(ROUTES.SURVEYS_HISTORY);
  };

  if (!projects) {
    return <Loading name="SaveBox" />;
  }
  return (
    <Box sx={{ display: "flex", alignItems: "center", my: 2 }}>
      <Text
        text="診断結果を保存する"
        size="subtitle1"
        sx={{ width: "160px" }}
      />
      <BasicSelect
        selects={projects}
        item={{
          name: "プロジェクトを選択してください。",
          value: "projectSelect",
        }}
        sx={{
          width: "calc(100% - 160px - 110px)",
          minWidth: '200px',
          "& .MuiFormControl-root": { mb: 0 },
        }}
        value={dynamicProjectId}
        setValue={setProject}
      />
      <CustomButton
        text="保存する"
        sx={{ ml: 1, my: 1, width: "110px" }}
        onClick={() => {
          handleSave();
        }}
      />
    </Box>
  );
};

export default SaveBox;
