import React from "react";
import { Link } from "react-router-dom";
import { Button, Typography } from "@mui/material";
import RocketIcon from "@mui/icons-material/Rocket";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { SxProps, Theme } from "@mui/material/styles";
import "./index.css";
import { Bookmarks } from "@mui/icons-material";

type IconName = 'rocket' | 'addCircle' | 'check' | 'bookmarks';
interface LinkButtonProps {
  text: string;
  to: string;
  id?: string;
  className?: string;
  onClick?: () => void,
  color?:
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning";
  variant?: "contained" | "outlined" | "text";
  icon?: IconName;
  textAlign?: "left" | "center";
  sx?: SxProps<Theme>;
}

const ICON_SX = { mr: 1 };
const Icon = ({ icon }: { icon?: IconName }) => {
  switch (icon) {
    case "rocket":
      return <RocketIcon sx={ICON_SX} />;
    case "addCircle":
      return <AddCircleIcon sx={ICON_SX} />;
    case "check":
      return <CheckCircleIcon sx={ICON_SX} />;
    case "bookmarks":
      return <Bookmarks sx={ICON_SX} />;
    default:
      return <></>;
  }
};
const DEFAULT_BUTTON_SX = { my: 1 };

const CustomLinkButton = ({
  text,
  to,
  id,
  className,
  onClick,
  color = "secondary",
  variant = "contained",
  icon,
  textAlign = "center",
  sx,
}: LinkButtonProps) => {
  return (
    <Button
      id={id ? id : undefined}
      className={className ? className : undefined}
      color={color}
      variant={variant}
      sx={sx ? sx : DEFAULT_BUTTON_SX}
      component={Link}
      to={to}
      onClick={onClick}
    >
      {icon && <Icon icon={icon} />}
      <Typography textAlign={textAlign}>{text}</Typography>
    </Button>
  );
};

export default CustomLinkButton;
