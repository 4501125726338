import InvalidRoleTooltipContent from "components/InvalidRoleTooltipContent";
import { LICENSED_ROLES } from "constants/constants";
import { ACADEMY_COURSES_TITLE, DANDLE_TITLE, DESK_TITLE, PROJECT_SETTINGS_TITLE, PROJECT_TASKS_PART_TITLES, SURVEYS_HISTORY_TITLE, SURVEYS_LIST_TITLE, SURVEYS_TITLE } from "constants/messages";
import { ROUTES } from "constants/routes";
import { RoleKey, RouteItem } from "types";
import { hasRequiredRoles } from "utilities";

// const ROUTE_ITEMS = {
const ROUTE_ITEMS: Record<string, RouteItem> = {
  LOGGED_OUT_HOME: {
    path: ROUTES.HOME,
    text: '',
    icon: "Default",
    requiredRoles: ['guest'],
    route: 'LoggedOutHome',
  },
  LOGGED_IN_HOME: {
    path: ROUTES.HOME,
    text: '',
    icon: "Default",
    requiredRoles: ['free'],
    route: 'LoggedInHome',
  },
  PROJECT_LIST: {
    path: ROUTES.HOME,
    text: DANDLE_TITLE,
    icon: "List",
    requiredRoles: ['dandle'],
    route: 'LoggedInAndLicensedHome',
  },
  /*
  DASHBOARD: {
    path: ROUTES.DASHBOARD,
    text: "ダッシュボード",
    icon: "dashboard",
    requiredRoles: ['dandle'],
  },
  */
  TASKS: {
    path: ROUTES.PROJECT_TASKS,
    text: PROJECT_TASKS_PART_TITLES.TASK_LIST,
    icon: "ViewList",
    requiredRoles: ['dandle'],
    route: 'TaskListPage',
  },
  /*
  GANT_CHART: {
    path: "#",
    text: "ガントチャート",
    icon: "sort",
  },
  */
  /*
  CALENDAR: {
    path: "/calendar",
    text: "カレンダー",
    icon: "calendar_month",
  },
  */
  /*
  MILESTONES: {
    path: "#",
    text: "マイルストーン",
    icon: "flag",
  },
  */
  PROJECT_SETTINGS: {
    path: ROUTES.PROJECT_SETTINGS,
    text: PROJECT_SETTINGS_TITLE,
    icon: "Settings",
    requiredRoles: ['dandle'],
    route: 'ProjectSettingPage',
  },
  PROJECT_ALERTS: {
    path: ROUTES.PROJECT_ALERTS,
    text: 'Alerts',
    icon: "Default",
    requiredRoles: ['dandle'],
    route: 'AlertsPage',
  },
  BOOKMARKS: {
    path: ROUTES.BOOKMARKS,
    text: 'ブックマーク一覧',
    icon: 'Bookmarks',
    requiredRoles: ['dandle'],
    route: 'BookmarksPage',
  },
  SURVEYS: {
    path: ROUTES.SURVEYS,
    text: SURVEYS_LIST_TITLE,
    icon: "List",
    requiredRoles: [],
    route: 'SurveysPage',
  },
  SURVEYS_QUESTIONS: {
    path: ROUTES.SURVEY,
    text: SURVEYS_LIST_TITLE,
    icon: "List",
    requiredRoles: [],
    route: 'SurveysQuestionsPage',
  },
  SURVEYS_HISTORY: {
    path: ROUTES.SURVEYS_HISTORY,
    text: SURVEYS_HISTORY_TITLE,
    icon: "WorkHistory",
    requiredRoles: ['free'],
    route: 'SurveysHistoryPage',
  },
  SURVEY_RESULTS_PAGE: {
    path: ROUTES.SURVEY_DIAGNOSIS,
    text: '',
    icon: "Default",
    requiredRoles: ['free'],
    route: 'SurveysResultsPage',
  },
  SURVEY_RESULTS_WITH_NO_ID_PAGE: {
    path: ROUTES.SURVEY_DIAGNOSIS_NO_ID,
    text: '',
    icon: "Default",
    requiredRoles: ['guest'],
    route: 'SurveysResultsWithNoIdPage',
  },
  ACADEMY_COURSES: {
    path: ROUTES.ACADEMY_COURSES,
    text: ACADEMY_COURSES_TITLE,
    icon: "School",
    requiredRoles: [],
    route: 'AcademyPage',
  },
  ACADEMY_PLAY_PAGE: {
    path: ROUTES.ACADEMY_COURSE,
    text: '',
    icon: "Default",
    requiredRoles: [],
    route: 'AcademyPlayPage',
  },
  DESK: {
    path: ROUTES.DESK_LIST,
    text: DESK_TITLE,
    icon: 'Desk',
    requiredRoles: ['desk'],
    route: 'DeskListPage',
  },
  DESK_ITEM: {
    path: ROUTES.DESK_ITEM,
    text: '',
    icon: 'Default',
    requiredRoles: ['desk'],
    route: 'DeskItemPage',
  }
  /*
  WALL: {
    path: ROUTES.WALL_ENTRANCE,
    text: WALL_TITLE,
    icon: 'AirlineStops',
    requiredRoles: [],
    route: 'WallPage',
  }
  */
// } as const;
};

const GROUPED_ROUTE_ITEMS = {
  DANDLE: {
    path: ROUTES.HOME,
    text: DANDLE_TITLE,
    icon: "TrendingUp",
    requiredRoles: ['dandle'],
    items: [
      ROUTE_ITEMS.TASKS,
      ROUTE_ITEMS.BOOKMARKS,
      ROUTE_ITEMS.PROJECT_SETTINGS,
    ],
  },
  ACADEMY: ROUTE_ITEMS.ACADEMY_COURSES,
  SURVEY: {
    path: ROUTES.SURVEYS,
    text: SURVEYS_TITLE,
    icon: 'ContentPaste',
    requiredRoles: [],
    items: [
      ROUTE_ITEMS.SURVEYS,
      ROUTE_ITEMS.SURVEYS_HISTORY,
    ],
  },
  DESK: ROUTE_ITEMS.DESK,
  // WALL: ROUTE_ITEMS.WALL,
} as const;

const LEFT_NAVI_ROUTE_ITEMS = [
  GROUPED_ROUTE_ITEMS.DANDLE,
  GROUPED_ROUTE_ITEMS.ACADEMY,
  GROUPED_ROUTE_ITEMS.SURVEY,
  GROUPED_ROUTE_ITEMS.DESK,
  // GROUPED_ROUTE_ITEMS.WALL,
];

type Params = Record<string, string | undefined>;

const replacePathKeys = (path: string, params: Params) => {
  Object.entries(params).forEach(([k, v]) => {
    if (v !== undefined) {
      path = path.replace(`:${k}`, v);
    }
  });
  return path;
};

export const parseParams = (routeItem: RouteItem, params: Params = {}) => {
  const toPart = routeItem.path ? { to: replacePathKeys(routeItem.path, params) } : {};
  return { ...routeItem, ...toPart };
};

const createDisabledListItem = (item: RouteItem, unauthorized: boolean): RouteItem => {
  // New reference
  return {
    ...item,
    disabled: true,
    tooltip: unauthorized ? <InvalidRoleTooltipContent /> : undefined,
  };
};

/**
 * rolesの優先度(低 => 高): guest => free => other => paid
 */
const getRoleDisplayPriorityWeight = (role: RoleKey) => {
  const DEFAULT_WEIGHT = 3;
  const WEIGHTS: { weight: number; check: () => boolean }[] = [
    { weight: 1, check: () => role === 'guest' },
    { weight: 2, check: () => role === 'free' },
    // { weight: DEFAULT_WEIGHT }, // DEFAULT
    { weight: 4, check: () => LICENSED_ROLES.includes(role) },
  ];

  return WEIGHTS.filter((item) => item.check()).map((item) => item.weight)[0] || DEFAULT_WEIGHT;
};

const getRouteItemDisplayPriorityWeight = (item: RouteItem) => {
  return Math.max(...item.requiredRoles.map(getRoleDisplayPriorityWeight));
};

/**
 * 重複のtoは不可にする。
 * getRoleDisplayPriorityWeight(role)で区別、まだ重複あれば、最初の項目。
 */
const parseItems = (routeItems: RouteItem[], roles: RoleKey[], params: Params = {}): RouteItem[] => {
  let items = (routeItems).map((item) => {
    item = parseParams(item, params);

    // CHECKS
    const rolesMatch = hasRequiredRoles(roles, item.requiredRoles);
    // const hasUnparsedPathKey = routeItemHasUnparsedPathKey(item);
    const unauthorized = !rolesMatch;
    const disabled = unauthorized;

    // DEBUG
    // if (hasUnparsedPathKey) console.debug('hasUnparsedPathKey', { item, params });

    return {
      item,
      disabled,
      unauthorized,
    };
  });
  console.debug('parseItems before filtering', { items: [...items] });
  // 重複検出・filtering
  items = items.filter((item, index) => {
    const duplicates = items
      .filter((i) => item !== i && i.item.to && i.item.to === item.item.to) // 違う項目、同じto
      .filter((i) => !i.disabled); // 無効のものは無視
    /*
    重複があれば、この項目はほかの重複項目よりいいかどうか。
    */
    if (duplicates.length > 0) {
      const weights = duplicates.map((i) => getRouteItemDisplayPriorityWeight(i.item));
      const maxOtherWeight = Math.max(...weights);
      const minOtherIndex = Math.max(...(duplicates.map((i) => items.indexOf(i))));
      const thisWeight = getRouteItemDisplayPriorityWeight(item.item);
      if (thisWeight > maxOtherWeight) {
        return true;
      } else if (thisWeight === maxOtherWeight && index < minOtherIndex) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  });

  // WITH nested disabled対応
  return items.map((i) => {
    const { item, disabled, unauthorized } = i;
    const data: RouteItem = {
      ...item,
      items: item.items ? parseItems(item.items, roles, params) : undefined,
    };
    return disabled ? createDisabledListItem(data, unauthorized) : data;
  });
  // return items.filter((item) => item.rolesMatch).map((item) => item.item); // FILTER
};

/**
 * 全項目を取得。主にテスト用。
 */
export const getAllItems = () => [
  ...Object.values(ROUTE_ITEMS),
  // NESTED: getDefaultItemsを利用。
];

export const getRouteItemsByRequiredRoles = (roles: RoleKey[], params: Params = {}) => {
  return parseItems(getAllItems() as any as RouteItem[], roles, params);
};

const getLeftNaviRouteItemsByRequiredRoles = (roles: RoleKey[], params: Params = {}) => {
  const items = parseItems(LEFT_NAVI_ROUTE_ITEMS as any as RouteItem[], roles, params);
  return items;
};

/**
 * ページが存在しない場合の項目一覧。
 */
export const getNoPageItems = () => {
  console.debug('getNoPageItems');
  return [
    ...(getLeftNaviRouteItemsByRequiredRoles(['guest'])),
  ];
};

/**
 * 一つのパターンを使う場合はこれを使う。
 */
export const getDefaultItems = (roles: RoleKey[], params: Params) => getLeftNaviRouteItemsByRequiredRoles(roles, params);
