import { checkIsTruthy as t } from 'utilities';

/**
 * 共通パラメーター統一:
 * projectId: id
 * surveyId: id
 * diagnosisId: diagnosisId
 */
export const API_ROUTES = {
    PROJECTS: '/projects',
    PROJECTS_WITH_ALERTS: '/projects/withAlerts',
    ACADEMY_COURSES: '/academy15/courses',
    TASKS: '/projects/:projectId/tasks',
    ALERTS: '/projects/:projectId/tasks/alerts',
    TOOLS: '/projects/:projectId/tools',
    TOOLS_SETTINGS: '/projects/:projectId/tools/:toolId/settings',
    NOTIFICATIONS: '/projects/:projectId/notifications',
    MEMBERS: '/projects/:projectId/members',
    ROUTINES: '/projects/:projectId/routines',
    MEETINGS: '/projects/:projectId/meetings',
    COMPLETE_STATUSES: '/projects/:projectId/settings/completeStatuses',
    SURVEY_DIAGNOSES: '/diagnoses',
    SURVEYS: '/surveys',
    SURVEY: '/surveys/:surveyId',
    SURVEY_DIAGNOSIS: '/surveys/:surveyId/diagnoses/:diagnosisId',
    SURVEY_POST_ANSWERS: '/surveys/:surveyId/answers',
    PROJECT_DIAGNOSES: '/projects/:projectId/diagnoses',
    AUTH_GOOGLE: '/auth/google?auth[client]=[CODE]&requests[]=calendars',
    AUTH_MEMBER: '/auth/member',
    DESK_TICKETS: '/desk/tickets',
    DESK_TICKET: '/desk/tickets/:ticketId',
    DESK_UPLOADS: '/desk/uploads',
    DESK_TICKET_COMMENT: '/desk/tickets/:ticketId/comment',
    DESK_TICKET_TEMPLATES: '/desk/templates',
    DESK_USER: '/desk/user',
    BOOKMARKS: '/projects/:projectId/bookmarks',
} as const;

export const DYNAMIC_API_ROUTES = {
    PROJECT: (projectId: string) => t({ projectId }, () => `${API_ROUTES.PROJECTS}/${projectId}`),
    TASKS: (projectId: string) => t({ projectId }, () => API_ROUTES.TASKS.replace(':projectId', projectId)),
    ALERTS: (projectId: string) => t({ projectId }, () => API_ROUTES.ALERTS.replace(':projectId', projectId)),
    TOOLS: (projectId: string) => t({ projectId }, () => API_ROUTES.TOOLS.replace(':projectId', projectId)),
    TOOLS_SETTINGS: (projectId: string, toolId: string) => t({ projectId, toolId }, () => API_ROUTES.TOOLS_SETTINGS.replace(':projectId', projectId).replace(':toolId', toolId)),
    TOOLS_DELETE: (projectId: string, id: string) => t({ projectId, id }, () => `${DYNAMIC_API_ROUTES.TOOLS(projectId)}/${id}`),
    NOTIFICATIONS: (projectId: string) => t({ projectId }, () => API_ROUTES.NOTIFICATIONS.replace(':projectId', projectId)),
    NOTIFICATIONS_DELETE: (projectId: string, id: string) => t({ projectId, id }, () => `${DYNAMIC_API_ROUTES.NOTIFICATIONS(projectId)}/${id}`),
    MEMBERS: (projectId: string) => t({ projectId }, () => API_ROUTES.MEMBERS.replace(':projectId', projectId)),
    MEMBERS_DELETE: (projectId: string, id: string) => t({ projectId, id }, () => `${DYNAMIC_API_ROUTES.MEMBERS(projectId)}/${id}`),
    ROUTINES: (projectId: string) => t({ projectId }, () => API_ROUTES.ROUTINES.replace(':projectId', projectId)),
    ROUTINES_DELETE: (projectId: string, id: string) => t({ projectId }, () => `${DYNAMIC_API_ROUTES.ROUTINES(projectId)}/${id}`),
    MEETINGS: (projectId: string) => t({ projectId }, () => API_ROUTES.MEETINGS.replace(':projectId', projectId)),
    MEETINGS_DELETE: (projectId: string, id: string) => t({ projectId, id }, () => `${DYNAMIC_API_ROUTES.MEETINGS(projectId)}/${id}`),
    COMPLETE_STATUSES: (projectId: string) => t({ projectId }, () => API_ROUTES.COMPLETE_STATUSES.replace(':projectId', projectId)),
    SURVEY: (surveyId: string) => t({ surveyId }, () => API_ROUTES.SURVEY.replace(':surveyId', surveyId)),
    SURVEY_DIAGNOSIS: (surveyId: string, diagnosisId: string) => t({ surveyId, diagnosisId }, () => API_ROUTES.SURVEY_DIAGNOSIS.replace(':surveyId', surveyId).replace(':diagnosisId', diagnosisId)),
    SURVEY_POST_ANSWERS: (surveyId: string)=> t({ surveyId }, () => API_ROUTES.SURVEY_POST_ANSWERS.replace(':surveyId', surveyId)),
    PROJECT_DIAGNOSES: (projectId: string) => t({ projectId }, () => API_ROUTES.PROJECT_DIAGNOSES.replace(':projectId', projectId)),
    AUTH_GOOGLE: (code: string) => t({ code }, () => API_ROUTES.AUTH_GOOGLE.replace('[CODE]', code)),
    DESK_TICKET: (ticketId: string) => t({ ticketId }, () => API_ROUTES.DESK_TICKET.replace(':ticketId', ticketId)),
    DESK_TICKET_COMMENT: (ticketId: string) => t({ ticketId }, () => API_ROUTES.DESK_TICKET_COMMENT.replace(':ticketId', ticketId)),
    BOOKMARKS: (projectId: string) => t({ projectId }, () => API_ROUTES.BOOKMARKS.replace(':projectId', projectId) ),
    BOOKMARK: (projectId: string, bookmarkId: string) => t({ projectId, bookmarkId }, () =>  `${DYNAMIC_API_ROUTES.BOOKMARKS(projectId)}/${bookmarkId}`),
};

export const EXTERNAL_API_ROUTES = {
    /**
     * @see https://developer.nulab.com/docs/backlog/api/2/get-project-list/#
     */
    BACKLOG_PROJECTS: (tenantUrl: string, apiKey: string) => t({ tenantUrl, apiKey }, () => `https://${tenantUrl}/api/v2/projects?apiKey=${apiKey}&archived=false`),
    /**
     * @see https://developer.nulab.com/docs/backlog/api/2/get-issue-type-list/#
     */
    BACKLOG_ISSUE_TYPES: (tenantUrl: string, projectId: string, apiKey: string) => t({ tenantUrl, projectId, apiKey }, () => `https://${tenantUrl}/api/v2/projects/${projectId}/issueTypes?apiKey=${apiKey}`),
    /**
     * @see https://developer.nulab.com/docs/backlog/api/2/get-status-list-of-project/#
     */
    BACKLOG_STATUSES: (tenantUrl: string, projectId: string, apiKey: string) => t({ tenantUrl, projectId, apiKey }, () => `https://${tenantUrl}/api/v2/projects/${projectId}/statuses?apiKey=${apiKey}`),
}
