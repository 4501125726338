import { useEffect, useState } from "react";
import { SubmitHandler } from "react-hook-form";
import { Box } from "@mui/material";
import { Title, FormModal, CustomButton, Loading } from "components";
import { FormInputType, TypedFormInputType } from "types";
import ProjectListTable from "./block/ProjectListTable";
import { useAPIProjectPOST, useAPIProjectsWithAlertsGET } from "api";
import ErrorModal from "components/ErrorModal";
import { IDS, LABELS } from "constants/constants";
import { HOME_LOGGED_IN_TITLE } from "constants/messages";
import { handleConflictError } from "utilities";
import APIGetHandler from "components/APIGetHandler";
import APIMutateHandler from "components/APIMutateHandler";

const labels = [
  LABELS.PROJECT_NAME,
];

/**
 * プロジェクト一覧画面
 * @returns
 */
const ProjectListPage = () => {
  const [mutateErrorMessage, setMutateErrorMessage] = useState<string | null>(null);
  const { data: projects, execute, isLoading: isLoadingProjects, errorMessage } = useAPIProjectsWithAlertsGET(); // プロジェクト一覧取得
  useEffect(() => {
    execute({});
  }, []);
  const { mutate: mutateProject, isLoading: isLoadingProjectPOST } = useAPIProjectPOST(); // プロジェクト登録
  const requestUpdate = () => execute({}, true);
  const isLoading = isLoadingProjects || isLoadingProjectPOST;

  // モーダル表示非表示処理
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // 新規タブを開いて遷移
  const openInitSettingmanual = ()=> window.open('https://www.andgate.jp/hubfs/AGPFマニュアル/&PROJECT利用手順%20初期設定編.pdf');
  const openFunction = ()=> window.open('https://www.andgate.jp/hubfs/AGPFマニュアル/&PROJECT利用手順%20機能編.pdf');

  /**
   * 新規プロジェクト作成処理
   * @param data
   */
  const handleCreateProject: SubmitHandler<TypedFormInputType<{ projectName: string }>> = async (data) => {
    const name = data.projectName;
    const response = await mutateProject({
      params: {
        name: name,
        hasReceivedOrder: true,
      },
    });
    //post終了後モーダル閉じる。失敗時エラー表示
    if (response.success) {
      requestUpdate();
      handleClose();
    } else {
      if (handleConflictError(response, (message) => setMutateErrorMessage(message))) {
        //
      } else {
        setMutateErrorMessage("新規プロジェクト作成に失敗しました。");
      }

      handleClose();
    }
  };

  if (isLoading || errorMessage || !projects) {
    return <APIGetHandler settings={{ name: 'ProjectListPage', isLoading, errorMessage, retry: () => execute() }} />
  }

  return (
    <>
      <Title title={HOME_LOGGED_IN_TITLE} icon="list" />
      <CustomButton
          id={IDS.SEND_TICKET_BUTTON}
          text="&PROJECT初期設定マニュアル"
          color="info"
          onClick={openInitSettingmanual}
          icon="PictureAsPdf"
        />
      <CustomButton
          id={IDS.SEND_TICKET_BUTTON}
          text="&PROJECT機能紹介"
          color="info"
          onClick={openFunction}
          icon="PictureAsPdf"
        />
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        {open && (
          <FormModal
            title="新規プロジェクト作成"
            buttonText="プロジェクト作成"
            open={open}
            onSubmit={handleCreateProject as SubmitHandler<FormInputType>}
            onClose={handleClose}
            labels={labels}
          />
        )}
        <APIMutateHandler message={mutateErrorMessage} />
        <CustomButton
          id={IDS.CREATE_PROJECT_BUTTON}
          text="新規プロジェクトの作成"
          onClick={handleOpen}
          icon="addCircle"
        />
      </Box>
      <ProjectListTable rows={projects} />
    </>
  );
};

export default ProjectListPage;
