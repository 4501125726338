import * as React from 'react';
import Container from '@mui/material/Container';
import { useAPIDiagnosesGET } from 'api';
import { useEffect } from 'react';
import ResultChart from 'views/SurveysResultsPage/block/ResultChart';
import { Loading } from "components";

function DashboardContent() {

  const { execute, data, isLoading } = useAPIDiagnosesGET();

  useEffect(() => {
    execute({});
  }, []);

  const item = data && data[0];
  if (!item) {
    return <Loading name={'Dashboard'} />;
  }

  return (
    <Container maxWidth="lg" sx={{ width: '50%', mt: 4, mb: 4 }}>
      <ResultChart data={item} />
    </Container>
  );
}

export default function Dashboard() {
  return <DashboardContent />;
}
