import React from 'react';
import {
    Chart as ChartJS,
    ChartOptions,
    ChartData,
    // Registerables:
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ChartDataset,
    ScatterDataPoint,
} from 'chart.js';
import { Line } from "react-chartjs-2";
import { DiagnosesResponseDto } from "types/api";
import 'chartjs-adapter-date-fns';
import { ja } from 'date-fns/locale';

// @ts-ignore
import autocolors from 'chartjs-plugin-autocolors';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

type Dataset = ChartDataset<"line", (number | ScatterDataPoint | null)[]>;

const plugins = [autocolors];
const getDefaultChartOptions = () => {
    const options: ChartOptions<'line'> = {
        scales: {
            x: {
                type: 'time',
                ticks: {
                    // source: 'auto',
                    source: 'data',
                    // source: 'labels',
                },
                time: {
                    unit: 'year', // DEFAULT
                },
                adapters: {
                    date: {
                        locale: ja,
                    },
                },
            },
        },
        responsive: true,
        plugins: {
            legend: {
                position: 'right' as const,
            },
            /*
            title: {
              display: true,
              text: '',
            },
            */
        },
    };
    return options;
};
const TOTAL_LABEL = '全';
const MEAN_LABEL = '平均値';

const createDataSets = (rows: DiagnosesResponseDto[]) => {
    const dates: Date[] = [];
    const labels: string[] = [];
    const datasets: Dataset[] = [];
    const totalDataSet: Dataset = {
        // label: TOTAL_LABEL,
        label: MEAN_LABEL,
        data: [],
        // borderColor: 'rgb(255, 99, 132)',
        // backgroundColor: 'rgba(255, 99, 132, 0.5)',
    };

    rows.forEach((row) => {
        // X AXIS
        const date = new Date(row.createdAt);
        dates.push(date);
        // const x = date.getFullYear();
        const x = (date.toISOString() as any); // string不可のようですが、問題ない？

        // Calculations
        let categoryTotals: Record<string, number> = {};
        let total = 0;
        row.results.choice.forEach((choice) => {
            const { category, point } = choice;
            categoryTotals[category] = (categoryTotals[category] || 0) + point;
            total += point;
        });
        // const mean = total / row.results.choice.length; // 項目に対する平均値
        const mean = total / Object.values(categoryTotals).length;// カテゴリーに対する平均値

        // Datasets
        // totalDataSet.data.push({ x, y: total }); // 全
        totalDataSet.data.push({ x, y: mean }); // 平均値
        Object.entries(categoryTotals).forEach(([category, point]) => {
            const getDataset = () => datasets.filter((dataset) => dataset.label === category)[0];
            // 初期化
            if (!getDataset()) {
                datasets.push({ label: category, data: [] });
            }

            const dataset = getDataset() as Dataset;
            dataset.data.push({ x, y: point });
        });
    });

    // Total
    datasets.unshift(totalDataSet);

    return {
        dates, labels, datasets
    };
};

const generateLabels = (dates: Date[], labels: string[], options: ReturnType<typeof getDefaultChartOptions>) => {
    type MDate = Date | null;
    let min: MDate = null as MDate;
    let max: MDate = null as MDate;
    dates.forEach((d) => {
        if (min === null || Number(d) < Number(min)) min = d;
        if (max === null || Number(d) > Number(max)) max = d;
    });
    const diff = Number(max) - Number(min);
    const SECOND = 1000;
    const MINUTE = 60 * SECOND;
    const HOUR = 60 * MINUTE;
    const DAY = 24 * HOUR;
    // const MONTH = 30 * DAY;
    const YEAR = 365 * DAY;

    let unit: "millisecond" | "second" | "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year" = 'year';
    if (diff > YEAR) {
        unit = 'year';
    } else {
        unit = 'month';
    }
    // optionsの型がなぜか間違っている。
    (options as any).scales.x.time.unit = unit;

    dates.forEach((d) => {
        if (unit === 'year') {
            const cur = `${d.getFullYear()}`;
            if (!labels.includes(cur)) labels.push(cur);
            const next = `${d.getFullYear() + 1}`;
            if (!labels.includes(next)) labels.push(next);
        } else {
            const toMM = (v: number) => String(v).padStart(2, '0');
            const curYear = d.getFullYear();
            const curMonth = d.getMonth() + 1;
            const cur = `${curYear}/${toMM(curMonth)}`;
            if (!labels.includes(cur)) labels.push(cur);
            const next = curMonth < 12 ? `${curYear}/${toMM(curMonth + 1)}` : `${curYear + 1}/${toMM(1)}`;
            if (!labels.includes(next)) labels.push(next);
        }
    });

    // MIN/MAX
    (options as any).scales.x.min = min ? min.toDateString() : null;
    (options as any).scales.x.max = max ? max.toDateString() : null;
};

const SurveyHistoryComparisonLineGraph = ({ rows }: { rows: DiagnosesResponseDto[] }) => {
    const options = getDefaultChartOptions();

    const { datasets, dates, labels } = createDataSets(rows);
    generateLabels(dates, labels, options);
    console.debug({ labels });

    const data: ChartData<'line'> = {
        // labels,
        datasets,
    };

    return <Line plugins={plugins} options={options} data={data} />
};
export default SurveyHistoryComparisonLineGraph;
