import { useState, Dispatch, SetStateAction } from "react";
import { SubmitHandler } from "react-hook-form";
import { Box, TableRow, TableCell, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { FormInputType, TypedFormInputType, ValuesType } from "types";
import {
  BasicTable,
  Title,
  FormModal,
  CustomButton,
  Loading,
} from "components";
import { useAPIMemberDelete, useAPIMemberPOST } from "api";
import ErrorModal from "components/ErrorModal";
import DeleteModal from "components/DeleteModal";
import { MemberGetResponseDto } from "types/api";
import { DATA_CLASSES, HEADERS, IDS, LABELS } from "constants/constants";

const headers = [
  HEADERS.NAME,
  HEADERS.EMAIL,
  HEADERS.ROLES,
  HEADERS.DELETE,
];

const labels = [
  LABELS.EMAIL,
];

interface MembersProps {
  rows: MemberGetResponseDto[] | null;
  projectId: string;
  requestUpdate: () => void;
  projectName: string;
}

/*
 * メンバー設定画面
 */
const Members = ({
  rows,
  projectId,
  requestUpdate,
  projectName,
}: MembersProps) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null); // エラーメッセージ
  const [deleteData, setDeleteData] = useState({ id: "", name: "" }); // 削除メンバー
  const { mutate: mutatePost, isLoading: isLoadingPost } = useAPIMemberPOST(); // メンバー登録
  const { mutate: mutateDelete, isLoading: isLoadingDelete } = useAPIMemberDelete(); //メンバー削除

  //「メンバーを追加する」ボタン押下時、モーダル表示
  const [modalOpen, setModalOpen] = useState(false);
  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);
  // 一覧の削除ボタン押下時、モーダル表示
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const handleOpenDeleteModal = (values: ValuesType) => {
    setDeleteData(values);
    setOpenDeleteModal(true);
  };
  const handleCloseDeleteModal = () => setOpenDeleteModal(false);
  // エラーモーダル表示非表示処理
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const handleErrorModalOpen = () => setErrorModalOpen(true);
  const handleErrorModalClose = () => {
    setErrorModalOpen(false);
    setErrorMessage("");
  };

  /**
   * メンバー登録処理
   */
  const handleSubmit: SubmitHandler<TypedFormInputType<{ email: string }>> = async (data) => {
    const email = data.email;
    const response = await mutatePost({
      projectId,
      params: {
        email: email,
      },
    });
    //post終了後モーダル閉じる。失敗時エラー表示
    if (response.success) {
      requestUpdate();
      handleModalClose();
    } else {
      // CONFLICT 409
      if (response.statusCode === 409) {
        const message = response?.data?.response?.data?.message || "既に同一メールアドレスで登録されています。別のログイン方法をお試しください";
        setErrorMessage(message);
      } else {
        setErrorMessage("プロジェクトメンバーの登録に失敗しました。");
      }
      handleModalClose();
      handleErrorModalOpen();
    }
  };

  /**
   * メンバー削除処理
   */
  const handleDelete = async () => {
    const { id } = deleteData;
    const response = await mutateDelete({ projectId, id });
    //削除後モーダル閉じる。失敗時エラーモーダル表示
    if (response.success) {
      requestUpdate();
      handleCloseDeleteModal();
    } else {
      setErrorMessage("プロジェクトメンバーの削除に失敗しました。");
      handleCloseDeleteModal();
      handleErrorModalOpen();
    }
  };

  if (!rows || isLoadingPost || isLoadingDelete) {
    return <Loading name="Members" />;
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Title title="プロジェクトメンバー一覧" icon="list" size="subtitle1" />
        <CustomButton
          id={IDS.ASSIGN_MEMBER_BUTTON}
          text="メンバーを追加する"
          onClick={handleModalOpen}
          icon="addCircle"
        />
      </Box>

      {modalOpen && (
        <FormModal
          title="プロジェクトメンバー登録"
          buttonText="招待する"
          open={modalOpen}
          onSubmit={handleSubmit as SubmitHandler<FormInputType>}
          onClose={handleModalClose}
          labels={labels}
        />
      )}

      <BasicTable headers={headers}>
        {rows.length !== 0 ? (
          rows.map((row, i: number) => {
            const rolesText = (row.roles || []).map((r) => r.name).join(', ') || 'ロールが付与されていません';

            return (
              <TableRow
                key={`row-${i}`}
                sx={{ "&:last-child td": { border: 0 } }}
              >
                <TableCell align="center">{row.name}</TableCell>
                <TableCell align="center">{row.email}</TableCell>
                <TableCell align="center">{rolesText}</TableCell>
                <TableCell align="center">
                  <IconButton
                    className={DATA_CLASSES.UNASSIGN_MEMBER_ITEM_BUTTON}
                    onClick={() =>
                      handleOpenDeleteModal({
                        id: row.id,
                        name: row.email || '',
                      })
                    }
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })
        ) : (
          <TableRow sx={{ "&:last-child td": { border: "none" } }}>
            <TableCell align="center" colSpan={headers.length}>
              メンバーを追加してください。
            </TableCell>
          </TableRow>
        )}
      </BasicTable>
      <DeleteModal text={`「${deleteData.name}」を「${projectName}」から削除しますか？`} open={openDeleteModal} onClick={handleDelete} onClose={handleCloseDeleteModal} />
      <ErrorModal text={errorMessage} open={errorModalOpen} onClose={handleErrorModalClose} />
    </>
  );
};

export default Members;
