import React from "react";
import Box from "@mui/material/Box";

interface ListItemsProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

/**
 * TODO: Boxじゃなくて、TabPanelを使う？https://mui.com/material-ui/react-tabs/ https://mui.com/material-ui/api/tab-panel/
 * index: この項目の値。
 * value: 現在の値。indexと一緒でしたら、このコンポーネントを表示する。
 */
const ListItems = (props: ListItemsProps) => {
  const { children, value, index, ...other } = props;

  return (
    <Box
      component="div"
      sx={{ width: "100%", overflow: 'auto' }}
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </Box>
  );
};

export default ListItems;
