import * as React from "react";
import { Box, Modal, Typography, IconButton, SxProps, Theme } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import WarningIcon from "@mui/icons-material/Warning";
import CustomButton from "components/CustomButton";
import { MODAL_ERROR_TITLE_COLOR, MODAL_CLOSE_BUTTON_COLOR, MODAL_STYLE } from "styles/constants";
import { getRandomId } from "utilities";

interface ModalProps {
  title?: string;
  text?: string | null;
  buttonText?: string;
  buttonColor?: "error";
  open: boolean;
  onClick?: () => void;
  onClose: () => void;
  error?: boolean;
  children?: JSX.Element;
  sx?: SxProps<Theme> | undefined;
}

const BasicModal = ({
  title,
  text,
  buttonText,
  buttonColor,
  open,
  onClick,
  onClose,
  error,
  children,
  sx,
}: ModalProps) => {
  const style = {
    ...MODAL_STYLE,
    p: 4,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    ...sx,
  } as SxProps<Theme>;

  const titleId = getRandomId('modal-modal-title');
  const textId = getRandomId('modal-modal-text');
  return (
    <div>
      <Modal
        open={open}
        aria-labelledby={titleId}
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ mb: 2, display: "flex", alignItems: "center" }}>
            {error && <WarningIcon color="error" sx={{ mr: 1 }} />}
            <Typography
              id={titleId}
              variant="h6"
              component="h2"
              sx={{ color: error ? MODAL_ERROR_TITLE_COLOR : "unset" }}
            >
              {title}
            </Typography>
          </Box>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: MODAL_CLOSE_BUTTON_COLOR,
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography id={textId} sx={{ mb: 2, width: '100%' }}>
            {text}
          </Typography>
          {children}
          {buttonText && (
            <CustomButton
              text={buttonText}
              onClick={onClick}
              color={buttonColor}
              fullWidth={sx ? false : true}
            />
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default BasicModal;
