import React, { Component, ReactElement, useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// import { useAuth0 } from '@auth0/auth0-react';
import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  Menu,
  MenuItem,
  Divider,
  Avatar,
  IconButton,
  Button,
  Typography,
  ListItemIcon,
  MenuList,
  Popover,
  Snackbar,
  Alert,
  Toolbar,
} from "@mui/material";
import Logout from '@mui/icons-material/Logout';
import AppsIcon from '@mui/icons-material/Apps';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { CustomButton } from "components";
import { useAppState, useAuthAxios, useProject } from "hooks";
import "./header.css";
import { DYNAMIC_ROUTES, ROUTES } from "constants/routes";
import { API_ROUTES } from "constants/api-routes";
import { AxiosInstance } from "axios";
import { useAPIProjectsGET, useAuthMemberPOST } from "api";
import { IDS, ROLES_KEY } from "constants/constants";
import { ACADEMY_COURSES_TITLE, LOGIN_BUTTON_TEXT, SURVEYS_TITLE } from "constants/messages";
import { ArrowForward, OpenInNew, Payment, Close as CloseIcon } from "@mui/icons-material";
import { handleConflictError } from "utilities";
import CustomSnackbar from "components/CustomSnackbar";
import { useAPIErrorMessage } from "components/GlobalSnackbar/globalSnackbarContext";

// IMAGES
import img from "../../images/logo.png";
import academy15 from "../../images/academy15.png";
import survey from "../../images/survey.png";
import wall from "../../images/wall.png";
import desk from "../../images/desk.png";
import dandle from "../../images/dandle.svg";
import kabeuchi from "../../images/kabeuchi.png";
import saatisfy from "../../images/saatisfy.png";

// type User = {
//   name: string;
// };

interface HeaderProps {
  AppBar: React.ComponentType<any>;
  onLogin?: () => void;
  onLogout?: () => void;
  onCreateAccount?: () => void;
}

/**
 * 現在の認証方法では、ページが再読み込みするので、起動する際でensureMemberを実行してもよい。
 * TODO: 手動で認証するときのみ, ensureMemberができるようにする。
 */
const LoginComponent = () => {
  const { mutate } = useAuthMemberPOST();
  const { isAuthenticated, user } = useAuth0();
  console.debug('LoginComponent', { isAuthenticated, user });

  // error message
  // const [errorMessage, setErrorMessage] = useState('');
  const [, setErrorMessage] = useAPIErrorMessage(); // TODO: use global error

  // member
  const ensureMember = useCallback(async () => {
    if (!user) return;
    if (!user.sub) return;

    const id = user.sub;
    console.debug('ensureMember', { user, id });

    const response = await mutate({ params: { id } });
    if (!response.success) {
      handleConflictError(response, (message) => {
        setErrorMessage(message);
      }, '既に同一メールアドレスで登録されています。別のログイン方法をお試しください');
    } else {
      setErrorMessage('');
    }
  }, [user]);
  useEffect(() => {
    ensureMember();
  }, [ensureMember])

  // <CustomSnackbar severity="error" message={errorMessage} />
  return (
    <></>
  );
};

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();
  const onLoginClick = () => {
    loginWithRedirect({
      appState: {
        // リダイレクト後に使えるデータをここに保存しておく。onRedirectCallbackでアクセスできる。
        TEST: 'TEST',
      },
    });
  };
  return (
    <CustomButton id={IDS.LOGIN_BUTTON} text={LOGIN_BUTTON_TEXT} onClick={onLoginClick} />
  );
};
const HeaderPopup = ({ onClickOpen, anchorElement, onClose }: { onClickOpen: (event: React.MouseEvent<HTMLElement>) => void, anchorElement: HTMLElement | null, onClose: React.MouseEventHandler<HTMLAnchorElement> | undefined }) => {
  return (
    <Box>
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls={IDS.HEADER_POPUP_POPOVER}
        aria-haspopup="true"
        onClick={onClickOpen}
        color="secondary"
      >
        <AppsIcon />
      </IconButton>
      <Popover
        id={IDS.HEADER_POPUP_POPOVER}
        anchorEl={anchorElement}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElement)}
        onClose={onClose}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          alignContent: "center"
        }}
      >
        <MenuItem
          component={Link}
          to={ROUTES.ACADEMY_COURSES}
          onClick={onClose}
          color="secondary"
        >
          <ListItemIcon>
            <ArrowForward fontSize="small" />
          </ListItemIcon>
          {ACADEMY_COURSES_TITLE}
        </MenuItem>
        <MenuItem
          component={Link}
          to={ROUTES.SURVEYS}
          onClick={onClose}
          color="secondary"
        >
          <ListItemIcon>
            <ArrowForward fontSize="small" />
          </ListItemIcon>
          {SURVEYS_TITLE}
        </MenuItem>
        <Divider />
        <MenuItem
          component={Link}
          to="//www.andgate.jp/hubfs/AGPFマニュアル/ANDGATE登録手順.pdf"
          onClick={onClose}
          target="_blank"
          color="secondary"
        >
          <ListItemIcon>
            <PictureAsPdfIcon fontSize="small" />
          </ListItemIcon>
          ANDGATE登録マニュアル
        </MenuItem>
        <MenuItem
          component={Link}
          to="//www.andgate.jp/hubfs/AGPFマニュアル/&PROJECT利用手順%20初期設定編.pdf"
          onClick={onClose}
          target="_blank"
          color="secondary"
        >
          <ListItemIcon>
            <PictureAsPdfIcon fontSize="small" />
          </ListItemIcon>
          &PROJECT初期設定マニュアル
        </MenuItem>
        <MenuItem
          component={Link}
          to="//www.andgate.jp/hubfs/AGPFマニュアル/&PROJECT利用手順%20機能編.pdf"
          onClick={onClose}
          target="_blank"
          color="secondary"
        >
          <ListItemIcon>
            <PictureAsPdfIcon fontSize="small" />
          </ListItemIcon>
          &PROJECT機能紹介
        </MenuItem>
        <MenuItem
          component={Link}
          to="//www.andgate.jp/hubfs/AGPFマニュアル/&SUPPORTチケット起票ガイドライン.pdf"
          onClick={onClose}
          target="_blank"
          color="secondary"
        >
          <ListItemIcon>
            <PictureAsPdfIcon fontSize="small" />
          </ListItemIcon>
          &SUPORT起票ガイドライン
        </MenuItem>
        <Divider />
        <MenuItem
          component={Link}
          to="//www.andgate.jp/kabeuchi"
          onClick={onClose}
          target="_blank"
          color="secondary"
        >
          <ListItemIcon>
            <OpenInNew fontSize="small" />
          </ListItemIcon>
          カベウチ
        </MenuItem>
        <MenuItem
          component={Link}
          to="//www.andgate.jp/saatisfy"
          onClick={onClose}
          target="_blank"
          color="secondary"
        >
          <ListItemIcon>
            <OpenInNew fontSize="small" />
          </ListItemIcon>
          Saatisfy
        </MenuItem>
        <MenuItem
          component={Link}
          to="//www.dandle.jp"
          onClick={onClose}
          target="_blank"
          color="secondary"
        >
          <ListItemIcon>
            <OpenInNew fontSize="small" />
          </ListItemIcon>
          ダンドル
        </MenuItem>
      </Popover>
    </Box>
  );
};

const LoggedInStatus = () => {
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const { logout, user } = useAuth0();

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Typography sx={{ mr: "10px" }}>{user!.email}</Typography>
      <IconButton id={IDS.CURRENT_USER_BUTTON} title={user!.name} onClick={handleOpenUserMenu} sx={{ p: 0 }}>
        <Avatar src={user!.picture} />
      </IconButton>
      <Menu
        sx={{ mt: "45px" }}
        id={IDS.LOGIN_STATUS_MENU}
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <MenuItem disabled sx={{ opacity: "1 !important" }}>
          <ListItemIcon>
            <Payment fontSize="small" />
          </ListItemIcon>
          ご契約中のプラン
        </MenuItem>
        <MenuItem disabled sx={{ opacity: "1 !important" }}>
          <Typography sx={{ ml: "38px" }}>
            {user![ROLES_KEY].length > 0 ? user![ROLES_KEY].join(",") : "なし"}
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            logout({ returnTo: window.location.origin, });
          }}
        >
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          ログアウト
        </MenuItem>
      </Menu>
    </Box>
  );
};

const AuthStatus = () => {
  const { isAuthenticated } = useAuth0();
  return (
    isAuthenticated ? <LoggedInStatus /> : <LoginButton />
  );
};

const ProjectInfo = () => {
  const navigate = useNavigate();
  const [state, setAppState] = useAppState();
  const { data: projects, execute } = useAPIProjectsGET();
  const { project } = useProject();
  useEffect(() => {
    execute({});
  }, []);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleOpenProjectMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClick = (projectId: string) => {
    const newState = { projectId };
    setAppState({ ...state, ...newState });
    navigate(DYNAMIC_ROUTES.PROJECT_TASKS(projectId), { state: newState });
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    project ? (
      <>
        <CustomButton
          color="inherit"
          onClick={handleOpenProjectMenu}
          text={project.name}
          variant="text"
          sx={{ ml: 1, textTransform: 'none' }}
        />
        <Menu
          id={IDS.HEADER_PROJECT_MENU}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          sx={{
            "& .MuiMenu-paper": { minWidth: "150px" },
            "& ul": { py: 0 },
          }}
        >
          <Typography
            sx={{
              textAlign: "center",
              py: 1,
            }}
          >
            プロジェクト
          </Typography>
          <Divider />
          {/** TODO: projects list component */ projects &&
            projects.map((project, i) => (
              <MenuItem
                key={`project-${i}`}
                onClick={() => handleClick(project.id)}
              >
                {project.name}
              </MenuItem>
            ))}
        </Menu>
      </>
    ) : <></>
  );
};

const Header = ({
  AppBar,
  onLogin,
  onLogout,
  onCreateAccount,
}: HeaderProps) => {

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position="absolute">
      <LoginComponent />
      <Toolbar
        sx={{
          justifyContent: "space-between",
          pr: "24px", // keep right padding when drawer closed
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Button component={Link} to={ROUTES.HOME} id={IDS.HOME_BUTTON}>
            <img className="logo" src={img} alt="" />
          </Button>

          <ProjectInfo />
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "right",
          }}
        >
          <AuthStatus />
          <HeaderPopup onClickOpen={handleOpenNavMenu} anchorElement={anchorElNav} onClose={handleCloseNavMenu} />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
