import { useAPIToolSettingsPOST } from "api";
import { FormModal } from "components";
import ErrorModal from "components/ErrorModal";
import { LABELS } from "constants/constants";
import { REGISTER_BUTTON_TEXT } from "constants/messages";
import { useState } from "react";
import { SubmitHandler } from "react-hook-form";
import { FormInputType } from "types";

interface UpdateCompleteStatusesModalProps {
  projectId: string;
  toolId: string;
  open: boolean;
  onSubmit: (data: FormInputType) => void;
  onClose: () => void;
  onBack?: (() => void) | undefined;
  selectValues: any;
  currentSelectedToolStatusIds: string[];
}
const UpdateCompleteStatusesModal = ({ projectId, toolId, open, onSubmit, onClose, onBack, selectValues, currentSelectedToolStatusIds }: UpdateCompleteStatusesModalProps) => {
  const labels = [
    LABELS.COMPLETE_STATUSES,
  ];
  const [errorMessage, setErrorMessage] = useState<string | null>(null); // エラーメッセージ
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const handleErrorModalOpen = () => setErrorModalOpen(true);
  const handleErrorModalClose = () => {
    setErrorModalOpen(false);
    setErrorMessage("");
  };
  const { mutate: mutatePost, isLoading: isLoadingPost } = useAPIToolSettingsPOST(); // 完了設定登録

  /**
   * 完了設定登録処理
   */
  const handleSubmit: SubmitHandler<{ tool: string; checkbox: number[] }> = async (data) => {
    console.debug('completeStatuses:handleSubmit', { data });
    const ERROR_FAILURE = "完了設定の登録に失敗しました。";
    if (!toolId) {
      setErrorMessage(ERROR_FAILURE);
      return (
        <></>
      );
    }

    /**
     * 仕様統一としてnumber=>string。
     */
    const completeStatuses = data.checkbox.map((numId) => String(numId));

    const response = await mutatePost({
      projectId,
      toolId,
      params: {
        completeStatuses,
      },
    });
    //post終了後モーダル閉じる。失敗時エラー表示
    if (response.success) {
      onSubmit(data as any);
      onClose();
    } else {
      setErrorMessage(ERROR_FAILURE);
      onClose();
      handleErrorModalOpen();
    }
  };
  return (
    <>
      <FormModal
        title="完了設定"
        buttonText={REGISTER_BUTTON_TEXT}
        open={open}
        onSubmit={handleSubmit as any}
        onClose={onClose}
        onBack={onBack}
        labels={labels}
        selectValues={selectValues}
        defaultValues={{ checkbox: currentSelectedToolStatusIds }}
      />
      <ErrorModal text={errorMessage} open={errorModalOpen} onClose={handleErrorModalClose} />
    </>
  );
};
export default UpdateCompleteStatusesModal;
