import {
  FormControl,
  FormHelperText,
  FormLabel,
  Checkbox,
  FormControlLabel,
  FormGroup,
  CheckboxProps,
} from "@mui/material";
import { useCallback } from "react";
import { Control, Controller, FieldValues } from "react-hook-form";
import { FormType, SelectType } from "types";

type ControllerControl = Control<FieldValues, any> | undefined;

interface FormCheckProps {
  selectValues: Array<SelectType>;
  label: FormType;
  control: ControllerControl;
  register: (s: string, o: Record<string, any>) => CheckboxProps;
  errors: any;
  getValues: () => { checkbox?: string[] };
}

const FormCheck = ({
  selectValues,
  label,
  control,
  register,
  getValues,
  errors,
}: FormCheckProps) => {
  const handleCheck = useCallback((checkedId: string) => {
    const { checkbox: ids } = getValues();
    const newIds = ids?.some((id: string) => id === checkedId)
      ? ids?.filter((id: string) => id !== checkedId)
      : [...(ids ?? []), checkedId];
    console.debug('handleCheck', { ids, newIds });
    return newIds;
  }, [getValues]);

  const defaultIds = selectValues.filter((item) => !!item.selected).map((item) => item.id);
  // defaultIds.forEach((id) => field.onChange(handleCheck(id)));
  console.debug({ selectValues });

  return (
    <FormControl
      fullWidth
      required={label.required}
      sx={{
        mb: 1,
      }}
      error={!!errors?.checkbox}
    >
      <FormLabel component="legend">{label.name}</FormLabel>
      <FormGroup row>
        <Controller
          name="checkbox"
          control={control}
          defaultValue={defaultIds}
          rules={{ required: label.errorMessage }}
          render={({ field }) => (
            <>
              {selectValues.map((item, index) => {
                /*
                if (item.selected) {
                  field.onChange(handleCheck(item.id));  
                }
                <Checkbox
                defaultChecked={item.selected}
                {...register(item.name, { })}
                */
                return (
                  <FormControlLabel
                    {...field}
                    control={
                      <Checkbox
                        onChange={() => {
                          field.onChange(handleCheck(item.id));
                        }}
                        defaultChecked={item.selected}
                      />
                    }
                    key={item.id}
                    label={item.name}
                    value={item.id}
                  />
                );
              })}
            </>
          )}
        />
      </FormGroup>
      <FormHelperText>{errors?.checkbox?.message}</FormHelperText>
    </FormControl>
  );
};

export default FormCheck;
