// ほかの似ている削除の文言はバラツキがあるため、ここで管理しなくてよい。
/**
 * ${prefix}「${target}」を削除しますか？
 */
export const getDeleteTargetTitleText = (target: string, prefix?: string) => `${prefix || ''}「${target}」を削除しますか？`;
export const DELETE_BUTTON_TEXT = '削除する';
export const REGISTER_BUTTON_TEXT = '登録する';
export const ALERT_TYPES = {
    NO_ASSIGNEE: "担当者なし",
    EXPIRED: "期限切れ",
    DUE_SOON: "期限間近",
    NO_DUE: "期限なし",
}; 
export const LOGIN_BUTTON_TEXT = 'ログイン・新規登録';
export const REGISTER_TOOL_REQUEST_TEXT = 'タスク管理ツールを登録してください。';
export const REGISTER_NOTIFICATION_REQUEST_TEXT = '通知設定をしてください。';

// TITLE
export const HOME_LOGGED_IN_TITLE = 'プロジェクト';
export const SURVEYS_TITLE = '&SURVEY';
export const SURVEYS_LIST_TITLE = '診断一覧';
export const SURVEYS_HISTORY_TITLE = '診断履歴';
export const ACADEMY_COURSES_TITLE = '&ACADEMY';
export const PROJECT_ALERTS_TITLE = '要注意タスク';
export const PROJECT_TASKS_PART_TITLES = {
    ALERTS: PROJECT_ALERTS_TITLE,
    TASK_SEARCH: 'タスク検索',
    TASK_LIST: 'タスク一覧'
};
export const PROJECT_SETTINGS_TITLE = 'プロジェクト設定';
export const PROJECT_SETTINGS_PART_TITLES = {
    // TOOL: '管理ツール一覧',
    TOOL: '管理ツール設定',
    // ROUTINE: 'ルーチンタスク\nカレンダー設定',
    ROUTINE: 'タスク用カレンダー設定',
    // MEETING: 'ミーティング用\nカレンダー設定',
    MEETING: 'MTG用カレンダー設定',
    NOTIFICATION: '通知設定',
    MEMBER: 'メンバー設定',
    PROJECT_SETTINGS: PROJECT_SETTINGS_TITLE,
    PROJECT_DELETE: 'プロジェクト削除',
};
export const DANDLE_TITLE = '&PROJECT';
// export const WALL_TITLE = '&WALL';
export const DESK_TITLE = '&SUPPORT';
export const BOOKMARK_FOLDER_CREATE_TITLE = 'ブックマークフォルダーを登録する';
