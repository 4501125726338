import React, { useEffect } from "react";
import { Title, Loading } from "components";
import { Grid, Stack, Typography } from "@mui/material";
import SurveysBox from "./block/SurveysBox";
import { useAPISurveysGET } from "api";
import { SURVEYS_TITLE } from "constants/messages";
import APIGetHandler from "components/APIGetHandler";

/*
 * プロジェクト診断選択画面
 */
const SurveysPage = () => {
  const { data: surveys, execute, isLoading, errorMessage } = useAPISurveysGET();
  useEffect(() => {
    execute({});
  }, []);

  if (isLoading || errorMessage || !surveys) {
    return <APIGetHandler settings={{ name: 'SurveysPage', isLoading, errorMessage, retry: () => execute() }} />
  }

  return (
    <>
      <Title title={SURVEYS_TITLE} icon="content_paste" />
      <Typography sx={{mb: "10px"}}>正直にご回答いただくことで正確な診断ができます。</Typography>

      <Grid container spacing={2}>
        {surveys.map((survey, index: number) => (
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <SurveysBox key={`survey-${index}`} survey={survey} />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default SurveysPage;
