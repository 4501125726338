import { useEffect, useState } from "react";
import { Title, Loading } from "components";
import SurveysHistoryTable from "./block/SurveysHistoryTable";
import { useAPIDiagnosesGET } from 'api';
import { SURVEYS_HISTORY_TITLE, SURVEYS_TITLE } from "constants/messages";
import { DiagnosesResponseDto } from "types/api";
import SurveysHistoryComparisonLineGraph from "./block/SurveysHistoryComparisonLineGraph";
import SurveysHistoryComparisonTable from "./block/SurveysHistoryComparisonTable";
import { Box, Card } from "@mui/material";
import { sortByCreatedAtAsc, sortByCreatedAtDesc } from 'utilities/index';
import APIGetHandler from "components/APIGetHandler";

interface SurveysHistoryPageProps {
  //
}
interface GroupedDiagnoses {
  name: string; // Survey name
  diagnoses: DiagnosesResponseDto[]
}
type GroupedDiagnosesMap = Record<string, GroupedDiagnoses>;
// eslint-disable-next-line no-empty-pattern
const SurveysHistoryPage = ({ }: SurveysHistoryPageProps) => {
  const { data: diagnoses, execute, isLoading, errorMessage } = useAPIDiagnosesGET();

  useEffect(() => {
    execute({});
  }, []);

  /**
   * Surveyでグループ化
   */
  const [groupedDiagnosesMap, setGroupedDiagnosesMap] = useState<GroupedDiagnosesMap>({});
  useEffect(() => {
    const newGroupedDiagnosesMap: GroupedDiagnosesMap = {};
    if (!diagnoses) {
      setGroupedDiagnosesMap(newGroupedDiagnosesMap);
      return;
    }
    diagnoses.forEach((diagnosis) => {
      const { name } = diagnosis.survey;
      newGroupedDiagnosesMap[name] = newGroupedDiagnosesMap[name] || { name, diagnoses: [] }; // initialize
      if (!newGroupedDiagnosesMap[name]) throw new Error('No diagnosis map item');
      (newGroupedDiagnosesMap[name] as GroupedDiagnoses).diagnoses.push(diagnosis);
    });
    setGroupedDiagnosesMap(newGroupedDiagnosesMap);
  }, [diagnoses]);

  if (isLoading || errorMessage || !diagnoses) {
    return <APIGetHandler settings={{ name: 'SurveysHistoryPage', isLoading, errorMessage, retry: () => execute() }} />
  }

  sortByCreatedAtDesc(diagnoses);

  return (
    <>
      <Title
        title={SURVEYS_TITLE}
        subTitle={SURVEYS_HISTORY_TITLE}
        icon="content_paste"
      />

      {
        Object.values(groupedDiagnosesMap)
        .filter(({ diagnoses }) => diagnoses.length >= 2 ) // 比較可能
        .map((groupedDiagnoses) => {
          const { name, diagnoses } = groupedDiagnoses;

          // SORT
          sortByCreatedAtAsc(diagnoses);

          // CALCULATIONS
          const hasChoices = diagnoses.filter((item) => item.results.choice.length > 0).length > 0;
          const hasTextItems = (diagnoses.filter((item) => item.results.text.length > 0).length > 0);

          return (
            <Box key={name} sx={{ margin: '10px 0' }}>
              <Title
                title={name}
                icon="content_paste"
              />
              {
                hasChoices && (
                  <Card sx={{ margin: '10px 0', padding: '10px' }}>
                    <SurveysHistoryComparisonLineGraph rows={diagnoses} />
                  </Card>
                )
              }
              {
                hasTextItems && (
                  <SurveysHistoryComparisonTable rows={diagnoses} />
                )
              }
            </Box>
          );
        })
      }

      <>
        <Title title={SURVEYS_HISTORY_TITLE} icon="list" size="subtitle1" />
        <SurveysHistoryTable rows={diagnoses} />
      </>
    </>
  );
};

export default SurveysHistoryPage;
