import { ToolGetResponseDto } from "types/api";

export type Status = { id: string; name: string; selected?: boolean };
/**
 * Record<ID, Status[]>
 */
export type StatusMap = Record<string, Status[]>;

/**
 * status ids + statusesから完了設定の文字列表示を作成。
 */
export function parseCompleteStatuses(ids: string[] | undefined, statuses: Status[]) {
  if (!ids) return '';

  const names = ids.map((id) => {
    const status = statuses.filter((s) => String(s.id) === String(id))[0];
    // return status ? status.name : id;
    return status ? status.name : '';
  }).filter((item) => item);

  return names.join(', ');
}

/**
 * status ids + toolId + Record<toolId, statuses>から完了設定の文字列表示を作成。
 */
export function parseCurrentToolCompleteStatuses(ids: string[] | undefined, toolId: string, currentStatuses: StatusMap) {
  const statuses = currentStatuses[toolId] || [];
  return parseCompleteStatuses(ids, statuses);
}

export const getCurrentStatusesMap = (tools: ToolGetResponseDto[], currentToolId: string | null, currentStatuses: StatusMap) => {
  const getCurrentTool = () => {
    const filtered = tools.filter((t) => t.id === currentToolId);
    return filtered.length > 0 ? filtered[0] : null;
  };
  const tool = getCurrentTool();

  const completeStatuses = tool && tool.settings?.completeStatuses ? tool.settings?.completeStatuses : [];

  const entries = Object.entries(currentStatuses).map(([toolId, statuses]) => {
    
    const newStatuses = statuses.map((status) => {
      const newStatus = {
        ...status,
        selected: completeStatuses.includes(status.id),
      }
      return newStatus;
    });

    return [toolId, newStatuses];
  });

  const currentStatusesMap = Object.fromEntries(entries);

  return currentStatusesMap;
};

export const getCurrentToolStatusInfo = (currentToolId: string | null, currentStatuses: StatusMap)  => {
  const currentToolStatuses = currentToolId ? (currentStatuses[currentToolId] || []) : [];
  const currentSelectedToolStatusIds = currentToolStatuses.filter((item) => item.selected).map((item) => item.id);

  return {
    currentToolStatuses, currentSelectedToolStatusIds,
  };
};
