import { useCallback } from "react";
import { Outlet } from "react-router-dom";
import {
  List,
  Box,
  Toolbar,
  Container,
  ListItemButton,
  ListItemIcon,
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import MenuIcon from "@mui/icons-material/Menu";
import MuiDrawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";
import { RouteItem } from "types";
import { ListItem, Copyright } from "components";
import { MAIN_COMPONENT_BACKGROUND_COLOR } from "styles/constants";
import { DRAWER_HIDE_SUBITEMS_ICONS_ON_SHRINK, DRAWER_TOGGLEABLE } from "constants/constants";

const drawerWidth: number = 240;
const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

interface LeftNavMainLayoutProps {
  open: boolean;
  toggleDrawer: () => void;
  listItems: Array<RouteItem>;
}
const LeftNavMainLayout = ({
  open,
  toggleDrawer,
  listItems,
}: LeftNavMainLayoutProps) => {

  const MainComponent = useCallback(() => (
    <Box
      component="main"
      sx={{
        backgroundColor: MAIN_COMPONENT_BACKGROUND_COLOR,
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
      }}
    >
      <Toolbar />
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Outlet />
        <Copyright sx={{ pt: 4 }} />
      </Container>
    </Box>
  ), []);

  const ListItems = useCallback(() => (
    <>
      {
        listItems &&
        listItems.map((listItem: RouteItem, index: number) => (
          <ListItem key={`list-item-${index}`} item={listItem} />
        ))
      }
    </>
  ), [listItems]);

  const notExistOnClosed = {
    ...(!open ? {display: 'none'} : {}),
  };
  const hideOnClosed = {
    ...(!open ? {visibility: 'hidden'} : {}),
  };
  const sxExtra = DRAWER_HIDE_SUBITEMS_ICONS_ON_SHRINK ? {
    '.ListItem_ListItemComponent_Parent_ListItemText': hideOnClosed,
    '.ListItem_ListItemComponent_Child': notExistOnClosed,
  } : {};
  const MARGIN_TOP_STYLES = {
    height: "70px",
  };

  const DrawerToggleButton = () => {
    return (
      <ListItemButton
        sx={MARGIN_TOP_STYLES}
        onClick={toggleDrawer}
      >
        <ListItemIcon
          color="inherit"
          aria-label="open drawer"
          onClick={toggleDrawer}
          sx={{
            ...(open && { display: "none" }),
          }}
        >
          <MenuIcon
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          />
        </ListItemIcon>
        <ListItemIcon
          sx={{
            marginLeft: "auto",
            minWidth: "15px",
            ...(!open && { display: "none" }),
          }}
        >
          <ChevronLeftIcon />
        </ListItemIcon>
      </ListItemButton>
    );
  };

  return (
    <>
      <Drawer variant="permanent" open={open}>
        <Toolbar />
        <List
          component="nav"
          sx={{
            pt: 0,
            ...sxExtra,
          }}
        >
          {
            DRAWER_TOGGLEABLE ? <DrawerToggleButton /> : <Box sx={MARGIN_TOP_STYLES} />
          }

          <ListItems />

        </List>
      </Drawer>
      <MainComponent />
    </>
  );
};

export default LeftNavMainLayout;
