import { useCallback, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { createAuthAxios } from "api/axios";
import { AxiosRequestConfig, AxiosResponse, AxiosError, AxiosInstance } from "axios";
import { UseAuthAxiosProps } from "types";
import { getBearerToken, getParsedEnvironmentVariables } from "utilities";

const audience = getParsedEnvironmentVariables().REACT_APP_AUTH0_AUDIENCE;

// let awaitingAccessTokenPromise: Promise<string> | null = null;

const onAccessToken = (jwt: string) => {
  console.debug('onAccessToken', { jwt, end: jwt.substring(jwt.length - 10)});
  console.debug(jwt); // 簡易コピー形式
  //
};

const getTokenOptions = () => {
  const tokenOptions = {
    audience: audience,
    // Revoke user refresh token: https://auth0.com/docs/secure/tokens/refresh-tokens/revoke-refresh-tokens#use-the-dashboard
    // 以下でアプリケーション内でのセッション切れの状態を作れなかった。
    // ignoreCache: (getParsedEnvironmentVariables().REACT_APP_DEBUG && (window as any)['__auth0_ignoreCache']) || false,
  };
  console.debug({ tokenOptions, env: getParsedEnvironmentVariables() });
  return tokenOptions;
};

const useAuthAxios = ({ authRequired, alwaysSendAuthToken, canSend, requiredRoles }: UseAuthAxiosProps) => {
  console.debug('useAuthAxios', { authRequired, alwaysSendAuthToken, canSend, requiredRoles });
  // TODO: requiredRoles

  const [authAxios, ] = useState<AxiosInstance>(createAuthAxios);
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  
  // 実行可能か:
  const getCanExecute = useCallback(() => {
    const authFail = (authRequired && !isAuthenticated);
    return !authFail && (canSend === undefined || canSend);
  }, [authRequired, canSend, isAuthenticated]);

  const INITIAL_CAN_EXECUTE = getCanExecute();
  // TODO: setが利用されていないからまだ無意味:
  const [canExecute] = useState<boolean>(INITIAL_CAN_EXECUTE);

  const ensureAccessToken = useCallback(async (config: AxiosRequestConfig) => {
    console.debug('ensureAccessToken', { config, authRequired });
    // let isAccessTokenGenerator = false;
    
    const exec = () => getAccessTokenSilently(getTokenOptions());
    /*
    if (awaitingAccessTokenPromise === null) {
      isAccessTokenGenerator = true;
      awaitingAccessTokenPromise = exec();
    }
    const jwt = await awaitingAccessTokenPromise;
    if (isAccessTokenGenerator) {
      awaitingAccessTokenPromise = null; 
    }
    */
    const jwt = await exec(); // NO CACHE
    // const jwt = await (async () => {})();
    if (!config.headers) {
      console.warn('No config headers', { config });
      return;
    }
    config.headers["Authorization"] = `Bearer ${jwt}`;
    onAccessToken(jwt);
  }, [authRequired, getAccessTokenSilently]);

  useEffect(() => {
    console.debug('useAuthAxios:useEffect');
    
    const configHasAuthorizationHeaders = (config: AxiosRequestConfig) => !!(config.headers && typeof config.headers['Authorization'] === 'string' && getBearerToken(config.headers['Authorization']));

    // リクエスト前に実行。headerに認証情報を付与する
    const requestInterceptor = async (config: AxiosRequestConfig) => {
      console.debug('requestinterceptor', { config });
      // なにも変更しなくてよい。
      if (!authRequired && !alwaysSendAuthToken) {
        console.debug('no authRequired so ignore', { config });
        return config;
      }
      try {
        await ensureAccessToken(config);
      } catch(err) {
        if (authRequired) {
          throw err;
        }
      }

      // 認証必要でエラー
      if (authRequired && !configHasAuthorizationHeaders(config)) {
        const controller = new AbortController();
        config.signal = controller.signal;
        controller.abort('authRequired: NOT AUTHORIZED');
        console.debug('authRequired failed, attempting abort');
      }

      // jwt取得(キャッシュあり)
      console.debug('useAuthAxios', { isAuthenticated });
      
      return config;
    };

    const requestInterceptorId = authAxios.interceptors.request.use(requestInterceptor, (error) => Promise.reject(error));
    console.debug('new requestInterceptor', { requestInterceptorId, requestInterceptor });

    // レスポンスを受け取った直後に実行。もし認証エラーだった場合、再度リクエストする。
    const responseInterceptor = (response: AxiosResponse) => response;
    const responseErrorInterceptor = async (error: AxiosError) => {
      console.debug('responseErrorInterceptor', { error });
      const prevRequest = error?.config as (AxiosRequestConfig & { sent?: boolean; });
      // 401エラーは
      if (error?.response?.status === 401 && !prevRequest.sent) {
        console.debug('reattempt', { sent: prevRequest.sent });
        prevRequest.sent = true;
        // 新しくjwt_tokenを発行する
        // await ensureAccessToken(prevRequest);
        // 再度実行する
        // return authAxios(prevRequest);
      }
      return Promise.reject(error);
    };
    const responseInterceptorId = authAxios.interceptors.response.use(
      responseInterceptor,
      responseErrorInterceptor,
    );
    console.debug('new responseInterceptor', { responseInterceptorId, responseInterceptor });

    return () => {
      // 離脱するときにejectする
      console.debug('requestInterceptor: destroy', { requestInterceptorId, requestInterceptor });
      authAxios.interceptors.request.eject(requestInterceptorId);
      authAxios.interceptors.response.eject(responseInterceptorId);
    };
  }, [authRequired, getAccessTokenSilently, isAuthenticated, alwaysSendAuthToken, authAxios.interceptors.request, authAxios.interceptors.response, ensureAccessToken]);

  return { authAxios, canExecute };
};

export default useAuthAxios;
