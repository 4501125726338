import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { Loading, Title } from "components";
import { useDiagnosis, useRoles, useSurvey } from "hooks";
import AlertBox from "./block/AlertBox";
import SaveBox from "./block/SaveBox";
import ResultBox from "./block/ResultBox";
import { SurveyDiagnosisGetResponseDto } from "types/api";
import { useAPISurveyOneGET, useAPISurveyDiagnosisGET } from "api";
import { useAuth0 } from "@auth0/auth0-react";
import { SURVEYS_TITLE } from "constants/messages";
import { RoleKey } from "types";
import TagManager from 'react-gtm-module';
import { GOOGLE_TAG_MANAGER_EVENT_ITEMS } from "constants/constants";
import APIGetHandler from "components/APIGetHandler";

const SurveysResultsPage = () => {
  const { surveyId } = useSurvey();
  const { diagnosisId, diagnosis: localDiagnosis, isLoading: isLoadingDiagnosis, errorMessage: diagnosisErrorMessage } = useDiagnosis();
  const { data: survey, execute: executeSurveyOne, isLoading: isLoadingSurvey, errorMessage: surveyErrorMessage } = useAPISurveyOneGET();
  const { data: diagnosis, execute: executeDiagnosis, isLoading: isLoadingSurveyDiagnosis, errorMessage: surveyDiagnosisErrorMessage } = useAPISurveyDiagnosisGET();
  const isLoading = isLoadingDiagnosis || isLoadingSurvey || isLoadingSurveyDiagnosis;
  const errorMessage = diagnosisErrorMessage || surveyErrorMessage || surveyDiagnosisErrorMessage;

  const { isAuthenticated } = useAuth0();
  const [roles, setRoles] = useState<RoleKey[]>([]); // TODO: temp fix.
  const { updateRoles } = useRoles(); // TODO: roles undefined. state修正してから、これを使うこと。
  const licensed = roles && roles.includes('dandle');

  const executeConditionalSurveyOne = () => {
    if (surveyId) {
      executeSurveyOne({ surveyId });
    }
  };
  const executeConditionalDiagnosis = () => {
    if (surveyId && diagnosisId) {
      executeDiagnosis({ surveyId, diagnosisId });
    }
  };
  const execute = () => {
    updateRoles();
    executeConditionalSurveyOne();
    executeConditionalDiagnosis();
  };

  useEffect(() => {
    executeConditionalSurveyOne();
  }, [surveyId]);
  
  useEffect(() => {
    executeConditionalDiagnosis();
  }, [diagnosisId]);

  useEffect(() => {
    updateRoles().then((r) => setRoles(r));
  }, [isAuthenticated, updateRoles]);

  // GOOGLE TAG MANAGER. GUESTのみ.
  useEffect(() => {
    const isGuest = !diagnosisId && localDiagnosis;
    if (isGuest && surveyId) {
      const data = {
        dataLayer: {
            event: GOOGLE_TAG_MANAGER_EVENT_ITEMS.SURVEY_RESPONSE_GUEST, // event name declared during initialization
            surveyId,
            // add more data you want here
        }
      };
      console.debug('GOOGLE_TAG_MANAGER', { data });
      TagManager.dataLayer(data);
    }
  }, [surveyId, diagnosisId, localDiagnosis]);

  // プロジェクト診断結果を取得
  let result: SurveyDiagnosisGetResponseDto | null;
  if (localDiagnosis) {
    result = localDiagnosis;
  } else {
    result = diagnosis;
  }

  console.debug('SurveysResultsPage', { result });

  if (isLoading || errorMessage || !result || !survey) {
    return <APIGetHandler settings={{ name: 'SurveysResultsPage', isLoading, errorMessage, retry: () => execute() }} />
  }
  const projectId = result.project ? result.project.id : undefined;
  const surveyName = survey.name;
  console.debug('SurveysResultsPage params', { roles, isAuthenticated, licensed, projectId, diagnosisId });

  return (
    <>
      <Title
        title={SURVEYS_TITLE}
        subTitle={surveyName}
        subTitle2="診断結果"
        icon="content_paste"
      />
      <Box sx={{ width: "80%", mx: "auto" }}>
        <Title
          title={`${surveyName}結果`}
          size="subtitle1"
          icon="playlistAddCheckIcon"
        />
        {isAuthenticated && licensed && diagnosisId && (
          <SaveBox
            diagnosisId={diagnosisId}
            projectId={projectId}
          />
        )}
        {!isAuthenticated && <AlertBox />}
        <ResultBox data={result} />
        
      </Box>
    </>
  );
};

export default SurveysResultsPage;
