import { useState } from "react";
import { Modal, BasicTextField, CustomButton } from "components";
import { formatDate } from "models";
// import { ALERTS_SETTINGS } from "constants";
import { ALERTS_SETTINGS, IDS } from "constants/constants";
import { AlertsGetResponseDto } from "types/api";
import { copyToClipboard } from "utilities";
import { MODAL_STYLE } from "styles/constants";

interface AgendaProps {
  alerts: AlertsGetResponseDto;
  projectName: string;
}

const createAgendaText = (alerts: AlertsGetResponseDto, projectName: string, now: string) => {
  // 要注意タスクをmarkdown形式に変換
  const tasks = (Object.keys(alerts) as (keyof typeof alerts)[]).map((key) => {
    const alertsItem = ALERTS_SETTINGS.find(
      (alertsItem) => alertsItem.value === key
    );
    if (!alertsItem) {
      throw new Error('No alerts item');
    }
    const taskData = alerts[key].map((alert) => {
      return `- [${alert.name}](${alert.taskUrl})\n`;
    });
    const task =
      taskData.length !== 0
        ? `${taskData.join("")}\n`
        : `${alertsItem.name}のタスクはありません\n\n`;
    return `## ${alertsItem.name}のタスク\n${task}`;
  });
  const text = `# ${now} ${projectName}のアジェンダ\n\n${tasks.join("")}`;

  return text;
}

const style = {
  ...MODAL_STYLE,
  width: "650px",
};

const Agenda = ({ alerts, projectName }: AgendaProps) => {
  //「アジェンダを生成する」ボタン押下時、モーダル表示
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const now = formatDate(new Date()); //今日の日付
  const text = createAgendaText(alerts, projectName, now);
  const copyTextToClipboard = () => copyToClipboard(text);

  return (
    <>
      <CustomButton
        id={IDS.GENERATE_AGENDA_BUTTON}
        text="アジェンダを生成する"
        icon="addCircle"
        onClick={handleOpen}
      />
      {open && (
        <Modal
          title="アジェンダ"
          buttonText="クリップボードにコピー"
          open={open}
          onClick={copyTextToClipboard}
          onClose={handleClose}
          sx={style}
        >
          <BasicTextField values={text} multiline={true} textarea={true} />
        </Modal>
      )}
    </>
  );
};

export default Agenda;
