import { Box } from "@mui/material";
import { Title, Loading } from "components";
import { useProject } from "hooks";
import AlertsPageTable from "./block/AlertsPageTable";
import { ALERTS_SETTINGS } from "constants/constants";
import { useEffect } from "react";
import { useAPIAlertsGET } from "api";
import { PROJECT_ALERTS_TITLE } from "constants/messages";
import APIGetHandler from "components/APIGetHandler";
import { taskToTaskDates } from "utilities";
import { TaskDto } from "types/api";

const AlertsPage = () => {
  const { project, projectId, setProjectFromParams, isLoading, execute: executeGETProjects, errorMessage: projectsErrorMessage } = useProject();
  useEffect(() => setProjectFromParams(), [setProjectFromParams]);

  // 要注意タスク一覧取得処理
  const { data: alerts, execute: executeGETAlerts, errorMessage: alertsErrorMessage } = useAPIAlertsGET();

  const execute = async () => {
    executeGETProjects();
    executeGETAlerts({ projectId });
  };
  useEffect(() => {
    if (!projectId) {
      return console.warn('projectIdがまだ準備してないのでexecuteを無視する。');
    }
    execute();
  }, [projectId])

  const errorMessage = projectsErrorMessage || alertsErrorMessage;
  if (isLoading || errorMessage || !project || !alerts) {
    return <APIGetHandler settings={{ name: 'AlertsPage', isLoading, errorMessage, retry: () => execute() }} />
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
        }}
      >
        <Title title={project.name} subTitle={PROJECT_ALERTS_TITLE} icon="list" />
      </Box>

      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        {ALERTS_SETTINGS.map((alertsObject, index) => {
          const { name, value } = alertsObject;
          const nameValue = { name, value };
          const tasks: TaskDto[] = alerts[value].map(a => {
            const { startDate, dueDate, updated } = taskToTaskDates(a);

            return {
              ...a,
              startDate,
              dueDate,
              updated,
            };
  
          });
          
          return (
            <AlertsPageTable
              key={value}
              category={nameValue}
              alerts={tasks}
              index={index}
            />
          );
        })}
      </Box>
    </>
  );
};

export default AlertsPage;
