import { BasicTable, CustomLinkButton } from "components";
import { TableRow, TableCell } from "@mui/material";
import { formatDate } from "models";
import { DYNAMIC_ROUTES } from "constants/routes";
import { DiagnosesResponseDto } from "types/api";
import { DATA_CLASSES, HEADERS } from "constants/constants";

const headersFree = [
  HEADERS.CREATED_DATE,
  HEADERS.SURVEY,
  HEADERS.LOOK,
];

const headers = [
  HEADERS.CREATED_DATE,
  HEADERS.SURVEY,
  HEADERS.PROJECT_NAME,
  HEADERS.LOOK,
];

interface RowProps {
  row?: DiagnosesResponseDto;
  hasProject: boolean;
}

interface SurveysHistoryTableProps {
  rows: DiagnosesResponseDto[];
}

const Row = ({ row, hasProject }: RowProps) => {
  const date = row && formatDate(new Date(row.createdAt));

  return row ? (
    <TableRow sx={{ "&:last-child td": { border: "none" } }}>
      <TableCell sx={{ textAlign: "center" }}>{date}</TableCell>
      <TableCell sx={{ textAlign: "center" }}>{row.survey.name}</TableCell>
      {hasProject && (
        <TableCell sx={{ textAlign: "center" }}>{row.project?.name || ''}</TableCell>
      )}
      <TableCell sx={{ textAlign: "center" }}>
        <CustomLinkButton
          className={DATA_CLASSES.DIAGNOSIS_ITEM_LINK}
          text="診断結果を見る"
          to={DYNAMIC_ROUTES.SURVEY_DIAGNOSIS(row.survey.id, row.id)}
          variant="text"
        />
      </TableCell>
    </TableRow>
  ) : (
    <TableRow sx={{ "&:last-child td": { border: "none" } }}>
      <TableCell
        align="center"
        colSpan={hasProject ? headers.length : headersFree.length}
      >
        診断履歴がありません。
      </TableCell>
    </TableRow>
  );
};

const SurveysHistoryTable = ({ rows }: SurveysHistoryTableProps) => {
  const hasProject = rows.filter((r) => r.project).length > 0;
  return (
    <BasicTable headers={hasProject ? headers : headersFree}>
      {rows.length !== 0 ? (
        rows.map((row: DiagnosesResponseDto, index: number) => (
          <Row key={`row-${index}`} row={row} hasProject={hasProject} />
        ))
      ) : (
        <Row hasProject={hasProject} />
      )}
    </BasicTable>
  );
};

export default SurveysHistoryTable;
