import { Text, Chart } from "components";
import { Box, Typography } from "@mui/material";
import { SURVEY_RESULTS_PAGE_BOX_BACKGROUND_COLOR, SURVEY_RESULTS_PAGE_BOX_BORDER_COLOR } from "styles/constants";
import { SurveyDiagnosisGetResponseDto } from "types/api";
import { filterImportantSurveyDiagnosisData } from "./helpers";

const ResultChart = ({ data }: { data: SurveyDiagnosisGetResponseDto }) => {
    const results = filterImportantSurveyDiagnosisData(data);

    // カテゴリー配列
    const categories = results.map((result) => {
        return result.category;
    });
    // ポイント配列
    const points = results.map((result) => {
        return result.point;
    });
    // コメント配列
    const comments = results.map((result) => {
        return result.comment;
    });

    return (
        <Box sx={{ display: "flex" }}>

            <Box sx={{ width: "70%" }}>
            <Chart label="今回の結果" labels={categories} data={points} />
            </Box>

            <Box sx={{ mt: 3, ml: 2 }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box
                sx={{
                    width: 70,
                    height: 20,
                    background: SURVEY_RESULTS_PAGE_BOX_BACKGROUND_COLOR,
                    border: `2px solid ${SURVEY_RESULTS_PAGE_BOX_BORDER_COLOR}`,
                }}
                />
                <Text text="今回の結果" />
            </Box>
            {comments.map((elem, index) => (
                <Typography key={`comment-${index}`}>{elem}</Typography>
            ))}
            </Box>
        </Box>
    );
};

export default ResultChart;
