import { BasicTable } from "components";
import { TableRow, TableCell } from "@mui/material";
import { TextItemDto } from "types/api";
import { HEADERS } from "constants/constants";
import { REGISTER_TOOL_REQUEST_TEXT } from "constants/messages";

const headers = [
  HEADERS.QUESTION,
  HEADERS.DIAGNOSIS,
];

interface RowProps {
  row?: TextItemDto;
  data?: boolean;
}

interface ResultTableProps {
  rows: TextItemDto[];
}

const Row = ({ row, data = true }: RowProps) => {
  return row && data ? (
    <TableRow sx={{ "&:last-child td": { border: 0 } }}>
      <TableCell align="center">{row.question.body}</TableCell>
      <TableCell align="center">{row.answer}</TableCell>
    </TableRow>
  ) : <></>;
};

const ResultTable = ({ rows }: ResultTableProps) => {
  
  return (
    <BasicTable headers={headers} boxShadow={false} headerStyle={{ th: { width: '50%', fontWeight: 'bold' } }}>
      {rows.length !== 0 ? (
        rows.map((data, index: number) => <Row key={index} row={data} />)
      ) : (
        <Row data={false} />
      )}
    </BasicTable>
  );
};

export default ResultTable;
