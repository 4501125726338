import React, { useCallback, useState } from "react";
import {
  Box,
  Modal,
  Typography,
  IconButton,
  TextField,
  InputAdornment,
  SxProps,
  Theme,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import CloseIcon from "@mui/icons-material/Close";
import { SubmitHandler, useForm } from "react-hook-form";
import { CustomButton, Loading } from "components";
import { FormInputType, FormType, ValueType } from "types";
import FormSelect from "./Select";
import FormCheck from "./Check";
import { FORM_MODAL_CLOSE_BUTTON_COLOR, FORM_MODAL_ERROR_TEXT_COLOR, MODAL_STYLE } from "styles/constants";
import FileUploadArea from "components/FileUploadArea";
import { IDS } from "constants/constants";

interface FormModalProps {
  title: string;
  buttonText: string;
  open: boolean;
  onSubmit: SubmitHandler<FormInputType>;
  onClose: () => void;
  onBack?: () => void;
  labels: Array<FormType>;
  values?: any; // TODO: 確認待ち. ValueType;
  defaultValues?: Record<string, any>, // { [NAME]: [VALUE] } OR { [NAME]: [VALUE][] }
  selectValues?: any; // SelectType[] | SelectType[][];
  selects?: boolean; // TODO: RoutinesCalendarのみ? RoutinesCalendarでも配列を利用しているため、なんで設定しているかを調査すること。
  error?: string | null;
  loading?: boolean;
  sx?: SxProps<Theme>;
  externalLabel?: boolean;
}

const BackButton = ({ onClick }: { onClick: () => void }) => (
  <CustomButton
    text="戻る"
    onClick={onClick}
    variant="outlined"
    sx={{ mr: 1 }}
  />
);

const SubmitFormControls = ({ onSubmit, onBack, submitButtonText }: { onBack: (() => void) | undefined; submitButtonText: string; onSubmit: ((event: React.MouseEvent<HTMLElement, MouseEvent>) => void) | undefined }) => {
  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      {onBack && (<BackButton onClick={onBack} />)}
      <CustomButton
        text={submitButtonText}
        sx={{ flexGrow: 1 }}
        onClick={onSubmit}
      />
    </Box>
  );
};

const InputLabel = ({ label, required } : { label: string; required: boolean; }) => {
  return (
    <Typography sx={{ display: 'block', margin: '5px', textAlign: 'left', width: '100%', fontWeight: 'bolder' }}>
      {label}<span style={{ color: 'red' }}>{required ? '*' : ''}</span>
    </Typography>
  );
};

const createItemKey = (i: number) => `item-${i}`;

const BasicFormModal = ({
  buttonText,
  title,
  open,
  onSubmit,
  onClose,
  onBack,
  labels,
  values,
  defaultValues,
  selectValues,
  selects = false,
  error,
  loading,
  sx,
  externalLabel,
}: FormModalProps) => {
  const {
    control,
    getValues,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    setError,
  } = useForm<FormInputType>({
    // defaultValues: defaultValues || {}
  });
  // console.debug({ defaultValues });

  const [valueDatas, setValueDatas] = useState<ValueType>(values ? values : {});
  const handleChange = useCallback((e: SelectChangeEvent) => {
    const target = e.target;
    const { name, value } = target;
    setValueDatas({ ...valueDatas, [name]: value });
    setValue(name, value); // TODO: 仮対応。なぜかSelectは選択で値が変わっていない。
    console.debug('handleChange', { e, name, value });
  }, [setValue, valueDatas]);

  const style = {
    ...MODAL_STYLE,
    p: 4,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    ...{
      '.MuiInputBase-input.MuiInput-input': {
        border: '1px solid lightgray',
        'borderRadius': '5px',
      },
      '.MuiInputBase-root.MuiInput-root.MuiInput-underline:before': {
        borderBottom: 'none',
      },
    },
    ...(sx || {}),
  } as SxProps<Theme> | undefined;

  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: FORM_MODAL_CLOSE_BUTTON_COLOR,
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            id={IDS.MODAL_TITLE}
            variant="h6"
            component="h2"
            sx={{ mb: 2 }}
          >
            {title}
          </Typography>
          {loading ? (
            <Loading name="FormModal" />
          ) : (
            <>
              {labels.map((label: FormType, i: number) => {
                const { type } = label;
                const key = createItemKey(i);
                const props = label.props || {};

                /*
                Input Label 仕様:
                - InputLabel
                - FormLabel
                - Component内部(TextField + label + variant)

                Labelを項目の上に表示:
                - externalLabel
                - variant="standard"
                */

                const Input = () => type === "select" ? (
                  <FormSelect
                    key={key}
                    selectValues={selects ? selectValues[i] : selectValues}
                    selects={selects}
                    label={label}
                    register={register}
                    errors={errors}
                    valueDatas={valueDatas}
                    handleChange={handleChange}
                    {...props}
                  />
                ) : type === "check" ? (
                  <FormCheck
                    key={key}
                    selectValues={selects ? selectValues[i] : selectValues}
                    label={label}
                    register={register}
                    control={control}
                    getValues={getValues}
                    errors={errors}
                    {...props}
                  />
                ) : type === "attachments" ? (
                  <FileUploadArea
                    key={key}
                    label={!externalLabel ? label.name : ''}
                    name={label.value}
                    register={register}
                    {...props}
                  />
                ) : (
                  <TextField
                    key={key}
                    multiline={type === 'textArea'}
                    rows={type === 'textArea' ? 3 : 1}
                    fullWidth
                    sx={{ mb: 1 }}
                    required={label.required}
                    id={label.value}
                    label={label.name}
                    value={values && values[label.value]}
                    error={errors[label.value] ? true : false}
                    helperText={errors[label.value]?.message}
                    InputProps={
                      label.InputPropsStart || label.InputPropsEnd
                        ? {
                            startAdornment: (
                              <InputAdornment position="start">
                                {label.InputPropsStart}
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <InputAdornment position="end">
                                {label.InputPropsEnd}
                              </InputAdornment>
                            ),
                          }
                        : undefined
                    }
                    {...register(label.value, {
                      required: label.errorMessage,
                    })}
                    {...props}
                  />
                );

                return (
                  <React.Fragment key={key}>
                    { externalLabel ? <InputLabel label={label.name} required={label.required} /> : <></> }
                    <Input />
                  </React.Fragment>
                );
              })}
              <Typography
                sx={{
                  mb: 1,
                  color: FORM_MODAL_ERROR_TEXT_COLOR,
                  whiteSpace: "pre-wrap",
                  textAlign: "center",
                }}
              >
                {error}
              </Typography>
              <SubmitFormControls onBack={onBack} submitButtonText={buttonText} onSubmit={handleSubmit(onSubmit)}  />
            </>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default BasicFormModal;
