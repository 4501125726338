import { AlertColor } from "@mui/material";
import CustomSnackbar from "components/CustomSnackbar";
import React from "react";
import { useState, useRef } from "react";
import { SnackbarContext } from "./globalSnackbarContext";

export interface GlobalSnackbarState { message: string; severity: AlertColor }
export const createGlobalSnackbarState = (options: Partial<GlobalSnackbarState> = {}): GlobalSnackbarState => ({
  message: '',
  severity: 'info',
});

const ChildWrapper = ({ children }: { children: any }) => {
  return (
    <>
      {children}
    </>
  );
};
const StaticChildrenWrapper = React.memo(ChildWrapper);

const GlobalSnackbar = ({ children }: { children: any }) => {
  const [globalSnackbar, setGlobalSnackbar] = useState<GlobalSnackbarState>(createGlobalSnackbarState());
  const snackbarContextValue = useRef({ globalSnackbar, setGlobalSnackbar }).current;
  
  console.debug('GlobalSnackbar render');

  const { message, severity } = globalSnackbar;

  return (
    <SnackbarContext.Provider value={snackbarContextValue}>
      <CustomSnackbar message={message} severity={severity} />
      <StaticChildrenWrapper children={children} />
    </SnackbarContext.Provider>
  );
};

export default GlobalSnackbar;
