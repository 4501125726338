import Attachments from "components/Attachments";
import { FileLink } from "types";

const DeskUploads = ({ uploads } : { uploads?: { content_url?: string }[] }) => {
  const attachments: FileLink[] = (uploads || []).map((upload) => {
    const contentUrl = upload.content_url || '';
    return {
      filename: (new URL(contentUrl)).searchParams.get('name') || contentUrl, // EXAMPLE: "https://andgate.zendesk.com/attachments/token/YGWHXqHcXjtXkphL96Vs6JFoZ/?name=logo512.png"
      filepath: contentUrl,
    };
  });
  return (
    <Attachments attachments={attachments} />
  );
};

export default DeskUploads;
