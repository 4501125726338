import { Title, CustomButton } from "components";
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  FormControl,
  TextField,
  // Select,
  // MenuItem,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { IDS, TASK_LIST_SEARCH_FIELDS } from "constants/constants";
import { PROJECT_TASKS_PART_TITLES } from "constants/messages";
import { getRandomId } from "utilities";

interface SearchBoxProps {
  onSubmit: any;
}
// const selects = ["未対応", "処理中"];

const DateRangeSearchItem = ({ label, startDateName, endDateName }: { label: string; startDateName: string; endDateName: string }) => {
  const sx = {
    BOX: { display: "flex", alignItems: "center", mb: 1 },
    LABEL: { minWidth: "50px" },
    FIELD: { mx: 1 },
  };
  return (
    <Box sx={sx.BOX}>
      <Typography sx={sx.LABEL}>{label}</Typography>
      <TextField name={startDateName} type="date" sx={sx.FIELD} />
      ~
      <TextField name={endDateName} type="date" sx={sx.FIELD} />
    </Box>
  );
};

const SearchBox = ({ onSubmit }: SearchBoxProps) => {
  const id = getRandomId();
  return (
    <Box sx={{ ml: 1, overflow: 'auto' }}>
      <Box sx={{ height: "50px", display: "flex", alignItems: "center" }}>
        <Title
          title={PROJECT_TASKS_PART_TITLES.TASK_SEARCH}
          icon="search"
          size="subtitle1"
          sx={{ my: 3 }}
        />
      </Box>
      <div>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`${id}-content`}
            id={`${id}-header`}
          >
            <Typography>{PROJECT_TASKS_PART_TITLES.TASK_SEARCH}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box component="form" onSubmit={onSubmit}>
              <FormControl sx={{ display: "flex", flexFlow: "unset", mb: 1 }}>
                <Box sx={{ width: "60%" }}>
                  <DateRangeSearchItem label="作成日" startDateName={TASK_LIST_SEARCH_FIELDS.START_DATE_1} endDateName={TASK_LIST_SEARCH_FIELDS.START_DATE_2} />
                  <DateRangeSearchItem label="期限日" startDateName={TASK_LIST_SEARCH_FIELDS.DUE_DATE_1} endDateName={TASK_LIST_SEARCH_FIELDS.DUE_DATE_2} />
                  <DateRangeSearchItem label="更新日" startDateName={TASK_LIST_SEARCH_FIELDS.UPDATED_1} endDateName={TASK_LIST_SEARCH_FIELDS.UPDATED_2} />
                </Box>
                <Box sx={{ width: "40%", ml: 2 }}>
                  <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                    <Typography sx={{ minWidth: "90px" }}>
                      キーワード
                    </Typography>
                    <TextField
                      name={TASK_LIST_SEARCH_FIELDS.KEYWORD}
                      sx={{ ml: 1, width: "calc(100% - 90px)" }}
                    />
                  </Box>
                  {/* <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography sx={{ minWidth: "90px" }}>
                      ステータス
                    </Typography>
                    <Select
                      labelId="demo-simple-select-label"
                      // id={item.value}
                      name={TASK_LIST_SEARCH_FIELDS.STATUS}
                      // value={value ? value : ""}
                      // label={item.name}
                      onChange={() => {}}
                      // onChange={handleChange}
                      sx={{ width: "calc(100% - 90px)", ml: 1 }}
                    >
                      {selects.map((select, index: number) => (
                        <MenuItem key={`select-${index}`} value={select}>
                          {select}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box> */}
                </Box>
              </FormControl>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <CustomButton id={IDS.TASK_SEARCH_BUTTON} text="検索する" submit={true} />
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
      </div>
    </Box>
  );
};

export default SearchBox;
