import { useEffect, useMemo, useState } from "react";
import { SubmitHandler } from "react-hook-form";
import { Box, TableRow, TableCell, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { ValuesType, FormInputType, TypedFormInputType, SuccessFailureResponse } from "types";
import {
  BasicTable,
  Title,
  FormModal,
  CustomButton,
  Loading,
} from "components";
import { useAPIToolPOST, useAPIToolsDELETE, useBacklogAPIProjectsGET } from "api";
import ErrorModal from "components/ErrorModal";
import DeleteModal from "components/DeleteModal";
import { getDeleteTargetTitleText, PROJECT_SETTINGS_PART_TITLES, REGISTER_TOOL_REQUEST_TEXT } from "constants/messages";
import { BacklogProject, ExternalToolDataDto, ToolGetResponseDto } from "types/api";
import { DATA_CLASSES, HEADERS, IDS, LABELS } from "constants/constants";
import { Edit as EditIcon } from "@mui/icons-material";
import UpdateCompleteStatusesModal from "./UpdateCompleteStatusesModal";
import { StatusMap, getCurrentStatusesMap, getCurrentToolStatusInfo, parseCurrentToolCompleteStatuses } from "./CompleteStatusesCommon";
import useBacklogAPIStatuses from './useBacklogAPIStatuses';

const headers = [
  HEADERS.TOOL_NAME,
  HEADERS.EXTERNAL_PROJECT_NAME,
  HEADERS.COMPLETE_STATUSES,
  HEADERS.EDIT,
  HEADERS.DELETE,
];

const nextLabels = [
  LABELS.TENANT_URL,
  LABELS.API_KEY,
];

const submitLabels = [
  LABELS.PROJECT,
  LABELS.TOOL_NAME,
];

interface ToolsProps {
  rows: ToolGetResponseDto[];
  projectId: string;
  requestUpdate: () => void;
}

type ToolsApiType = {
  tenantUrl: string | null;
  apiKey: string | null;
  projects: BacklogProject[];
};

type NextToolData = { tenantUrl: string; apiKey: string };
type SubmitData = { projects: string; toolName: string };

const createExternalToolData = (data: SubmitData, type: 'backlog', apiDatas: ToolsApiType | undefined) => {
  let externalData: ExternalToolDataDto;
  if (type === 'backlog') {
    const externalProjectId = String(data.projects);
    const externalProjectsMatches = apiDatas?.projects.filter((item) => `${item.id}` === externalProjectId);
    externalData = {
      tenantUrl: String(apiDatas?.tenantUrl),
      apiKey: String(apiDatas?.apiKey),
      projectId: externalProjectId,
      projectName: externalProjectsMatches && externalProjectsMatches.length > 0 ? externalProjectsMatches[0]?.name : '',
    };
  } else {
    throw new Error(`Invalid type ${type}`);
  }

  return externalData;
};

const getToolProjects = async (data: NextToolData, type: 'backlog', mutateBacklog: ReturnType<typeof useBacklogAPIProjectsGET>['execute']) => {
  let response: SuccessFailureResponse<BacklogProject[]> | undefined = undefined;

  if (type === 'backlog') {
    const tenantUrl = data.tenantUrl;
    const apiKey = data.apiKey;
    // response = await mutateBacklog({ tenantUrl, apiKey });
    response = await mutateBacklog({ tenantUrl, apiKey }, true);
    if (!response) throw new Error('No response');
    // backlogAPI通信後モーダル閉じる。成功時次のモーダル表示、失敗時エラーモーダル表示
  }

  return response;
};

/**
 * プロジェクト管理ツール画面
 */
const Tools = ({ rows, projectId, requestUpdate }: ToolsProps) => {
  const [apiDatas, setApiDatas] = useState<ToolsApiType>(); // 入力データ
  const [tool, setTool] = useState({ id: "", name: "" }); // プロジェクト管理ツールid
  const [errorMessage, setErrorMessage] = useState<string | null>(null); // エラーメッセージ
  const { mutate: mutatePostTool, isLoading: isLoadingPostTool } = useAPIToolPOST(); // プロジェクト管理ツール登録
  // const { mutate: mutateBacklog, isLoading: isLoadingBacklog } = useBacklogAPIProjectsGET(); // backlogApi通信
  const { execute: mutateBacklog, isLoading: isLoadingBacklog } = useBacklogAPIProjectsGET(); // backlogApi通信
  const { mutate: mutateDeleteTool, isLoading: isLoadingDeleteTool } = useAPIToolsDELETE(); // プロジェクト管理ツール削除

  //「プロジェクト管理ツールを登録する」ボタン押下時、モーダル表示
  const [tool1ModalOpen, setTool1ModalOpen] = useState(false); //「プロジェクト管理ツールを登録する」押下時のモーダル
  const handleTool1ModalOpen = () => setTool1ModalOpen(true);
  const handleTool1ModalClose = () => {
    setTool1ModalOpen(false);
    setErrorMessage("");
  };
  //「次へ」ボタン押下時、モーダル表示
  const [tool2ModalOpen, setTool2ModalOpen] = useState(false);
  const handleTool2ModalOpen = () => setTool2ModalOpen(true);
  const handleTool2ModalClose = () => setTool2ModalOpen(false);
  // エラーモーダル表示非表示処理
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const handleErrorModalOpen = () => setErrorModalOpen(true);
  const handleErrorModalClose = () => {
    setErrorModalOpen(false);
    setErrorMessage("");
  };
  // 一覧の削除ボタン押下時、モーダル表示
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const handleOpenDeleteModal = (values: ValuesType) => {
    setTool(values);
    setOpenDeleteModal(true);
  };
  const handleCloseDeleteModal = () => setOpenDeleteModal(false);

  // 編集
  const [editModalOpen, setEditModalOpen] = useState(false);
  const handleEditModalOpen = () => setEditModalOpen(true);
  const handleEditModalClose = () => setEditModalOpen(false);
  const handleOpenEditModal = (values: ValuesType) => {
    setTool(values);
    handleEditModalOpen();
  };
  
  //「戻る」ボタン押下時処理
  const handleBack = () => {
    handleTool2ModalClose();
    handleTool1ModalOpen();
  };
  //モーダル閉じた際、データ保持しない。
  useEffect(() => {
    if ((!tool1ModalOpen && !tool2ModalOpen) || !tool2ModalOpen) {
      setApiDatas(undefined);
    }
  }, [tool1ModalOpen, tool2ModalOpen]);

  const { BacklogStatusesHandler, isLoading: isLoadingBacklogStatuses } = useBacklogAPIStatuses(rows);
  const [currentStatuses, setCurrentStatuses] = useState<StatusMap>({});
  useMemo(() => {
    setCurrentStatuses(getCurrentStatusesMap(rows, tool.id, currentStatuses));
  }, [tool.id, rows]);

  useEffect(() => {
    const updateStatuses = async () => {
      const h = new BacklogStatusesHandler((err) => setErrorMessage(err.message));
      setCurrentStatuses(await h.getStatusesMap());
    };
    updateStatuses();
  }, [rows]);

  /**
   * 「次へ」ボタン押下時、backlogAPI通信処理
   */
  const handleNextTool: SubmitHandler<TypedFormInputType<NextToolData>> = async (data) => {
    const type = 'backlog';
    
    const response = await getToolProjects(data, type, mutateBacklog);

    if (response && response.success) {
      const apiDatas = {
        tenantUrl: data.tenantUrl,
        apiKey: data.apiKey,
        projects: response.data,
      };
      setApiDatas(apiDatas);
      handleTool1ModalClose();
      handleTool2ModalOpen();
    } else {
      setErrorMessage(`通信に失敗しました。\n入力値を確認してください。`);
    }
  };

  /**
   * プロジェクト管理ツール登録処理
   */
  const handleSubmitTool: SubmitHandler<TypedFormInputType<SubmitData>> = async (data) => {
    const type = 'backlog'

    const externalData = createExternalToolData(data, type, apiDatas);

    const response = await mutatePostTool({
      projectId,
      params: {
        name: data.toolName,
        type,
        data: externalData,
      },
    });
    //post終了後モーダル閉じる。失敗時エラー表示
    if (response.success) {
      requestUpdate();
      handleTool2ModalClose();
    } else {
      setErrorMessage("新規プロジェクト管理ツール作成に失敗しました。");
      handleTool2ModalClose();
      handleErrorModalOpen();
    }
  };

  /**
   * プロジェクト管理ツール削除処理
   */
  const handleDeleteTool = async () => {
    const { id } = tool;
    const response = await mutateDeleteTool({ projectId, id });
    //削除後モーダル閉じる。失敗時エラーモーダル表示
    if (response.success) {
      requestUpdate();
      handleCloseDeleteModal();
    } else {
      setErrorMessage("プロジェクト管理ツールの削除に失敗しました。");
      handleCloseDeleteModal();
      handleErrorModalOpen();
    }
  };

  if (isLoadingPostTool || isLoadingDeleteTool) {
    return <Loading name="Tools" />;
  }

  const { currentSelectedToolStatusIds, currentToolStatuses } = getCurrentToolStatusInfo(tool.id, currentStatuses);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Title title={PROJECT_SETTINGS_PART_TITLES.TOOL} icon="list" size="subtitle1" />
        <CustomButton
          id={IDS.CREATE_TOOL_BUTTON}
          text="タスク管理ツールを登録する"
          onClick={handleTool1ModalOpen}
          icon="addCircle"
        />
      </Box>

      {tool1ModalOpen && (
        <FormModal
          title="プロジェクト管理ツール登録"
          buttonText="次へ"
          open={tool1ModalOpen}
          onSubmit={handleNextTool as SubmitHandler<FormInputType>}
          onClose={handleTool1ModalClose}
          labels={nextLabels}
          values={apiDatas}
          error={errorMessage}
          loading={isLoadingBacklog}
        />
      )}
      {apiDatas && tool2ModalOpen && (
        <FormModal
          title="プロジェクト管理ツール登録"
          buttonText="タスク管理ツールを登録する"
          open={tool2ModalOpen}
          onSubmit={handleSubmitTool as SubmitHandler<FormInputType>}
          onClose={handleTool2ModalClose}
          onBack={handleBack}
          labels={submitLabels}
          selectValues={apiDatas.projects}
        />
      )}
      {editModalOpen && (
        <UpdateCompleteStatusesModal
          projectId={projectId}
          toolId={tool.id || ''}
          open={editModalOpen}
          onSubmit={() => requestUpdate()}
          onClose={handleEditModalClose}
          selectValues={currentToolStatuses}
          currentSelectedToolStatusIds={currentSelectedToolStatusIds}
        />
      )}
      <ErrorModal text={errorMessage} open={errorModalOpen} onClose={handleErrorModalClose} />

      <BasicTable headers={headers}>
        {rows.length !== 0 ? (
          rows.map((row) => {
            const completeStatusesString = parseCurrentToolCompleteStatuses(row.settings?.completeStatuses, row.id, currentStatuses);
            console.debug({ completeStatusesString, row, tool, currentStatuses });
            return (
              <TableRow key={row.id} sx={{ "&:last-child td": { border: 0 } }}>
                <TableCell align="center">{row.name}</TableCell>
                <TableCell align="center">{row.data.projectName || ''}</TableCell>
                <TableCell align="center">{completeStatusesString}</TableCell>
                <TableCell>
                  <IconButton
                    onClick={() => {
                      handleOpenEditModal({ id: row.id, name: row.name });
                    }}
                    sx={{ display: "block", p: 0, mx: "auto" }}
                  >
                    <EditIcon />
                  </IconButton>
                  
                </TableCell>
                {/* <TableCell>{row.type}</TableCell> */}
                <TableCell>
                  <IconButton
                    className={DATA_CLASSES.DELETE_TOOL_ITEM_BUTTON}
                    onClick={() => {
                      handleOpenDeleteModal({ id: row.id, name: row.name });
                    }}
                    sx={{ display: "block", p: 0, mx: "auto" }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })
        ) : (
          <TableRow sx={{ "&:last-child td": { border: "none" } }}>
            <TableCell align="center" colSpan={headers.length}>
              {REGISTER_TOOL_REQUEST_TEXT}
            </TableCell>
          </TableRow>
        )}
      </BasicTable>
      <DeleteModal title="プロジェクト管理ツール削除" text={getDeleteTargetTitleText(tool.name, 'タスク名')} open={openDeleteModal} onClick={handleDeleteTool} onClose={handleCloseDeleteModal} />
    </>
  );
};

export default Tools;
