import { useState } from "react";
import { SubmitHandler } from "react-hook-form";
import { Box, TableRow, TableCell, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { FormInputType, TypedFormInputType, ValuesType } from "types";
import {
  Title,
  CustomButton,
  FormModal,
  BasicTable,
  Loading,
} from "components";
import { useAPINotificationPOST, useAPINotificationsDELETE } from "api";
import ErrorModal from "components/ErrorModal";
import DeleteModal from "components/DeleteModal";
import { getDeleteTargetTitleText, REGISTER_BUTTON_TEXT, REGISTER_NOTIFICATION_REQUEST_TEXT } from "constants/messages";
import { NotificationGetResponseDto, ToolGetResponseDto } from "types/api";
import { DATA_CLASSES, HEADERS, IDS, LABELS } from "constants/constants";

const headers = [
  HEADERS.NAME,
  HEADERS.TOOL, // 修正前 name: タスク管理ツール
  HEADERS.DELETE,
];

const labels = [
  LABELS.NAME,
  LABELS.WEBHOOK,
  LABELS.TOOL,
];

interface NotificationsProps {
  rows: NotificationGetResponseDto[];
  projectId: string;
  tools: ToolGetResponseDto[];
  requestUpdate: () => void;
}

/*
 * 通知設定画面
 */
const Notifications = ({
  rows,
  projectId,
  tools,
  requestUpdate,
}: NotificationsProps) => {
  const [notification, setNotification] = useState<ValuesType>({
    id: "",
    name: "",
  });
  const [errorMessage, setErrorMessage] = useState<string | null>(null); // エラーメッセージ
  const { mutate: mutatePostNotifications, isLoading: isLoadingPostNotifications } = useAPINotificationPOST(); //通知設定登録
  const { mutate: mutateDeleteNotifications, isLoading: isLoadingDeleteNotifications } = useAPINotificationsDELETE(); //通知設定削除

  // 「通知設定を登録する」ボタン押下時、モーダル表示
  const [submitModalOpen, setSubmitModalOpen] = useState(false);
  const handleSubmitModalOpen = () => setSubmitModalOpen(true);
  const handleSubmitModalClose = () => {
    setSubmitModalOpen(false);
    setErrorMessage("");
  };
  // 一覧の削除ボタン押下時、モーダル表示
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const handleOpenDeleteModal = (values: ValuesType) => {
    setOpenDeleteModal(true);
    setNotification(values);
  };
  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };
  // エラーモーダル表示非表示処理
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const handleErrorModalOpen = () => setErrorModalOpen(true);
  const handleErrorModalClose = () => {
    setErrorModalOpen(false);
    setErrorMessage("");
  };

  /**
   * 通知設定登録処理
   */
  const handleSubmit: SubmitHandler<TypedFormInputType<{ name: string; tool: string; webhookUrl: string; }>> = async (data) => {
    const response = await mutatePostNotifications({
      projectId,
      params: {
        name: data.name,
        tool: data.tool,
        type: "slack",
        webhookUrl: data.webhookUrl,
      },
    });
    //post終了後モーダル閉じる。失敗時エラー表示
    if (response.success) {
      requestUpdate();
      handleSubmitModalClose();
    } else {
      setErrorMessage(`通信に失敗しました。\n入力値を確認してください。`);
    }
  };

  /**
   * 通知設定削除処理
   */
  const handleDelete = async () => {
    const response = await mutateDeleteNotifications({ projectId, id: notification.id });
    //削除後モーダル閉じる。失敗時エラーモーダル表示
    if (response.success) {
      requestUpdate();
      handleCloseDeleteModal();
    } else {
      setErrorMessage("通知設定の削除に失敗しました。");
      handleCloseDeleteModal();
      handleErrorModalOpen();
    }
  };

  if (isLoadingDeleteNotifications) {
    return <Loading name="Notifications" />;
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Title title="通知設定一覧" icon="list" size="subtitle1" />
        <CustomButton
          id={IDS.CREATE_NOTIFICATION_BUTTON}
          text="通知設定を登録する"
          onClick={handleSubmitModalOpen}
          icon="addCircle"
        />
      </Box>
      {submitModalOpen && (
        <FormModal
          title="通知設定登録"
          buttonText={REGISTER_BUTTON_TEXT}
          open={submitModalOpen}
          onSubmit={handleSubmit as SubmitHandler<FormInputType>}
          onClose={handleSubmitModalClose}
          labels={labels}
          selectValues={tools}
          error={errorMessage}
          loading={isLoadingPostNotifications}
        />
      )}
      <ErrorModal text={errorMessage} open={errorModalOpen} onClose={handleErrorModalClose} />

      <BasicTable headers={headers}>
        {rows.length !== 0 ? (
          rows.map((row, index: number) => (
            <TableRow key={index} sx={{ "&:last-child td": { border: 0 } }}>
              <TableCell align="center">{row.name}</TableCell>
              <TableCell align="center">{row.tool.name}</TableCell>
              <TableCell align="center">
                <IconButton
                  className={DATA_CLASSES.DELETE_NOTIFICATION_ITEM_BUTTON}
                  onClick={() => {
                    handleOpenDeleteModal({ id: row.id, name: row.name });
                  }}
                  sx={{ display: "block", p: 0, mx: "auto" }}
                >
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))
        ) : (
          <TableRow sx={{ "&:last-child td": { border: "none" } }}>
            <TableCell align="center" colSpan={headers.length}>
              {REGISTER_NOTIFICATION_REQUEST_TEXT}
            </TableCell>
          </TableRow>
        )}
      </BasicTable>
      <DeleteModal title="通知設定削除" text={`${getDeleteTargetTitleText(notification.name, '通知名')}`} open={openDeleteModal} onClick={handleDelete} onClose={handleCloseDeleteModal} />
    </>
  );
};

export default Notifications;
