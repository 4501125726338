import Modal from "./Modal";

const DeleteModal = ({ title, text, open, onClose, onClick }: { title?: string | null; text?: string | null; open: boolean; onClose: () => void; onClick: () => void; }) => {
  return (
    <>
      {open && (
        <Modal
          title={title || ''}
          text={text}
          buttonText="削除する"
          open={open}
          onClick={onClick}
          onClose={onClose}
          buttonColor="error"
        />
      )}
    </>
  );
};

export default DeleteModal;
