import { checkIsTruthy as t } from 'utilities';

/**
 * 使っている場所:
 * <Route path=
 * to=
 * navigate(
 * 
 * id一覧:
 * projectId
 * surveyId
 * diagnosisId
 * courseId
 */
export const ROUTES = {
    HOME: '/',
    DASHBOARD: '/dashboard',
    PROJECT_SETTINGS: '/projects/:projectId/settings',
    PROJECT_TASKS: '/projects/:projectId/tasks',
    PROJECT_ALERTS: '/projects/:projectId/tasks/alerts',
    BOOKMARKS: '/projects/:projectId/bookmarks',
    SURVEYS: '/surveys',
    SURVEY: '/surveys/:surveyId',
    SURVEY_DIAGNOSIS: '/surveys/history/:surveyId/diagnosis/:diagnosisId',
    SURVEY_DIAGNOSIS_NO_ID: '/surveys/history/:surveyId/diagnosis/',
    SURVEYS_HISTORY: '/surveys/history',
    ACADEMY_COURSES: '/academy/courses',
    ACADEMY_COURSE: '/academy/courses/:courseId',
    WALL_ENTRANCE: '/wall/entrance',
    DESK_LIST: '/desk',
    DESK_ITEM: '/desk/:ticketId',
};

export const DYNAMIC_ROUTES = {
    PROJECT_SETTINGS: (projectId: string) => t({ projectId }, () => ROUTES.PROJECT_SETTINGS.replace(':projectId', projectId)),
    PROJECT_TASKS: (projectId: string) => t({ projectId }, () => ROUTES.PROJECT_TASKS.replace(':projectId', projectId)),
    PROJECT_ALERTS: (projectId: string) => t({ projectId }, () => ROUTES.PROJECT_ALERTS.replace(':projectId', projectId)),
    SURVEY: (surveyId: string) => t({ surveyId }, () => ROUTES.SURVEY.replace(':surveyId', surveyId)),
    /**
     * diagnosisIdはfalsyでもよい。ログインなしで利用する場合、APIから戻るデータにdiagnosis.idがないため。
     */
    SURVEY_DIAGNOSIS: (surveyId: string, diagnosisId?: string | null) => t({ surveyId }, () => ROUTES.SURVEY_DIAGNOSIS.replace(':surveyId', surveyId).replace(':diagnosisId', diagnosisId || '')),
    ACADEMY_COURSE: (courseId: string) => t({ courseId }, () => ROUTES.ACADEMY_COURSE.replace(':courseId', courseId)),
    DESK_ITEM: (ticketId: string) => t({ ticketId }, () => ROUTES.DESK_ITEM.replace(':ticketId', ticketId)),
    BOOKMARKS: (projectId: string) => t({ projectId }, () => ROUTES.BOOKMARKS.replace(':projectId', projectId)),
};
