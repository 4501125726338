import { Box, Card, CardContent, CardHeader, Link, List, ListItem, Typography } from "@mui/material";
import { FileLink } from "types";
import { DeskTicketGetResponseDto } from "types/api";
import { format } from 'date-fns';
import MarkdownComponent from "components/MarkdownComponent";
import DeskUploads from "./DeskUploads";

const DeskItemResponse = ({ comment }: { comment: DeskTicketGetResponseDto['comments'][number] }) => {
  const { body, createdAt, createdBy, uploads } = comment;
  /*
  const { body, createdAt, value } = {
      createdBy: 'Kazuto Morita',
      createdAt: '2022/10/12 23:25',
      body: '返信をここに記載する。'
  };
  */

  const DATE_FORMAT = 'yyyy/MM/dd HH:mm';
  const formattedCreatedAt = format(new Date(createdAt), DATE_FORMAT);

  const CardHeaderContent = () => (
    <Box sx={{ textAlign: 'right', padding: '10px', borderBottom: '1px solid lightgray' }}>
      <Typography>{createdBy ? createdBy.name : ''}</Typography>
      <Typography>{formattedCreatedAt}</Typography>
    </Box>
  );

  return (
    <Card sx={{ padding: '10px', margin: '10px' }}>
      <CardHeader component={Box} title={<CardHeaderContent />} />
      <CardContent>
        <Box sx={{ padding: '10px 0', /* borderTop: '1px solid lightgray', */ borderBottom: '1px solid lightgray' }}>
          <MarkdownComponent value={body} pre />
        </Box>
        <DeskUploads uploads={uploads} />
      </CardContent>
    </Card>
  )
};

export default DeskItemResponse;
