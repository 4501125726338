import { useAPICoursesGET } from "api";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getItemFromIdRowList } from "utilities";
import useAppState from "./useAppState";

/**
 * 現在設定されたコース
 */
const useCourse = () => {
    const params = useParams();
    const { courseId } = params;
    if (!courseId) {
        throw new Error('No courseId');
    }

    const [state, setAppState] = useAppState();
    // const { courses } = state;
    // setAppState

    const { data, execute, errorMessage, isLoading } = useAPICoursesGET();
    
    useEffect(() => {
        execute({});
    }, []);

    const course = data ? getItemFromIdRowList(courseId, data) : null;
    return {
        courseId, execute, course, errorMessage, isLoading
    };
};

export default useCourse;
