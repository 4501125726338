/// <reference types="@welldone-software/why-did-you-render" />
import { hijackEffects } from 'stop-runaway-react-effects'
import React from "react";

// DEBUG(開発環境のみ)
export function debugHandling() {
  console.debug('Debug handling activated');
  // Bubbling event 確認
  /*
  document.addEventListener('click', (ev) => {
    console.debug('document.click', ev);
  })
  */
  
  // 開発中にuseEffectのinfinite loopを防ぐために。
  // https://github.com/kentcdodds/stop-runaway-react-effects#api
  hijackEffects();
  // hijackEffects({callCount: 60, timeLimit: 1000});

  /*
  import('@welldone-software/why-did-you-render').then(({ default: whenDidYouReact }) => {
    whyDidYouRender(React, {
      trackAllPureComponents: true,
    });
  });
  */

  // https://github.com/welldone-software/why-did-you-render#setup
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React, {
    trackAllPureComponents: true,
  });
}
