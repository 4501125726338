import List from "@mui/icons-material/List";
import Dashboard from "@mui/icons-material/Dashboard";
import CheckBox from "@mui/icons-material/CheckBox";
import Sort from "@mui/icons-material/Sort";
import CalendarMonth from "@mui/icons-material/CalendarMonth";
import Flag from "@mui/icons-material/Flag";
import Settings from "@mui/icons-material/Settings";
import ContentPaste from "@mui/icons-material/ContentPaste";
import WorkHistory from "@mui/icons-material/WorkHistory";
import PlayArrow from "@mui/icons-material/PlayArrow";
import Desk from "@mui/icons-material/Desk";
import TrendingUp from "@mui/icons-material/TrendingUp";
import { AirlineStops, School, ViewList, Remove, Bookmarks } from "@mui/icons-material";

/**
 * @see https://andgate-co.backlog.com/view/AP-375#comment-127805033
 */
export const ICON_MAP = {
  /**
   * なにも表示しなくていい、決まっていないなどの場合、これを使う。
   */
  Default: () => <Remove />,
  List: () => <List />,
  Dashboard: () => <Dashboard />,
  CheckBox: () => <CheckBox />,
  Sort: () => <Sort />,
  CalendarMonth: () => <CalendarMonth />,
  Flag: () => <Flag />,
  Settings: () => <Settings />,
  ContentPaste: () => <ContentPaste />,
  WorkHistory: () => <WorkHistory />,
  PlayArrow: () => <PlayArrow />,
  Desk: () => <Desk />,
  TrendingUp: () => <TrendingUp />,
  ViewList: () => <ViewList />,
  School: () => <School />,
  AirlineStops: () => <AirlineStops />,
  Bookmarks: () => <Bookmarks />
} as const;
